import React from "react";
import { Dropdown } from "semantic-ui-react";
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";

const BlockedDaysTable = (props) => (
  <ReactTable
    columns={[
      {
        Header: props.t("general.name"),
        accessor: "name",
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("general.date"),
        accessor: "date",
        Cell: (row) => {
          return `${props.months[row.original.month - 1]} ${row.original.day}`;
        },
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("general.exams"),
        Cell: (row) => {
            if(row.original.exams !== null && row.original.exams.length > 0){
                let selectedExams = props.exams.filter((exam) => {
                    return row.original.exams.includes(exam.id);
                });
                let spanList = selectedExams.map(( exam, index ) => {
                    return (
                        <li key={`exam_${exam.id}`}>{exam.title}</li>
                    )
                })
                return (
                    <ul className="exams-list">{spanList}</ul>
                )
            }else{
                return (
                    <span className="exams-cell">All</span>
                )
            }
        },
        style:{
            verticalAlign: 'middle',
            alignSelf: 'center'
        },
      },
      {
        Header: props.t("general.year"),
        accessor: "year",
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("BlockedDays.everyYear"),
        Cell: (row) => {
          if (row.original.every_year) {
            return (
              <i
                style={{ color: "#5cb84b", fontSize: "26px" }}
                className="check icon"
              ></i>
            );
          } else {
            return (
              <i
                style={{ color: "#db4737", fontSize: "26px" }}
                className="close icon"
              ></i>
            );
          }
        },
        style: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "flex-end",
        },
        sortable: false,
        filterable: false,
      },
      {
      Header: props.t("BlockedDays.allDay"),
        Cell: (row) => {
            if(row.original.all_day){
                return (
                    <i style={{color:'#5cb84b', fontSize: '26px'}} className='check icon'></i>
                )
            }else{
                return (
                    <i style={{color:'#db4737', fontSize: '26px'}} className='close icon'></i>
                )
            }
        },
        style:{
            textAlign: 'center',
            verticalAlign: 'middle',
            alignSelf: 'flex-end'
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: props.t("general.actions"),
        Cell: (row) => {
          return (
            <Dropdown
              style={{ background: "#0089a8", color: "white" }}
              button
              text={props.t("general.view")}
            >
              <Dropdown.Menu className="droppy">
                <Dropdown.Item
                  style={{ zIndex: 2000 }}
                  onClick={() =>
                    props.showDeleteBlockedDayModal(row.original.id)
                  }
                >
                  {props.t("general.delete")}
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ zIndex: 2000 }}
                  onClick={() =>
                    props.handleUpdateBlockedDay(row.original.id)
                  }
                >
                  {props.t("general.edit")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        style: {
          overflow: "inherit",
          textAlign: "center",
          alignSelf: "flex-end",
        },
        sortable: false,
        filterable: false,
      },
    ]}
    data={props.data}
    defaultPageSize={10}
    className="-striped -highlight test"
    minRows={0}
  />
);

export default withTranslation()(BlockedDaysTable);
