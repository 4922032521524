import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const ConfirmationModal = (props) => (
  <Modal open={props.open} className="modalHOTFIX">
    <Modal.Header>{props.headerText}</Modal.Header>
    <Modal.Content>
      <p>{props.confirmationMsg}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={props.close}>
        {props.t("general.no")}
      </Button>
      <Button
        positive
        icon="checkmark"
        labelPosition="right"
        content={props.t("general.yes")}
        onClick={props.submit}
      />
    </Modal.Actions>
  </Modal>
);

export default withTranslation()(ConfirmationModal);
