import React from "react";
import EmailLayout from "./EmailLayout";
import {Trans} from "react-i18next";

const pStyle = {
  margin: "0px 0px 15px",
  fontFamily: "sans-serif",
  fontSize: 14,
  fontWeight: "normal",
};

class BookingReschedule extends React.Component {
  render() {
    const {examTitle, duration, examRules, name, bookingDate, timezone} =
      this.props;
    return (
      <EmailLayout
        t={this.props.t}
        content={[
          <h2 key="">
            <Trans t={this.props.t} i18nKey="Emails.BookingReschedule.header">
              Booking Reschedule Confirmed for {{examTitle}}
            </Trans>
          </h2>,
          <p key="" style={pStyle}>
            <Trans t={this.props.t} i18nKey="Emails.BookingReschedule.message">
              {{name}}, Your previously schedule exam has been rescheduled to
              {{bookingDate}} {{timezone}}. Please login to your eProctor
              account 30 minutes prior to the exam to complete the validation
              and check-in process.
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            <Trans t={this.props.t} i18nKey="Emails.BookingReschedule.duration">
              Exam Duration: {{duration}} hours
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            <Trans
              t={this.props.t}
              i18nKey="Emails.BookingReschedule.examRules">
              Exam Specific Rules: {{examRules}}
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.BookingReschedule.defaultRules")}
          </p>,
          <ul>
            <li>{this.props.t("Emails.BookingReschedule.ruleOne")}</li>
            <li>{this.props.t("Emails.BookingReschedule.ruleTwo")}</li>
            <li>{this.props.t("Emails.BookingReschedule.ruleThree")}</li>
            <li>{this.props.t("Emails.BookingReschedule.ruleFour")}</li>
          </ul>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.supportCenter")}
          </p>,
        ]}
      />
    );
  }
}

export default BookingReschedule;
