import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Header, Table, Button, Icon, Message, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import {
  moveUser,
  fetchAllRooms,
  populatedAvailableRooms,
  sendDeleteRoom,
} from "./../reducers/room";
import { withTranslation } from "react-i18next";

const STATUS_WAITING = "Waiting";
const STATUS_ABSENT = "Absent";

class CheckinQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      learners: [],
      moving: false,
      movingBookingId: null,
      moveToRoomId: null,
      moveSuccess: false,
      moveError: false,
      message: "",
    };
    this.moveRoom = this.moveRoom.bind(this);
    this.handleDeleteRoom = this.handleDeleteRoom.bind(this);
  }
  componentDidMount() {
    this.props.ws.emit("getLearnersWaiting", {
      learners: this.props.room.bookings,
      room: this.props.room.id,
    });
    this.props.ws.on("learnersWaiting", (msg) => {
      this.setState({ learners: msg });
    });

    this.props.ws.on("updateCheckInQueue", (msg) => {
      const learners = this.state.learners.map((data, i) => {
        if (msg.room === data.room_id && msg.user_id === data.user.user_id) {
          data.status = msg.status;
          data.client_version = msg.client_version;
        }
        return data;
      });
      this.setState({ learners });
    });
  }
  componentWillUnmount() {
    this.props.ws.off("learnersWaiting");
    this.props.ws.off("updateCheckInQueue");
  }
  async moveRoom() {
    const res = await this.props.moveUser(
      this.state.movingBookingId,
      this.state.moveToRoomId
    );
    if (res.success) {
      const learners = this.state.learners.filter(
        (booking) => booking.id !== this.state.movingBookingId
      );
      this.setState({ learners, moveSuccess: true, message: res.message });
      this.props.fetchAllRooms();
    } else {
      this.setState({ moveError: true, message: res.message });
    }
  }
  handleMoveUser(id) {
    this.props.populatedAvailableRooms(this.props.room);
    this.setState({ moving: true, movingBookingId: id });
  }
  async handleDeleteRoom(e) {
    e.preventDefault();
    await this.props.sendDeleteRoom(this.props.room.id);
    this.props.fetchAllRooms();
    this.props.close();
  }
  render() {
    return (
      <div>
        <Header as="h2">
          <Icon name="user plus" />
          <Header.Content>
            {this.props.room.title}
            <Header.Subheader>
              {moment(this.props.room.slot_datetime).format(
                "MMMM Do YYYY - h:mm A"
              )}
            </Header.Subheader>
          </Header.Content>
        </Header>
        {this.state.moveSuccess ? (
          <Message
            onDismiss={() => this.setState({ moveSuccess: false })}
            positive
          >
            <Message.Header>{this.state.message}</Message.Header>
          </Message>
        ) : null}
        {this.state.moveError ? (
          <Message
            onDismiss={() => this.setState({ moveError: false })}
            negative
          >
            <Message.Header>{this.state.message}</Message.Header>
          </Message>
        ) : null}
        <Header size="medium">{this.props.t("CheckinQueue.header")}</Header>
        <Table celled padded>
          <Table.Header>
            {this.state.learners.length > 0 ? (
              <Table.Row>
                <Table.HeaderCell>
                  {this.props.t("general.name")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.username")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.phoneNumber")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("CheckinQueue.clientVersion")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.examName")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.status")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.actions")}
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.HeaderCell>
                  {this.props.t("general.name")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.t("general.actions")}
                </Table.HeaderCell>
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {this.state.learners.length > 0 ? (
              this.state.learners.map((data, i) => {
                return (
                  <Table.Row key={`${data.user_id}_checkin_queue`}>
                    <Table.Cell>
                      {`${data.user.first_name} ${data.user.last_name}`}
                    </Table.Cell>
                    <Table.Cell>{data.user.username}</Table.Cell>
                    <Table.Cell>{data.user.phone}</Table.Cell>
                    <Table.Cell>{data.client_version}</Table.Cell>
                    <Table.Cell>{data.exam.title}</Table.Cell>
                    <Table.Cell>{data.status}</Table.Cell>
                    <Table.Cell>
                      {data.status === STATUS_WAITING ? (
                        <NavLink
                          to={`/checkIn/${this.props.room.id}/${data.user_id}`}
                        >
                          {this.props.t("CheckinQueue.checkIn")}
                        </NavLink>
                      ) : this.state.moving ? (
                        <Form>
                          <Form.Group>
                            <Form.Field>
                              <select
                                onChange={(e) => {
                                  this.setState({
                                    moveToRoomId: e.target.value,
                                  });
                                }}
                              >
                                {this.props.rooms.availableRooms.length ===
                                  0 && (
                                  <option>
                                    {this.props.t(
                                      "CheckinQueue.noRoomsAvailable"
                                    )}
                                  </option>
                                )}
                                <option key="room_option_0" value=""></option>
                                {this.props.rooms.availableRooms.map((room) => {
                                  return (
                                    <option
                                      key={`room_option_${room.id}`}
                                      value={room.id}
                                    >
                                      {room.title}
                                    </option>
                                  );
                                })}
                              </select>
                            </Form.Field>
                            <Form.Field
                              disabled={!this.state.moveToRoomId}
                              primary
                              onClick={this.moveRoom}
                              control={Button}
                            >
                              {this.props.t("general.save")}
                            </Form.Field>
                            <Form.Field
                              color="red"
                              onClick={() => this.setState({ moving: false })}
                              control={Button}
                            >
                              {this.props.t("general.cancel")}
                            </Form.Field>
                          </Form.Group>
                        </Form>
                      ) : data.status === STATUS_ABSENT ? (
                        <a
                          href="#"
                          onClick={() => this.handleMoveUser(data.id)}
                        >
                          {this.props.t("CheckinQueue.moveUser")}
                        </a>
                      ) : (
                        ""
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row style={{ textAlign: "center" }}>
                <Table.Cell>
                  {this.props.t("CheckinQueue.noUsersInRoom")}
                </Table.Cell>
                <Table.Cell>
                  <a href="#" onClick={this.handleDeleteRoom}>
                    {this.props.t("CheckinQueue.deleteRoom")}
                  </a>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <NavLink to={`/proctoring/${this.props.room.id}`}>
          <Button size="massive" primary fluid>
            {this.props.t("JoinExamModal.joinExam")}
          </Button>
        </NavLink>
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      sessions: state.session.sessions,
      kc: state.keycloak,
      rooms: state.rooms,
    }),
    { moveUser, fetchAllRooms, populatedAvailableRooms, sendDeleteRoom }
  )(CheckinQueue)
);
