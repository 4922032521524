import React, { Component } from "react";
import {
  Button,
  Modal,
  Dropdown,
  Icon,
  Form,
  Grid,
  Input,
  Checkbox,
  Message,
  Header,
  Label,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { fetchExams } from "../reducers/exam";
import {
  fetchCoupons,
  updateEditCoupon,
  updateAvailModal,
  updateCouponAvailability,
  updateCouponName,
  updateCouponValue,
  setIsUpdating,
  updateCouponValueType,
  updateCouponExams,
  updateCouponStatus,
  changeCouponStatus,
  saveCoupon,
  updateExistingCoupon,
  updateMaxUses,
} from "../reducers/coupons";
import CouponTable from "./../components/CouponTable";
import "react-table/react-table.css";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const valueTypeOptions = [
  {
    key: "%",
    text: "%",
    value: "%",
  },
  {
    key: "$",
    text: "$",
    value: "$",
  },
];

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.coupons.coupons,
      status: "",
      nameError: false,
      valueError: false,
      valueTypeError: false,
      error: false,
      examArray: {},
      over100: "",
      overError: false,
    };
    this.updateCouponName = this.updateCouponName.bind(this);
    this.updateCouponValue = this.updateCouponValue.bind(this);
    this.updateCouponValueType = this.updateCouponValueType.bind(this);
    this.updateCouponExams = this.updateCouponExams.bind(this);
    this.submitStatusUpdate = this.submitStatusUpdate.bind(this);
    this.handleCouponStatus = this.handleCouponStatus.bind(this);
    this.handleUpdateCoupon = this.handleUpdateCoupon.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  componentWillMount() {
    this.props.fetchCoupons();
    this.props.fetchExams();
  }
  updateCouponName(value) {
    this.props.updateCouponName(value);
  }
  updateCouponValue(value) {
    this.props.updateCouponValue(value);
  }
  updateCouponValueType(value) {
    this.props.updateCouponValueType(value);
  }
  updateCouponExams(value) {
    this.props.updateCouponExams(value);
  }
  updateMaxUses(value) {
    const parsed = parseInt(value);
    if ((Number.isFinite(parsed) && parsed >= 0) || value === "") {
      this.props.updateMaxUses(value);
    }
  }
  updateCouponAvailability(value) {
    if (!value) {
      this.props.coupons.couponAvailability = false;
    } else {
      this.props.coupons.couponAvailability = true;
    }
    this.props.updateCouponAvailability(value);
  }
  handleAddCoupon() {
    this.setState({
      examArray: this.props.exams.allExams.filter(exam => exam.deleted === null).map((exam, index) => {
        return {
          text: exam.title,
          value: exam.id,
        };
      }),
    });
    this.props.updateEditCoupon();
    this.props.updateCouponName("");
    this.props.updateCouponValue("");
    this.props.updateCouponValueType("");
    this.props.updateCouponAvailability(false);
    this.props.updateCouponExams([]);
    this.props.setIsUpdating(false);
  }
  handleCouponStatus(id) {
    let coupon = this.props.coupons.coupons.filter((data) => data.id === id);
    coupon = coupon[0];
    this.props.updateCouponStatus(coupon);
    if (coupon["active"] === false) {
      this.setState({ status: this.props.t("general.activate") });
    } else {
      this.setState({ status: this.props.t("general.deactivate") });
    }
    this.props.updateAvailModal();
  }
  handleUpdateCoupon(id) {
    this.setState({
      examArray: this.props.exams.allExams.filter(exam => exam.deleted === null).map((exam, index) => {
        return {
          text: exam.title,
          value: exam.id,
        };
      }),
    });
    let coupon = this.props.coupons.coupons.filter((data) => data.id === id);
    coupon = coupon[0];
    this.props.updateCouponStatus(coupon);

    this.props.updateCouponName(coupon.coupon_name);
    this.props.updateCouponValue(coupon.coupon_value);
    this.props.updateCouponValueType(coupon.coupon_value_type);
    this.props.updateCouponExams(coupon.coupon_exams);
    this.props.updateCouponAvailability(coupon.coupon_availability);
    this.props.setIsUpdating(true);
    this.props.updateEditCoupon();
  }
  submitStatusUpdate() {
    this.props.changeCouponStatus(this.props.coupons.couponToChange);
    this.props.updateAvailModal();
  }
  submitForm(e) {
    e.preventDefault();
    let error = false;
    let overError = false;
    if (this.props.coupons.couponName.trim().length === 0) {
      this.setState({ nameError: true });
      error = true;
    }
    if (this.props.coupons.couponValue.trim().length === 0) {
      this.setState({ valueError: true });
      error = true;
    }
    if (this.props.coupons.couponValueType.trim().length === 0) {
      this.setState({ valueTypeError: true });
      error = true;
    }
    if (this.props.coupons.couponValueType === "%") {
      if (this.props.coupons.couponValue > 100) {
        this.setState({
          overError: true,
          over100: this.props.t("CouponTable.over100"),
        });
        overError = true;
      }
    }
    this.setState({ error: error });
    if (!error && !overError) {
      if (this.props.coupons.isUpdating) {
        this.props.updateExistingCoupon({
          id: this.props.coupons.couponToChange.id,
          coupon_name: this.props.coupons.couponName,
          coupon_value: this.props.coupons.couponValue,
          coupon_value_type: this.props.coupons.couponValueType,
          coupon_exams: this.props.coupons.couponExams,
          coupon_availability: this.props.coupons.couponAvailability,
          coupon_max_uses: this.props.coupons.couponMaxUses,
        });
      } else {
        this.props.saveCoupon({
          coupon_name: this.props.coupons.couponName,
          coupon_value: this.props.coupons.couponValue,
          coupon_value_type: this.props.coupons.couponValueType,
          coupon_exams: this.props.coupons.couponExams,
          coupon_availability: this.props.coupons.couponAvailability,
          coupon_max_uses: this.props.coupons.couponMaxUses,
        });
      }
      this.props.updateEditCoupon();
    }
  }
  render() {
    return (
      <div>
        <Grid style={{ paddingBottom: "25px" }}>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ display: "inline" }}>Coupons</Header>

              <Button
                floated="right"
                color="green"
                onClick={() => this.handleAddCoupon()}
              >
                {this.props.t("CouponTable.addCoupon")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          size="small"
          open={this.props.coupons.showAvailModal}
          className="modalHOTFIX"
          closeIcon
          onClose={() => {
            this.props.updateAvailModal();
          }}
        >
          <Modal.Header>
            {this.state.status === "Deactivate"
              ? this.props.t("CouponTable.deactivateCoupon")
              : this.props.t("CouponTable.activateCoupon")}
          </Modal.Header>
          <Modal.Content>
            <p>
              {this.state.status === "Deactivate"
                ? this.props.t("CouponTable.deactivateCouponConfirm")
                : this.props.t("CouponTable.activateCouponConfirm")}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                this.props.updateAvailModal();
              }}
            >
              {this.props.t("general.no")}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={this.props.t("general.yes")}
              onClick={this.submitStatusUpdate}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="small"
          open={this.props.coupons.showEditCoupon}
          className="modalHOTFIX"
          closeIcon
          onClose={() => {
            this.props.updateEditCoupon();
          }}
        >
          <Modal.Header>
            {this.props.t("CouponTable.addOrEditCoupon")}
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Message
                visible={this.state.error}
                onDismiss={() => this.setState({ error: false })}
                header={this.props.t("CouponTable.missingFieldsErrorHeader")}
                content={this.props.t("CouponTable.missingFieldsErrorMessage")}
                error
              />
              <Message
                visible={this.state.overError}
                onDismiss={() => this.setState({ overError: false })}
                header={this.props.t("CouponTable.missingFieldsErrorHeader")}
                content={this.state.over100}
                error
              />
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-name"
                      error={this.state.nameError}
                      name="name"
                      value={this.props.coupons.couponName}
                      onChange={(e) => this.updateCouponName(e.target.value)}
                      control={Input}
                      label={this.props.t("CouponTable.couponCode")}
                      onBlur={(e) =>
                        e.target.value.length === 0
                          ? this.setState({ nameError: true })
                          : this.setState({ nameError: false })
                      }
                    />
                    <Form.Field
                      id="form-input-control-contact"
                      error={this.state.valueError}
                      name="contact"
                      value={this.props.coupons.couponValue}
                      onChange={(e) => this.updateCouponValue(e.target.value)}
                      control={Input}
                      label={this.props.t("CouponTable.couponValue")}
                    />
                    <Form.Field
                      id="form-input-control-email"
                      name="email"
                      error={this.state.valueTypeError}
                      value={this.props.coupons.couponValueType}
                      onChange={(e) =>
                        this.updateCouponValueType(e.target.value)
                      }
                      control={Input}
                      label={this.props.t("CouponTable.couponValueType")}
                    >
                      <Dropdown
                        placeholder={this.props.t("CouponTable.valueType")}
                        fluid
                        selection
                        defaultValue={this.props.coupons.couponValueType}
                        onChange={(e, data) =>
                          this.updateCouponValueType(data.value)
                        }
                        options={valueTypeOptions}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>{this.props.t("general.exams")}</label>
                      <Dropdown
                        value={this.props.coupons.couponExams}
                        options={Array.from(this.state.examArray)}
                        onChange={(e, data) =>
                          this.updateCouponExams(data.value)
                        }
                        placeholder={this.props.t("CouponTable.selectExams")}
                        fluid
                        multiple
                        selection
                      />
                    </Form.Field>
                    <Form.Field
                      id="form-input-control-max-uses"
                      name="couponMaxUses"
                      type="number"
                      error={this.state.maxUsesError}
                      value={this.props.coupons.couponMaxUses}
                      onChange={(e) => this.updateMaxUses(e.target.value)}
                      control={Input}
                      label={this.props.t("CouponTable.maxUses")}
                    ></Form.Field>
                    <Grid.Column width="5">
                      <Grid.Column width="11">
                        <label>
                          {this.props.t("CouponTable.availabilityStatus")}
                        </label>
                      </Grid.Column>
                      <Checkbox
                        onChange={(e, data) =>
                          this.updateCouponAvailability(data.checked)
                        }
                        label={this.props.t("general.online")}
                        checked={this.props.coupons.couponAvailability}
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      onClick={() => this.props.updateEditCoupon()}
                      color="red"
                      floated="right"
                    >
                      {this.props.t("general.cancel")}
                    </Button>
                    <Button
                      onClick={(e) => this.submitForm(e)}
                      color="green"
                      floated="right"
                    >
                      {this.props.t("general.save")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
        <CouponTable
          handleUpdateCoupon={this.handleUpdateCoupon}
          handleCouponStatus={this.handleCouponStatus}
          allExams={this.props.exams.allExams}
          data={this.props.coupons.coupons}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      coupons: state.coupons,
      exams: state.exam,
    }),
    {
      fetchExams,
      fetchCoupons,
      updateEditCoupon,
      updateAvailModal,
      updateCouponAvailability,
      updateCouponName,
      updateCouponValue,
      setIsUpdating,
      updateCouponValueType,
      updateCouponExams,
      updateCouponStatus,
      changeCouponStatus,
      saveCoupon,
      updateExistingCoupon,
      updateMaxUses,
    }
  )(Coupons)
);
