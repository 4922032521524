import React, { Component } from "react";
import { Segment, Divider, Popup, Message } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ExamBlock extends Component {
  copyToClipboard = () => {
    let textField = document.createElement("textarea");
    textField.innerText = this.props.code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  render() {
    let status = this.props.status;
    let approved = false;
    let pending = false;
    let rejected = false;
    let completed = false;
    let missed = false;
    let message = "";
    switch (status) {
      case "approved":
        if (this.props.past) {
          missed = true;
          status = "missed";
          message = this.props.t("ExamBlock.notPresentForExam");
        } else {
          approved = true;
          message = this.props.t("ExamBlock.bookingApproved");
        }
        break;
      case "pending":
        if (this.props.past) {
          missed = true;
          status = "missed";
          message = this.props.t("ExamBlock.notPresentForExam");
        } else {
          pending = true;
          message = this.props.t("ExamBlock.approvalTimeFrameMessage");
        }
        break;
      case "rejected":
        rejected = true;
        message = this.props.t("ExamBlock.bookingRejected");
        break;
      case "banned":
        rejected = true;
        message = "You were banned from this Booking in a prior session.";
        break;
      case "completed":
        completed = true;
        message = this.props.t("ExamBlock.examCompleted");
        break;
      default:
        rejected = true;
        message = this.props.t("ExamBlock.bookingRejected");
    }
    return (
      <div>
        <Popup
          style={{ textTransform: "capitalize" }}
          inverted
          trigger={
            <Segment
              className="home-exam"
              compact
              style={{ width: 350, height: 200 }}
            >
              <span className="exam-block-title">{this.props.title}</span>
              <Divider className="exam-block-divider" />
              {approved ? (
                <NavLink to="/download">
                  <Message
                    positive={approved}
                    info={pending}
                    negative={rejected}
                    warning={missed}
                    color={completed ? "orange" : null}
                  >
                    {`${this.props.date} (${this.props.timezone})`}
                  </Message>
                </NavLink>
              ) : (
                <Message
                  positive={approved}
                  info={pending}
                  negative={rejected}
                  warning={missed}
                  color={completed ? "orange" : null}
                >
                  {`${this.props.date} (${this.props.timezone})`}
                </Message>
              )}

              {message}
            </Segment>
          }
          content={status}
        />
      </div>
    );
  }
}

export default withTranslation()(ExamBlock);
