import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, Grid, Container, Dropdown } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { fetchLearners, fetchLearnersSessions } from "./../reducers/learners";
import MainMenu from "./../components/MainMenu";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

class Learners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.learners.allLearners,
      open: false,
      sessions: [],
    };

    this.closeModal = this.closeModal.bind(this);
  }
  componentWillMount() {
    this.props.fetchLearners();
    this.props.fetchLearnersSessions();
  }
  closeModal() {
    this.props.changeModal();
  }
  defaultFilterMethod(filter, row, column) {
    const id = filter.pivotId || filter.id;
    if (row[id] === null || row[id] === undefined) {
      return true;
    }
    return row[id].toLowerCase().startsWith(filter.value.toLowerCase());
  }
  render() {
    return (
      <Container>
        <MainMenu />
        <Modal open={this.state.open} className="modalHOTFIX">
          <Modal.Header>{this.props.t("Learners.replays")}</Modal.Header>
          <Modal.Content scrolling>
            <Grid celled>
              {this.state.sessions.map((data, i) => {
                return (
                  <Grid.Row key={i}>
                    <Grid.Column>
                      {data.exam_title} -{" "}
                      {moment(data.start_time).format("MMMM Do, YYYY")}
                      <NavLink to={`/replay/${data.session_guid}`}>
                        <Button circular icon="play" floated="right" />
                      </NavLink>
                    </Grid.Column>
                  </Grid.Row>
                );
              })}
            </Grid>
            <Modal.Actions>
              <Button
                primary
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                {this.props.t("general.close")}
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
        <ReactTable
          columns={[
            {
              Header: this.props.t("general.username"),
              accessor: "username",
              filterMethod: this.defaultFilterMethod,
            },
            {
              Header: this.props.t("general.firstName"),
              accessor: "first_name",
              filterMethod: this.defaultFilterMethod,
            },
            {
              Header: this.props.t("general.lastName"),
              accessor: "last_name",
              filterMethod: this.defaultFilterMethod,
            },
            {
              Header: this.props.t("AccountDetails.province"),
              accessor: "province",
              filterMethod: this.defaultFilterMethod,
            },
            {
              Header: this.props.t("AccountDetails.timezone"),
              accessor: "timezone",
              filterMethod: this.defaultFilterMethod,
            },
            {
              Header: this.props.t("Learners.registeredOn"),
              accessor: "createdAt",
              filterable: false,
              Cell: (row) => {
                return moment(row.original.registered).format("MMMM Do, YYYY");
              },
            },
            {
              Header: this.props.t("general.actions"),
              Cell: (row) => {
                return (
                  <Dropdown
                    button
                    text={this.props.t("general.actions")}
                    style={{ overflow: "inherit !important" }}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={NavLink}
                        to={`/learner-details/${row.original.user_id}`}
                        name="learners-bookings"
                      >
                        {this.props.t("Learners.viewBookings")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                );
              },
              style: {
                overflow: "inherit",
              },
              sortable: false,
              filterable: false,
            },
          ]}
          data={this.props.learners.allLearners}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          minRows={0}
        />
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      learners: state.learners,
    }),
    { fetchLearners, fetchLearnersSessions }
  )(Learners)
);
