import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Button,
  Grid,
  Segment,
  Label,
  Dropdown,
  Form,
  Icon,
} from "semantic-ui-react";
import { fetchExams, fetchClients } from "../../reducers/exam";
import { withTranslation } from "react-i18next";
import ExamDetails from "./ExamDetails";
import _ from "underscore";

class SelectExam extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedExam: this.props.formData.exam || {},
      selectedClient: this.props.formData.client || {},
      options: [],
    };
  }
  updateSelected = (event, data) => {
    const selectedExam = this.props.exam.allExams.filter(function (exam) {
      return parseInt(exam.id, 10) === parseInt(data.value, 10);
    });
    this.setState({
      selectedExam: selectedExam[0],
    });
  };

  updateClientSelected = (event, data) => {
    const selectedClient = this.props.exam.clients.filter(function (client) {
      return parseInt(client.value, 10) === parseInt(data.value, 10);
    });
    this.setState(
      {
        selectedExam: {},
        selectedClient: selectedClient[0],
      },
      () => {
        //callback
        this.updateExamOptions();
      }
    );
  };
  updateExamOptions() {
    let examOptions = [];
    this.props.exam.allExams.reduce((res, data) => {
      if (data.deleted === null) {
        if (
          data.clients.filter((e) => e.id === this.state.selectedClient.value)
            .length > 0
        ) {
          examOptions.push({
            key: `${data.id}`,
            value: `${data.id}`,
            text: data.title,
          });
        }
      }
      return null;
    }, []);

    examOptions.sort(function (a, b) {
      var textA = a.text.toUpperCase();
      var textB = b.text.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    this.setState({
      options: examOptions,
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onForward({
      exam: this.state.selectedExam,
      clientId: this.state.selectedClient.value,
    });
  };

  componentWillMount() {
    this.props.fetchExams();
    this.props.fetchClients();
  }

  render() {
    let clients = [];
    this.props.exam.clients.forEach((client) => {
      if (client.online === true) {
        clients.push({
          key: `${client.value}`,
          value: `${client.value}`,
          text: client.text,
        });
      }
    });
    return (
      <Container>
        <Form>
          <Segment padded>
            <Header>{this.props.t("SelectExam.header")}</Header>
            <Grid.Column>
              <Form.Field>
                {_.isEmpty(this.state.selectedClient) ? (
                  <Label pointing="below">
                    {this.props.t("SelectExam.selectTrainingProvider")}
                  </Label>
                ) : (
                  ""
                )}
                <Dropdown
                  deburr
                  fluid
                  options={clients}
                  defaultValue={[1]}
                  onChange={this.updateClientSelected}
                  placeholder={this.props.t("SelectExam.selectExamProvider")}
                  search
                  selection
                />
              </Form.Field>
              <Form.Field>
                {_.isEmpty(this.state.selectedExam) &&
                !_.isEmpty(this.state.selectedClient) ? (
                  <Label pointing="below">
                    {this.props.t("SelectExam.selectExam")}
                  </Label>
                ) : (
                  ""
                )}
                <Dropdown
                  deburr
                  fluid
                  options={this.state.options}
                  defaultValue={[1]}
                  onChange={this.updateSelected}
                  placeholder={this.props.t("SelectExam.selectExam")}
                  search
                  selection
                />
              </Form.Field>
            </Grid.Column>
          </Segment>

          <ExamDetails
            step={this.props.step}
            duration={this.state.selectedExam.duration}
            cost={this.state.selectedExam.cost || 0.0}
            description={this.state.selectedExam.description}
          />

          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    onClick={this.onSubmit}
                    disabled={_.isEmpty(this.state.selectedExam)}
                    positive
                    floated="right"
                  >
                    <Button.Content visible>
                      {this.props.t("general.next")}
                      <Icon name="right arrow" />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
    }),
    {
      fetchExams,
      fetchClients,
    }
  )(SelectExam)
);
