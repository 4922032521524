import React from "react";
import { Modal } from "semantic-ui-react";
import RescheduleDates from "./Bookings/RescheduleDates";
import { withTranslation } from "react-i18next";

const RescheduleExamModal = (props) => {
  return (
    <Modal open={props.open} id="reschedule" className="modalHOTFIX">
      <Modal.Header>{props.t("RescheduleExam.header")}</Modal.Header>
      <Modal.Content>
        <RescheduleDates
          updateBooking={props.updateBooking}
          booking={props.booking}
          close={props.close}
        />
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(RescheduleExamModal);
