import React, { Component } from "react";
import { Container, Menu, Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "./../i18n";
import LanguageSelector from "../components/LanguageSelector";
import moment from "moment";

const logo = require("../images/logowhite.svg");

class FixedMenu extends Component {
  constructor(props) {
    super(props);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.state = {
      lang: localStorage.getItem("lang"),
    };
  }
  handleLanguageChange(e, data) {
    i18n.changeLanguage(data.value);
    localStorage.setItem("lang", data.value);
    moment.locale(data.value);
    this.setState({ lang: data.value });
  }
  render() {
    return (
      <Menu fixed="top" size="large" className="header-menu">
        <Container>
          <Menu.Item as={NavLink} exact to="/">
            <img alt="logo" src={String(logo)} />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Dropdown
                style={{ background: "#6abf48", color: "white" }}
                button
                text={this.props.name}
              >
                <Dropdown.Menu>
                  <NavLink to="/">
                    <Dropdown.Item
                      selected={this.props.active === "dashboard"}
                      active={this.props.active === "dashboard"}
                      onClick={() => this.props.setActiveMenuItem("dashboard")}
                    >
                      {this.props.t("App.dashboard")}
                    </Dropdown.Item>
                  </NavLink>
                  <NavLink to="/account-details">
                    <Dropdown.Item
                      selected={this.props.active === "accountDetails"}
                      active={this.props.active === "accountDetails"}
                      onClick={() =>
                        this.props.setActiveMenuItem("accountDetails")
                      }
                    >
                      {this.props.t("App.accountDetails")}
                    </Dropdown.Item>
                  </NavLink>
                  <NavLink to="/download">
                    <Dropdown.Item
                      selected={this.props.active === "downloadApp"}
                      active={this.props.active === "downloadApp"}
                      onClick={() =>
                        this.props.setActiveMenuItem("downloadApp")
                      }
                    >
                      {this.props.t("App.downloadApplication")}
                    </Dropdown.Item>
                  </NavLink>
                  <Dropdown.Divider />
                  <NavLink
                    to="/logout"
                    onClick={() => {
                      this.props.logout();
                    }}
                  >
                    <Dropdown.Item>{this.props.t("App.logout")}</Dropdown.Item>
                  </NavLink>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item>
              <LanguageSelector
                lang={this.state.lang}
                handleLanguageChange={this.handleLanguageChange}
              />
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

export default withTranslation()(FixedMenu);
