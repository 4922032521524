import {
  createSession,
  createEvent,
  listLearnerSessions,
} from "../lib/sessionServices";
import { getExamByName } from "../lib/examServices";

const initState = {
  sessions: [],
  events: [],
  notes: [],
  allSessions: [],
  activeChat: "",
  showExamModal: false,
  showPushModal: false,
  showAllPushModal: false,
  unreadMessage: 0,
  currentExam: null,
  url: "",
  url2: "",
  socketForUrlPush: "",
  examName: null,
  examCapacity: 0,
  noteText: "",
  maximizedUser: {},
  examId: null,
  zoomDesktop: false,
  zoomedSRC: null,
  muted: [],
  message: "",
  showMessage: false,
  faceToFaceConnected: false,
};

const ADD_EVENT = "ADD_EVENT";
const UPDATE_EVENTS = "UPDATE_EVENTS";
const SESSIONS_ADD = "SESSION_ADD";
const SHOW_PUSH_MODAL = "SHOW_PUSH_MODAL";
const SHOW_ALL_PUSH_MODAL = "SHOW_ALL_PUSH_MODAL";
const SHOW_EXAM_MODAL = "SHOW_EXAM_MODAL";
const UPDATE_ZOOM_DESKTOP = "UPDATE_ZOOM_DESKTOP";
const UPDATE_ZOOM_SRC = "UPDATE_ZOOM_SRC";
const UPDATE_EXAM_NAME = "UPDATE_EXAM_NAME";
const UPDATE_EXAM_CAPACITY = "UPDATE_EXAM_CAPACITY";
const BAN_USER = "BAN_USER";
const SOFT_BOOT_USER = "SOFT_BOOT_USER";
const UPDATE_CURRENT_EXAM = "UPDATE_CURRENT_EXAM";
const UPDATE_SESSIONS = "UPDATE_SESSIONS";
const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
const UPDATE_URL = "UPDATE_URL";
const UPDATE_ALL_URL = "UPDATE_ALL_URL";
const UPDATE_SOCKET_FOR_URL_PUSH = "UPDATE_SOCKET_FOR_URL_PUSH";
const UPDATE_NOTE_TEXT = "UPDATE_NOTE_TEXT";
const ADD_NOTE = "ADD_NOTE";
const SET_MAXIMIZED_USER = "SET_MAXIMIZED_USER";
const UPDATE_EXAM_ID = "UPDATE_EXAM_ID";
const LOAD_SESSIONS = "LOAD_SESSIONS";
const UPDATE_MUTED = "UPDATE_MUTED";
const CLEAR_SESSIONS = "CLEAR_SESSIONS";
const UPDATE_MESSAGE = "UPDATE_MESSAGE";
const UPDATE_SHOW_MESSAGE = "UPDATE_SHOW_MESSAGE";
const UPDATE_FACE_TO_FACE_CONNECTED = "UPDATE_FACE_TO_FACE_CONNECTED";

export const addSession = (val) => ({ type: SESSIONS_ADD, payload: val });
export const updateExamName = (val) => ({
  type: UPDATE_EXAM_NAME,
  payload: val,
});
export const updateExamCapacity = (val) => ({
  type: UPDATE_EXAM_CAPACITY,
  payload: val,
});
export const banUser = (payload) => ({ type: BAN_USER, payload });
export const softBootUser = (payload) => ({ type: SOFT_BOOT_USER, payload });
export const updateSessions = (val) => ({
  type: UPDATE_SESSIONS,
  payload: val,
});
export const updateEvents = (val) => ({ type: UPDATE_EVENTS, payload: val });
export const setActiveChat = (val) => ({ type: SET_ACTIVE_CHAT, payload: val });
export const updateCurrentExam = (val) => ({
  type: UPDATE_CURRENT_EXAM,
  payload: val,
});
export const loadSessions = (val) => ({ type: LOAD_SESSIONS, payload: val });
export const updateUrl = (val) => ({ type: UPDATE_URL, payload: val });
export const updateAllUrl = (val) => ({ type: UPDATE_ALL_URL, payload: val });
export const updateSocketForUrlPush = (val) => ({
  type: UPDATE_SOCKET_FOR_URL_PUSH,
  payload: val,
});
export const updatePushModal = () => ({ type: SHOW_PUSH_MODAL });
export const updateAllPushModal = () => ({ type: SHOW_ALL_PUSH_MODAL });
export const updateExamModal = () => ({ type: SHOW_EXAM_MODAL });
export const updateZoomDesktop = () => ({ type: UPDATE_ZOOM_DESKTOP });
export const updateZoomSRC = (val) => ({ type: UPDATE_ZOOM_SRC, payload: val });
export const saveEvent = (val) => ({ type: ADD_EVENT, payload: val });
export const updateNoteText = (val) => ({
  type: UPDATE_NOTE_TEXT,
  payload: val,
});
export const addNote = (val) => ({ type: ADD_NOTE, payload: val });
export const setMaximizedUser = (val) => ({
  type: SET_MAXIMIZED_USER,
  payload: val,
});
export const updateExamId = (val) => ({ type: UPDATE_EXAM_ID, payload: val });
export const updateMuted = (val) => ({ type: UPDATE_MUTED, payload: val });
export const clearSessions = () => ({ type: CLEAR_SESSIONS });
export const updateMessage = (message) => ({
  type: UPDATE_MESSAGE,
  payload: message,
});
export const updateShowMessage = (val) => ({
  type: UPDATE_SHOW_MESSAGE,
  payload: val,
});
export const updateFaceToFaceConnected = (val) => ({
  type: UPDATE_FACE_TO_FACE_CONNECTED,
  payload: val,
});

export const saveSession = (session) => {
  return (dispatch) => {
    createSession(session).then(console.log(session));
  };
};

export const getExamId = (examName) => {
  return (dispatch) => {
    getExamByName(examName).then((exam) => dispatch(updateExamId(exam.id)));
  };
};

export const fetchSessions = () => {
  return (dispatch) => {
    listLearnerSessions().then((sessions) => dispatch(loadSessions(sessions)));
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case SESSIONS_ADD:
      action.payload.muted = true;
      action.payload.render = true;
      return { ...state, sessions: state.sessions.concat(action.payload) };
    case LOAD_SESSIONS:
      return { ...state, allSessions: action.payload };
    case UPDATE_EXAM_NAME:
      return { ...state, examName: action.payload };
    case UPDATE_CURRENT_EXAM:
      return { ...state, currentExam: action.payload };
    case UPDATE_EXAM_CAPACITY:
      return { ...state, examCapacity: action.payload };
    case BAN_USER:
      return {
        ...state,
        sessions: state.sessions.filter(
          (data, i) => data.user_id !== action.payload
        ),
      };
    case SOFT_BOOT_USER:
      return {
        ...state,
        sessions: state.sessions.filter(
          (data, i) => data.user_id !== action.payload
        ),
      };
    case ADD_EVENT:
      createEvent(action.payload);
      return { ...state, events: [action.payload].concat(state.events) };
    case UPDATE_SESSIONS:
      return { ...state, sessions: action.payload };
    case UPDATE_EVENTS:
      return { ...state, events: action.payload };
    case SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.payload };
    case UPDATE_URL:
      let url = action.payload;
      if (!/^https?:\/\//i.test(url)) {
        url = "http://" + action.payload;
      }
      return { ...state, url: url };
    case UPDATE_ALL_URL:
      url = action.payload;
      if (!/^https?:\/\//i.test(url)) {
        url = "http://" + action.payload;
      }
      return { ...state, url: url };
    case UPDATE_SOCKET_FOR_URL_PUSH:
      return { ...state, socketForUrlPush: action.payload };
    case SHOW_PUSH_MODAL:
      return { ...state, showPushModal: !state.showPushModal };
    case SHOW_ALL_PUSH_MODAL:
      return { ...state, showAllPushModal: !state.showAllPushModal };
    case SHOW_EXAM_MODAL:
      return { ...state, showExamModal: !state.showExamModal };
    case UPDATE_ZOOM_DESKTOP:
      return { ...state, zoomDesktop: !state.zoomDesktop };
    case UPDATE_ZOOM_SRC:
      return { ...state, zoomedSRC: action.payload };
    case UPDATE_NOTE_TEXT:
      return { ...state, noteText: action.payload };
    case ADD_NOTE:
      createEvent(action.payload.event);
      return {
        ...state,
        notes: [
          { note: action.payload.event, user: action.payload.user_id },
        ].concat(state.notes),
      };
    case SET_MAXIMIZED_USER:
      return { ...state, maximizedUser: action.payload };
    case UPDATE_EXAM_ID:
      return { ...state, examId: action.payload };
    case UPDATE_MUTED:
      return {
        ...state,
        sessions: state.sessions.map((x) => {
          if (x.user_id === action.payload) {
            x.muted = !x.muted;
          }
          return x;
        }),
      };
    case CLEAR_SESSIONS:
      return { ...state, sessions: [] };
    case UPDATE_MESSAGE:
      return { ...state, message: action.payload };
    case UPDATE_SHOW_MESSAGE:
      return { ...state, showMessage: action.payload };
    case UPDATE_FACE_TO_FACE_CONNECTED:
      return { ...state, faceToFaceConnected: action.payload };
    default:
      return state;
  }
};
