import React, { Component } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import {
  updateShowCreateRoom,
  saveRoom,
  fetchAllRooms,
} from "./../reducers/room";
import { withTranslation } from "react-i18next";

class CreateRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      slot_datetime: null,
    };
    this.submitForm = this.submitForm.bind(this);
  }
  async submitForm() {
    await this.props.saveRoom({
      title: this.state.title,
      slot_datetime: this.state.slot_datetime,
    });
    this.props.updateShowCreateRoom(false);
    this.props.fetchAllRooms();
  }
  render() {
    return (
      <Modal open={this.props.rooms.showCreateRoom}>
        <Modal.Header>{this.props.t("CreateRoom.addRoom")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{this.props.t("general.title")}</label>
              <input
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={this.props.t("CreateRoom.roomTitle")}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("CreateRoom.dateAndTime")}</label>
              <DatePicker
                selected={this.state.slot_datetime}
                showTimeSelect
                timeIntervals={60}
                dateFormat="MM/DD/YYYY"
                timeFormat="h:mm a"
                onChange={(slot_datetime) => this.setState({ slot_datetime })}
              />
            </Form.Field>
            <Button primary onClick={this.submitForm} type="submit">
              {this.props.t("general.submit")}
            </Button>
            <Button
              color="red"
              onClick={() => this.props.updateShowCreateRoom(false)}
            >
              {this.props.t("general.cancel")}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      rooms: state.rooms,
    }),
    { updateShowCreateRoom, saveRoom, fetchAllRooms }
  )(CreateRoom)
);
