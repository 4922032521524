import React from "react";
import { Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const LanguageSelector = (props) => {
  return (
    <Dropdown
      style={{ background: "#6abf48", color: "white" }}
      compact
      onChange={props.handleLanguageChange}
      value={props.lang}
      selection
      options={[
        { key: "en", value: "en", text: "English" },
        { key: "fr", value: "fr", text: "français" },
      ]}
    />
  );
};

export default withTranslation()(LanguageSelector);
