import React from 'react';

const slider = (props) => {
    return (
        <div className="slideContainer">
            <label className="sliderLabel">{props.label}</label>
            <input 
                onChange={props.updateMaxUserCycleTime} 
                type="range" 
                min={props.min} 
                max={props.max} 
                className="slider" 
                value={props.maxUserCycleTime}
                disabled={props.disabled}
            />
            <span className="sliderCounter">{props.maxUserCycleTime == 0 ? 'Off' : `${props.maxUserCycleTime}(s)`}</span>
        </div>
    );
}

export default slider;