import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { updateMuted } from "../reducers/session";

class MuteButton extends Component {
  render() {
    let muted = "mute";
    this.props.sessions.forEach((element) => {
      if (element.user_id === this.props.user_id) {
        if (element.muted) {
          muted = "mute";
        } else {
          muted = "unmute";
        }
      }
    });
    return (
      <Button
        title="Mute"
        circular
        icon={muted}
        size={this.props.size}
        onClick={() => {
          let audio = document.getElementById(this.props.user_id + "_audio");
          audio.muted = !audio.muted;
          this.props.updateMuted(this.props.user_id);
        }}
      />
    );
  }
}

export default connect(
  (state) => ({
    sessions: state.session.sessions,
  }),
  { updateMuted }
)(MuteButton);
