import React from "react";
import EmailLayout from "./EmailLayout";
import { Trans } from "react-i18next";

const pStyle = {
  margin: "0px 0px 15px",
  fontFamily: "sans-serif",
  fontSize: 14,
  fontWeight: "normal",
};

class BookingRequest extends React.Component {
  render() {
    const { examTitle, code } = this.props;
    return (
      <EmailLayout
        t={this.props.t}
        content={[
          <h2 key="">
            <Trans t={this.props.t} i18nKey="Emails.BookingRequest.header">
              Booking Request for {{ examTitle }} Pending
            </Trans>
          </h2>,
          <h3 key="">
            <Trans t={this.props.t} i18nKey="Emails.BookingRequest.bookingCode">
              Booking Code: {{ code }}
            </Trans>
          </h3>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.BookingRequest.approvalTimeFrame")}
          </p>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.BookingRequest.postApprovalInstructions")}
          </p>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.supportCenter")}
          </p>,
        ]}
      />
    );
  }
}

export default BookingRequest;
