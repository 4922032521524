import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import PastExams from "../components/Bookings/PastExams";
import ScheduledExams from "../components/Bookings/ScheduledExams";

class StudentDashboard extends Component {
  render() {
    return (
      <Container style={{ marginTop: "3em" }}>
        <ScheduledExams />
        <PastExams />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    kc: state.keycloak,
  }),
  {}
)(StudentDashboard);
