import React, { Component } from "react";
import moment from "moment";
import EmailLayout from "./EmailLayout";
import { Trans } from "react-i18next";

class PurchaseReceipt extends Component {
  render() {
    return (
      <EmailLayout
        t={this.props.t}
        content={[
          <table key="" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <h1>{this.props.t("Emails.PurchaseReceipt.header")}</h1>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{this.props.name}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{`${this.props.t(
                    "Emails.PurchaseReceipt.invoiceNumber"
                  )}${this.props.invoiceNumber}`}</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>
                    {this.props.t("Emails.PurchaseReceipt.businessNumber")}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>
                    {moment().format("MMMM Do YYYY, " + this.props.timeFormat)}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: 400 }}>
                          <b>{this.props.examTitle}</b>
                        </td>
                        <td>
                          <b>
                            <Trans
                              t={this.props.t}
                              i18nKey="general.formattedPrice"
                              defaults="{{price}}"
                              values={{ price: this.props.examCost }}
                            />
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={3} color="black" />
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: 400 }}>
                          <b>HST ({this.props.taxRate} %)</b>
                        </td>
                        <td>
                          <b>{this.props.taxCost}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={3} color="black" />
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: 310 }} />
                        <td style={{ width: 90 }}>
                          <b>{this.props.t("general.total")}</b>
                        </td>
                        <td>
                          <b>
                            <Trans
                              t={this.props.t}
                              i18nKey="general.formattedPrice"
                              defaults="{{price}}"
                              values={{ price: this.props.total }}
                            />
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={3} color="black" />
                </td>
              </tr>
            </tbody>
          </table>,
        ]}
      />
    );
  }
}

export default PurchaseReceipt;
