const en = {
  general: {
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",
    error: "Error",
    success: "Success",
    next: "Next",
    previous: "Previous",
    hours: "Hours",
    loading: "Loading",
    coupon: "Coupon",
    couponCode: "Coupon Code",
    apply: "Apply",
    reset: "Reset",
    exam: "Exam",
    exams: "Exams",
    duration: "Duration",
    myDashboard: "My Dashboard",
    pendingRequests: "Pending Requests",
    scheduledExams: "Scheduled Exams",
    learners: "Learners",
    settings: "Settings",
    application: "Application",
    download: "Download",
    camera: "Camera",
    approved: "Approved",
    rejected: "Rejected",
    approve: "Approve",
    reject: "Reject",
    banned: "Banned",
    completed: "Completed",
    pending: "Pending",
    deleted: "Deleted",
    username: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    birthDate: "Birth Date",
    learningInstitution: "Learning Institution",
    learningInstitutions: "Learning Institutions",
    status: "Status",
    error: "Error",
    actions: "Actions",
    search: "Search",
    close: "Close",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    time: "Time",
    no: "No",
    yes: "Yes",
    edit: "Edit",
    delete: "Delete",
    online: "Online",
    name: "Name",
    deactivate: "Deactivate",
    activate: "Activate",
    view: "View",
    date: "Date",
    save: "Save",
    cancel: "Cancel",
    year: "Year",
    softBooted: "Soft Booted",
    flagged: "Flagged",
    confirm: "Confirm",
    back: "Back",
    accept: "Accept",
    decline: "Decline",
    activate: "Activate",
    deactivate: "Deactivate",
    examName: "Exam Name",
    eventLog: "Event Log",
    scheduling: "Scheduling",
    scheduledExams: "Scheduled Exams",
    enter: "Enter",
    total: "Total",
    missingFieldsErrorMessage: "Fill out all highlighted fields",
    all: "All",
    title: "Title",
    active: "Active",
    deactivated: "Deactivated",
    language: "Language",
    formattedPrice: "${{ price }}",
    edit: "Edit",
    limit: "How Many Times Can a Student Book?",
    limitNotice: "Limit Notice",
    note: "Note:",
    nextBooking: "Next Avaliable Booking Time:",
    book: "Book",
    cancelled: "Cancelled",
    request: "Request",
  },
  App: {
    dashboard: "DashBoard",
    accountDetails: "Account Details",
    downloadApplication: "Download Application",
    logout: "Logout",
    loggingOut: "Logging out...",
  },
  AccountDetails: {
    header: "Account Details",
    timezone: "Time Zone",
    selectTimezone: "Select Timezone",
    learningInstitution: "Learning Institution",
    selectLearningInstitution: "Select Learning Institution",
    province: "Province",
    selectProvince: "Select Province",
    dateOfBirth: "Date of Birth",
    streetAddress: "Street Address",
    city: "City",
    postalCode: "Postal Code",
    personalHeadshot: "Personal Headshot",
    saveAccountDetails: "Save Account Details",
    updatingError: "An Error occurred when updating account details.",
    updatingSuccess: "Your account has been updated.",
    PST: "Pacific Time (PT) - UTC-8",
    MST: "Mountain Time (MT) - UTC-7",
    CST: "Central Time (CT) - UTC-6",
    EST: "Eastern Time (ET) - UTC-5",
    AST: "Atlantic Time (AT) - UTC-4",
    NST: "Newfoundland Time (NT) - UTC-3:30",
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NT: "Northwest Territories",
    NS: "Nova Scotia",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  },
  ExamDetails: {
    header: "Book Exam",
    examPrice: "Exam Price",
    examDetails: "Exam Details",
    price: "Price",
    description: "Description",
    notes: "Notes",
    tax: "Tax",
    discount: "Discount",
    datePending: "Date (pending): ",
    creditCardNumber: "Credit Card Number",
    creditCardExpiration: "Credit Card Expiration",
  },
  SelectExam: {
    header: "Book Exam",
    selectTrainingProvider: "Please Select a Training Provider",
    selectExamProvider: "Please Select an Exam Provider",
    selectExam: "Please Select an Exam",
  },
  ConfirmBooking: {
    provinceMustBeSet:
      "Province must be set in Account Details in order to process order",
    confirmOrder: "Confirm Order",
    confirming: "Confirming...",
    pendingApproval: "eProctor Booking Request - Pending Approval",
    bookingError: "Unable to process your order.",
    maxCapacity: "Exam has reached max booking capacity.",
    unableBookTime: "Unable to book for specified time",
    dayBlocked: "Unable to book for specified day",
    noFilesUploaded: "No files uploaded.",
  },
  SelectDates: {
    time: "Time",
    selectDate: "Please Select Date",
    fullyBooked: "Fully Booked",
  },
  PaymentDetails: {
    header: "Payment Details",
    fileTooLargeError: "File must be 5MB or less.",
    invalidFileTypeError: "File must be of type jpeg or png",
    couponError: "Coupon Error",
    couponApplied: "Coupon Applied",
    expMonth: "Expiration - Month",
    expYear: "Expiration - Year (2 Digits)",
    ccv: "Credit Card CVV2",
  },
  Receipt: {
    header: "Book Exam - Request Submitted",
    confirmEligibilityMessage: `Thank you for scheduling your exam with eProctor Canada. We have
          received your request and will confirm your eligibility. You will
          receive a confirmation email when the exam has been confirmed, at
          which time the payment below will be processed.`,
    downloadEproctorClientMessage: `Please test the system you will be using to take your exam by
          downloading the eProctor application and running the system
          requirements tests. This will confirm your system is capable of
          running the application and you will be able to access your exam
          when it is scheduled.`,
    questionsMessage: `If you have any questions or need to make changes to this request,
          please contact support@eproctorcanada.com`,
    bookingDetails: "Booking Details",
    orderDetails: "Order Details",
  },
  Dashboard: {
    scheduledAdminLabel: "View current and future scheduled Exams",
    pendingRequestsAdminlabel: "Review and approve pending Exam requests",
    examsAdminLabel: "View, Add, and Edit exams for Learners to take",
    learnersAdminLabel: "Review learner details",
    scheduleExams: "Schedule Exams",
    rescheduleAdminLabel: "Reschedule and Remove learner exams.",
    settingsAdminLabel: "View and Edit settings for this Learning Institution",
  },
  ScheduledExams: {
    header: "Scheduled Exams",
    welcome: "Welcome",
    downloadApplication: "Download Application",
    bookExam: "Book an Exam",
    downloadInstructions: `Within 30 minutes of your exam start time, please download
      the <1>application</1>. Once the
      download is complete, please enter your login credentials
      again and select the exam by clicking on the title. This
      will notify the Proctor you are ready to begin. The Proctor
      will walk you through the check-in process.
      <3>
        PLEASE NOTE: Ensure that you have downloaded the latest
        version of the eProctor application client or you may
        experience technical difficulties and not have access to
        all new features. Should you have questions about what
        version numbers, please contact support.
      </3>`,
  },
  ExamBlock: {
    examCompleted: "This exam has been completed",
    bookingApproved: "Booking has been approved",
    notPresentForExam: "Not present for scheduled exam",
    bookingRejected: "Booking has been rejected",
    approvalTimeFrameMessage:
      "Your exam will be approved within 1 business day. You can expect to receive a notification, via email, upon approval.",
  },
  PastExams: {
    header: "Past Exams",
  },
  ClientDownload: {
    header: "Download Application",
    instructionsStepOne: "Select and download the application for your system",
    instructionsStepTwo: `Open the application and select the exam you are scheduled to write`,
    instructionsStepThree: `Follow the instructions as they are presented to reach
          eProctor Canada. Your login credentials for eProctor
          Canada and the exam provider's system (e.g. PSKN) are
          required`,
    systemRequirements: "Minimum System Requirements",
    networkRequirements: "Network Requirements",
    uploadSpeed: "Upload Speed",
    downloadSpeed: "Download Speed",
    minUploadSpeed: "2.5 Mbps Minimum",
    minDownloadSpeed: "2.5 Mbps Minimum",
    computerRequirements: "Computer Requirements",
    webcamWithMicrophone: "Webcam with microphone (or separate microphone)",
    windows: "Windows",
    minWindowsVersion: "Windows 7 or later",
    mac: "Mac",
    minMacVersion: "OS X 10.9 or later",
    downloadMessage: "Your download should begin automatically.",
    didntWork: "Didn’t work? Click <1>here</1> and try again",
    applicationDownload: "Application Download",
  },
  PendingRequests: {
    receiptEmailSubject: "eProctor Purchase Receipt - Booking Confirmed",
    bookingConfirmedEmail: "eProctor Booking Confirmed",
    bookingRejectedEmail: "eProctor Exam Request - Rejected",
    submittedDate: "Submitted Date",
    bookingDate: "Booking Date",
    downloadFile: "Download File",
    paymentError: "Payment Error",
  },
  RejectModal: {
    rejectBooking: "Reject Booking",
    rejectReasoning: "Reject Reasoning",
  },
  RescheduleDates: {
    sendUserNotification:
      "Would you like to send the user a notification of the changes?",
  },
  Exam: {
    errorAddingHeader: "Error Adding Exam",
    daysAvailable: "Days Available",
    days: "Days",
    timesAvailable: "Times Available",
    capacity: "Capacity",
    cost: "Cost",
    addExam: "Add Exam",
    notApplicable: "Not Applicable",
    enableFileUpload: "Enable file upload (certificates)",
    checkinScript: "Proctor Rules / Check-in Script",
    studentRules: "Student Rules",
    uploadDescription: "Upload Description",
    from: "From",
    to: "To",
    limit: "Limit",
  },
  ExamTable: {
    deleteExam: "Delete Exam",
    confirmDelete: "Are you sure you want to delete",
    confirmCancel: "Are you sure you want to cancel this booking?",
    learningInstitutions: "Learning Institution(s)",
    added: "Added",
    bookingLimit: "Booking Limit",
  },
  RescheduleExam: {
    header: "Reschedule Exam",
    resheduleEmailSubject: "eProctor Booking Rescheduled",
    dateAndTimeSlot: "Date and Time Slot",
    reschedule: "Reschedule",
    deleteBooking: "Delete Booking",
    confirmDelete: "Are you sure you want to delete this booking?",
    showFutureBookings: "Only show future bookings",
    cancelExam: "Cancel Exam",
    cancelExamMsg:
      "Are you sure you want to cancel this booking? User will not automatically be refunded when booking is cancelled.",
  },
  Learners: {
    replays: "Replays",
    registeredOn: "Registered On",
    replays: "Replays",
    viewBookings: "View Bookings",
  },
  LearnerBookings: {
    bookingsFor: "Bookings for",
    recordings: "Recordings",
    confirmRequestRecordings: "Request the following recordings?",
    requestDownloadAgain: "Request Failed. Try Again?",
  },
  InstitutionsTable: {
    addInstitution: "Add Institution",
    institution: "Institution",
    addOrEditInstitution: "Add/Edit Institution",
    errorAddingClient: "Error Adding Client",
    institutionName: "Institution Name",
    pointOfContactName: "Point of Contact Name",
    pointOfContactEmail: "Point of Contact Email",
    availabilityStatus: "Availability Status",
    pointOfContact: "Point of Contact",
    contactEmail: "Contact Email",
  },
  BlockedDays: {
    header: "Blocked Days",
    addBlockedDay: "Add Blocked Day",
    confirmDelete: "Are you sure you want to delete this blocked day?",
    missingFieldsError: "Please fill out name and date.",
    everyYearLabel: "Date should be blocked every year",
    everyYear: "Every Year",
    allDay: "All Day",
    allDayLabel: "All Day?",
    times: "Time Slot to Block",
    overlapError:
      "Blocked Day Overlaps with existing blocked day/time slot for given exams.",
  },
  ExamSchedule: {
    createRoom: "Create Room",
  },
  ProctoringSession: {
    expandView: "Expanded View",
    cycleMaxUsers: "Cycle Max Users",
    faceToFaceDisconnected: "User has disconnected from face to face",
    faceToFaceConnected: "You are now connected",
    joinedSession: "Joined Session",
    noLearnersInExam: "No Current Learners in Exam",
    noLearnersInExamMessage: "We will let you know when someone joins.",
    pushExamUrl: "Push Exam Url",
    url: "URL",
    pushExam: "Push Exam",
    pushExamAllUsers: "Push Exam To All Users",
    lostConnection: "Lost Connection",
    reJoinedSession: "Rejoined Session",
  },
  WebCamUser: {
    minimize: "Minimize",
    maximize: "Maximize",
    ban: "Ban",
    signOut: "Sign Out",
    flag: "Flag",
    singleLink: "Single Link",
    linkAll: "Link All",
    banUser: "Ban User",
    confirmBan: "Is it okay to ban",
    softBanUser: "Soft Boot User",
    confirmSoftBoot: "Is it okay to soft boot",
    callLearner: "Call Learner",
    userDisconnectedHeader: "Connection Lost",
    userDisconnectedMessage: "Attempting to restablish connection.",
  },
  Checkin: {
    learnerRejoinedHeader: "Learner Rejoined",
    learnerRejoinedMessage: "Learner has rejoined check in",
    learnerLeftHeader: "Learner Left",
    learnerLeftMessage: `The learner has left the check in area or is having Network
          Problems.`,
    webcamNotReadableHeader: "Webcam Not Readable",
    webcamNotReadableMessage: `Unable to access Proctors webcam. Please ensure webcam is not
          in use by another program.`,
    checkinScript: "Check-in Script",
    noRules: "No Rules For Exam.",
    userTyping: "is Typing...",
  },
  Chat: {
    proctor: "(Proctor)",
    typeMessageHere: "Type Message Here",
  },
  CheckinQueue: {
    header: "Check-in Queue",
    clientVersion: "Client Version",
    checkIn: "Check-in",
    noRoomsAvailable: "No Rooms Available",
    moveUser: "Move User",
    noUsersInRoom: "No users in this Room.",
    deleteRoom: "Delete Room",
  },
  CouponTable: {
    over100Error: "Coupon can not be over 100%.",
    addCoupon: "Add Coupon",
    addOrEditCoupon: "Add/Edit Coupon",
    missingFieldsErrorHeader: "Error Adding Coupon",
    couponCode: "Coupon Code",
    couponValue: "Coupon Value",
    couponValueType: "Coupon Value Type",
    valueType: "Value Type",
    selectExams: "Please Select Exams",
    maxUses: "Max Number of Uses",
    availabilityStatus: "Availability Status",
    value: "Value",
    exams: "Exam(s)",
    maxUses: "Max Uses",
    timesUsed: "Times Used",
    deactivateCoupon: "Deactivate Coupon",
    activateCoupon: "Activate Coupon",
    deactivateCouponConfirm: "Are you sure you want to deactivate this Coupon?",
    activateCouponConfirm: "Are you sure you want to activate this Coupon?",
  },
  JoinExamModal: {
    joinAnExam: "Join An Exam",
    join: "Join",
    leaveExam: "Leave Exam",
    joinExam: "Join Exam",
  },
  CreateRoom: {
    addRoom: "Add Room",
    title: "Title",
    roomTitle: "Room Title",
    dateAndTime: "Date & Time",
    submit: "Submit",
  },
  PictureUploader: {
    clickToUpload: "Click here to upload.",
    fileTooLargeError: "File must be 5MB or less.",
    incorrectFileTypeError: "File must be of type JPEG or PNG",
    text: "Click on box to upload personal Headshot",
  },
  Emails: {
    emailSubject: "Email Subject",
    supportCenter: "eProctor Support Centre",
    tollFree: "Toll Free",
    BookingApproval: {
      header: "Booking Confirmed for {{ examTitle }}",
      message: `Your exam on {{ bookingDate }} {{ timezone }} has been approved.
            Please pay close attention to the following:`,
      timezoneWarning: `Make sure your computer and your eProctor account time zone
            setting matches the time zone of your location. (For example, if
            you are in Alberta, your time zone should be Mountain Time (MT) - UTC-7.) 
            You can verify or update this setting under
            your "Account Settings"`,
      preExamInstructions: `Prior to writing your exam, complete a
            <1>Speed Test</1> in the
            location you plan to write to ensure your internet speeds meet
            the
            <3>
              system requirements
            </3>
            of eProctor Canada.`,
      downloadLatestClient: `On the day of your exam download the latest version of the
            <1>
              eProctor Canada Application.
            </1>
            If you don’t have the latest version of the application, you
            won’t be able to access the exam.`,
      checkinTime: `30 minutes prior to the exam, login to the eProctor application to
            complete the validation and check-in process.`,
      lateWarning: `If you are more than 15 minutes late, you will not be allowed to
            write the exam.`,
      examDuration: "Exam Duration: {{duration}} hours",
      examRules: "Exam Specific Rules: {{ examRules }}",
    },
    BookingRejected: {
      header: "eProctor Booking Request - Rejected",
      invoiceNumber: `invoice #: {{invoiceNumber}}`,
      message: `Booking Request for {{ name }} to take {{ examTitle }}
            has been Rejected`,
      reason: "Reason Provided: {{ reason }}",
      supportContact: `For more information please contact the eProctor Support Centre via
            email at support@eproctorcanada.com or by calling 1-844-595-2561.`,
    },
    BookingRequest: {
      header: "Booking Request for {{ examTitle }} Pending",
      bookingCode: "Booking Code: {{ code }}",
      approvalTimeFrame:
        "Please note, it can take up to 2 business days for an exam booking to be approved.",
      postApprovalInstructions: `Once your exam has been approved, you will receive an email
            notification with your confirmation of payment. If you have any
            questions please contact the eProctor Support Centre via email at
            support@eproctorcanada.com or by calling 1-844-595-2561.`,
    },
    BookingReschedule: {
      header: "Booking Reschedule Confirmed for {{examTitle}}",
      message: `{{ name }}, Your previously schedule exam has been rescheduled to
            {{ bookingDate }} {{ timezone }}. Please login to your eProctor
            account 30 minutes prior to the exam to complete the validation
            and check-in process.`,
      duration: "Exam Duration: {{duration}} hours",
      examRules: "Exam Specific Rules: {{ examRules }}",
      defaultRules:
        "In Addition to the above, please have the following ready:",
      ruleOne: `The student is expected to remain in the exam room until the
            completion of the exam. Washroom breaks are not permitted.`,
      ruleTwo: "Please ensure the exam area is quiet and free of distractions.",
      ruleThree: `No cellular telephones, including smart watches and or other
            electronic devises are permitted in the area at the time of the
            exam`,
      ruleFour: "Government issued photo ID",
    },
    EmailReminder: {
      header: "eProctor Exam Reminder - {{ examTitle }}",
      message: `This note is to remind you of your upcoming scheduled exam with
            eProctor.`,
      examDate: "{{ examTitle }} on {{ date }}",
      checkinInstructions: `You can start the check-in process up to 30 minutes prior to your
            scheduled exam start time. The following will be required at
            check-in:`,
      checkinRequirementOne: "Photo ID",
      loginCredentialsMessage:
        "Login credentials (username and password) for your host exam system (PSKN)",
    },
    PurchaseReceipt: {
      header: "eProctor - Thank you for your order.",
      invoiceNumber: "Invoice #",
      businessNumber: "Business #808349930RT0001",
    },
    UserRegistration: {
      welcome: "Welcome to eProctor",
      welcomeMessage: `Welcome to eProctor - Canada's top provider of online proctoring
        services. To schedule your exam please visit http://eproctorcanada.com.
        For more information or technical support please contact
        support@eproctorcanada.com or by calling 1-844-595-2561.`,
    },
  },
};

export default en;
