import React from "react";
import EmailLayout from "./EmailLayout";
import { Trans } from "react-i18next";

const pStyle = {
  margin: "0px 0px 15px",
  fontFamily: "sans-serif",
  fontSize: 14,
  fontWeight: "normal",
};

class BookingRejected extends React.Component {
  render() {
    const { invoiceNumber, name, examTitle, reason } = this.props;
    return (
      <EmailLayout
        t={this.props.t}
        content={[
          <h2 key="">{this.props.t("Emails.BookingRejected.header")}</h2>,
          <h3 key="">
            <Trans
              t={this.props.t}
              i18nKey="Emails.BookingRejected.invoiceNumber"
            >
              invoice #: {{ invoiceNumber }}
            </Trans>
          </h3>,
          <p key="" style={pStyle}>
            <Trans t={this.props.t} i18nKey="Emails.BookingRejected.message">
              Booking Request for {{ name }} to take {{ examTitle }}
              has been Rejected
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            <Trans t={this.props.t} i18nKey="Emails.BookingRejected.reason">
              Reason Provided: {{ reason }}
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            {this.props.t("Emails.BookingRejected.supportContact")}
          </p>,
        ]}
      />
    );
  }
}

export default BookingRejected;
