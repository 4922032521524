import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Table,
  Icon,
  Modal,
  Button,
  Header,
  Grid,
  Segment,
} from "semantic-ui-react";
import { fetchBooking } from "../reducers/booking";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";

class ClientDownload extends Component {
  constructor(props) {
    super(props);

    this.show = this.show.bind(this);
    this.close = this.close.bind(this);

    this.state = {
      open: false,
    };
  }

  show() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div>
        <Container>
          <h1
            style={{
              textAlign: "center",
              fontSize: "40px",
              marginBottom: "40px",
            }}
          >
            {this.props.t("ClientDownload.header")}
          </h1>
          <div className="ui three column grid instructions">
            <Grid.Row stretched>
              <Grid.Column>
                <Segment>
                  <div className="inst-icon">
                    <Icon name="download" />
                  </div>
                  <div className="inst-content">
                    {this.props.t("ClientDownload.instructionsStepOne")}
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <div className="inst-icon">
                    <Icon name="folder open outline" />
                  </div>
                  <div className="inst-content">
                    <span>
                      {this.props.t("ClientDownload.instructionsStepTwo")}
                    </span>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <div className="inst-icon">
                    <Icon name="map signs" />
                  </div>
                  <div className="inst-content">
                    <span>
                      {this.props.t("ClientDownload.instructionsStepThree")}
                    </span>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </div>
        </Container>
        <div className="downloads">
          <div className="ui equal width center aligned padded grid">
            <Grid.Column>
              <Icon name="apple" />
              <br />
              <Button
                onClick={this.show}
                href={
                  `${process.env.REACT_APP_API_ADDRESS}/api/client/download/mac` +
                  `?token=${this.props.kc.token}`
                }
              >
                {this.props.t("general.download")}
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Icon name="windows" />
              <br />
              <Button
                onClick={this.show}
                href={
                  `${process.env.REACT_APP_API_ADDRESS}/api/client/download/win` +
                  `?token=${this.props.kc.token}`
                }
              >
                {this.props.t("general.download")}
              </Button>
            </Grid.Column>
          </div>
        </div>
        <div className="min-reqs">
          <h1
            style={{
              textAlign: "center",
              fontSize: "22px",
              color: "white",
              fontWeight: "400",
            }}
          >
            {this.props.t("ClientDownload.systemRequirements")}
          </h1>
          <Grid.Row>
            <div className="home-buttons ui two column centered grid">
              <Grid.Column textAlign="center">
                <Table celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        {this.props.t("ClientDownload.networkRequirements")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name="arrow up" />{" "}
                        {this.props.t("ClientDownload.uploadSpeed")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.props.t("ClientDownload.minUploadSpeed")}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name="arrow down" />{" "}
                        {this.props.t("ClientDownload.downloadSpeed")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.props.t("ClientDownload.minDownloadSpeed")}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Table celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="3">
                        {this.props.t("ClientDownload.computerRequirements")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name="video camera" />
                        {this.props.t("general.camera")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.props.t("ClientDownload.webcamWithMicrophone")}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name="windows" />{" "}
                        {this.props.t("ClientDownload.windows")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.props.t("ClientDownload.minWindowsVersion")}
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="right">
                        <a
                          onClick={this.show}
                          href={
                            `${process.env.REACT_APP_API_ADDRESS}/api/client/download/win` +
                            `?token=${this.props.kc.token}`
                          }
                        >
                          {this.props.t("general.download")}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name="apple" />{" "}
                        {this.props.t("ClientDownload.mac")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.props.t("ClientDownload.minMacVersion")}
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="right">
                        <a
                          onClick={this.show}
                          href={
                            `${process.env.REACT_APP_API_ADDRESS}/api/client/download/mac` +
                            `?token=${this.props.kc.token}`
                          }
                        >
                          {this.props.t("general.download")}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </div>
          </Grid.Row>
        </div>
        <Modal open={open} onClose={this.close} closeIcon>
          <Header
            icon="download"
            content={this.props.t("ClientDownload.applicationDownload")}
          />
          <Modal.Content style={{ textAlign: "center" }}>
            <p>{this.props.t("ClientDownload.downloadMessage")}</p>
            <p>
              <Trans i18nKey="ClientDownload.didntWork">
                Didn't work? Click <a href="/download">here</a> and try again
              </Trans>
            </p>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { fetchBooking }
  )(ClientDownload)
);
