import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Comment,
  Form,
  Header,
  Segment,
  Feed,
  TextArea,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  addComment,
  updateNewMessage,
  updateTyping,
  updateChatBoxes,
} from "../reducers/chat";
import { withTranslation } from "react-i18next";
import moment from "moment";

export class Chat extends Component {
  constructor(props) {
    super(props);
    this.comment = this.comment.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.refreshTypingStatus = this.refreshTypingStatus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      message: "",
      lastTypedTime:"",
      typingDelayMillis: 3000,
      sent: false,
      notTypeSent: false
    };
  }
  handleInputChange(e) {
    this.setState({message: e.target.value});
  }
  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.setState({sent: false, notTypeSent: false });
      this.props.updateTyping(false);
      e.preventDefault();
      if (this.state.message !== "") {
        this.comment();
      }
    }else{
      this.updateLastTypedTime();
      this.props.updateTyping(true);
    }
  }
  updateLastTypedTime() {
    this.setState({ lastTypedTime: new Date() });
  }

  refreshTypingStatus() {
    let textarea = document.getElementById("reply-text");

    //called every second
    if(this.props.typing && !this.state.sent){
      //If user has not been sent notice and proctor is typing, emit notice.
      this.props.socket.emit("proctortyping", {
        is_typing:this.props.typing,
        to: this.props.user_id,
        room: this.props.room,
        user_id: this.props.kc.tokenParsed.sub
      });
      this.setState({sent: true });
    } else if(!this.props.typing && !this.state.notTypeSent){
      //user is not typing.
      this.setState({notTypeSent: true, sent:false });
      this.props.socket.emit("proctortyping", {
        is_typing:this.props.typing,
        to: this.props.user_id,
        room: this.props.room,
        user_id: this.props.kc.tokenParsed.sub
      });
    } else if(this.props.typing && new Date().getTime() - this.state.lastTypedTime.getTime() > this.state.typingDelayMillis) {
      this.props.updateTyping(false);
      this.setState({notTypeSent: false });
    }
    
  }
  comment() {
    const { socket } = this.props;
    socket.emit("message", {
      text: this.state.message,
      username: `${this.props.kc.tokenParsed.preferred_username} ${this.props.t(
        "Chat.proctor"
      )}`,
      to: this.props.user_id,
      room: this.props.room,
      user_id: this.props.kc.tokenParsed.sub,
    });

    this.props.addComment({
      room: this.room_id,
      text: this.state.message,
      timestamp: moment.now(),
      author: `${this.props.kc.tokenParsed.given_name} ${this.props.t(
        "Chat.proctor"
      )}`,
      user_id: this.props.user_id,
    });
    this.setState({ message: "" });
    this.props.updateNewMessage(true);
  }
  componentDidUpdate() {
    if (this.props.newMessage) {
      document.getElementById("chatFeed").scrollTop =
        document.getElementById("chatFeed").scrollHeight;
      this.props.updateNewMessage(false);
    }
  }
  componentDidMount() {
    document.getElementById("chatFeed").scrollTop =
      document.getElementById("chatFeed").scrollHeight;
    this.setState({
      lastTypedTime: new Date(0),
    });
    setInterval(this.refreshTypingStatus, 1000);
  }
  render() {
    const { username, socket_id, room_id } = this.props;

    return (
      <Comment.Group
        onClick={() => {
          const chatBoxes = this.props.chatBoxes.map((chatBox) =>
            chatBox.socket_id === socket_id
              ? { ...chatBox, unreadMessages: 0 }
              : { ...chatBox }
          );
          this.props.updateChatBoxes(chatBoxes);
        }}
      >
        <Header as="h3" dividing>
          {username}
        </Header>
        <Feed
          id="chatFeed"
          style={{ height: 300, width: 300, overflowY: "scroll" }}
        >
          {this.props.comments
            .filter((comment) => comment.room === room_id)
            .map((data, i) => {
              return (
                <Segment key={`${this.props.user_id}_comment_${i}`}>
                  <Comment>
                    <Comment.Content>
                      <Comment.Author as="a">{data.author}</Comment.Author>
                      <Comment.Metadata>
                        <div>
                          {moment
                            .unix(data.timestamp / 1000)
                            .format("DD MMM YYYY hh:mm a")}
                        </div>
                      </Comment.Metadata>
                      <Comment.Text>{data.text}</Comment.Text>
                    </Comment.Content>
                  </Comment>
                </Segment>
              );
            })}
        </Feed>
        <Form reply>
          <Form.Field
            autoFocus
            id="reply-text"
            placeholder={this.props.t("Chat.typeMessageHere")}
            control={TextArea}
            onKeyPress={this.handleKeyPress}
            onBlur={this.refreshTypingStatus}
            onChange={this.handleInputChange}
            maxLength="200"
            value={this.state.message}
          />
        </Form>
      </Comment.Group>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      newMessage: state.chat.newMessage,
      chatBoxes: state.chat.chatBoxes,
      typing: state.chat.typing,
    }),
    { addComment, updateNewMessage, updateTyping, updateChatBoxes }
  )(Chat)
);
