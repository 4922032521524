import React, {Component} from 'react';
const poster = require('../images/webcam_loading.jpg');

class ExpandedDesktop extends Component {
    componentDidMount() {
        this.video.srcObject = this.props.media;
    }
    shouldComponentUpdate(props) {
        return this.props.media !== props.media;
    }
    componentDidUpdate() {
        this.video.srcObject = this.props.media;
    }

    render() {
        const {style} = this.props;
        return (
            <video
                style={style}
                autoPlay
                poster={String(poster)}
                ref={(video) => { this.video = video; }}
            />
        );
    }
}

export default ExpandedDesktop;

