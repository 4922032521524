import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Header, Grid } from "semantic-ui-react";
import LearnersBookingsTable from "../components/LearnersBookingsTable";
import { fetchLearnerWithBookings } from "./../reducers/learners";
import { requestRecordings } from "./../reducers/booking";
import { withTranslation } from "react-i18next";
import MainMenu from "./../components/MainMenu";
import ConfirmationModal from "../components/ConfirmationModal";
import moment from "moment-timezone";
import parseTimezone from "../lib/helperServices";

class LearnerBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      booking_id: "",
      exam: "",
      date: "",
    };

    this.handleRequestRecordings = this.handleRequestRecordings.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
  }
  componentDidMount() {
    this.props.fetchLearnerWithBookings(this.props.match.params.user_id);
  }
  async handleRequestRecordings() {
    await this.props.requestRecordings(this.state.booking_id);
    window.location.reload(false);
  }
  showConfirmationModal({ booking_id, exam, date }) {
    this.setState({
      showConfirmationModal: true,
      booking_id,
      exam,
      date,
    });
  }
  render() {
    let timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    const timeFormat =
      localStorage.getItem("lang") === "en"
        ? "MMMM Do YYYY - h:mm A"
        : "MMMM Do YYYY - HH:mm";
    const username = "";
    const bookings = this.props.selected ? this.props.selected : [];
    return (
      <Container>
        <MainMenu />
        <Header style={{ textAlign: "left", fontSize: "26px" }}>
          {`${this.props.t("LearnerBookings.bookingsFor")} ${username}`}
        </Header>
        <ConfirmationModal
          open={this.state.showConfirmationModal}
          close={() => this.setState({ showConfirmationModal: false })}
          submit={this.handleRequestRecordings}
          headerText={`${this.props.t(
            "LearnerBookings.confirmRequestRecordings"
          )}`}
          confirmationMsg={`${username}: ${this.state.exam} - ${moment(
            this.state.date
          )
            .tz(timezone)
            .format(timeFormat)}`}
        />
        <Grid style={{ border: "1px solid #efefef" }}>
          <Grid.Row>
            <Grid.Column>
              <LearnersBookingsTable
                data={bookings}
                handleRequestRecordings={this.showConfirmationModal}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      selected: state.learners.selected,
    }),
    { fetchLearnerWithBookings, requestRecordings }
  )(LearnerBookings)
);
