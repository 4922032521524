import React from "react";
import EmailLayout from "./EmailLayout";
import { Trans } from "react-i18next";

const pStyle = {
  margin: "0px 0px 15px",
  fontFamily: "sans-serif",
  fontSize: 14,
  fontWeight: "normal",
};

class BookingApproval extends React.Component {
  render() {
    const { examTitle, bookingDate, timezone, duration, examRules } =
      this.props;

    return (
      <EmailLayout
        t={this.props.t}
        content={[
          <h2 key="">
            <Trans t={this.props.t} i18nKey="Emails.BookingApproval.header">
              Booking Confirmed for {{ examTitle }}
            </Trans>
          </h2>,
          <p style={pStyle}>{this.props.name},</p>,
          <p>
            <Trans t={this.props.t} i18nKey="Emails.BookingApproval.message">
              Your exam on {{ bookingDate }} {{ timezone }} has been approved.
              Please pay close attention to the following:
            </Trans>
          </p>,
          <ul>
            <li>{this.props.t("Emails.BookingApproval.timezoneWarning")}</li>
            <li>
              <Trans
                t={this.props.t}
                i18nKey="Emails.BookingApproval.preExamInstructions"
              >
                Prior to writing your exam, complete a
                <a href="https://www.speedtest.net/">Speed Test</a> in the
                location you plan to write to ensure your internet speeds meet
                the
                <a href="https://www.eproctorcanada.com/faq/">
                  system requirements
                </a>
                of eProctor Canada.
              </Trans>
            </li>
            <li>
              <Trans
                t={this.props.t}
                i18nKey="Emails.BookingApproval.downloadLatestClient"
              >
                On the day of your exam download the latest version of the
                <a href="https://app.eproctorcanada.com/download">
                  eProctor Canada Application.
                </a>
                If you don’t have the latest version of the application, you
                won’t be able to access the exam.
              </Trans>
            </li>
            <li>{this.props.t("Emails.BookingApproval.checkinTime")}</li>
            <li>{this.props.t("Emails.BookingApproval.lateWarning")}</li>
          </ul>,
          <p key="" style={pStyle}>
            <Trans
              t={this.props.t}
              i18nKey="Emails.BookingApproval.examDuration"
            >
              Exam Duration: {{ duration }} hours
            </Trans>
          </p>,
          <p key="" style={pStyle}>
            <Trans t={this.props.t} i18nKey="Emails.BookingApproval.examRules">
              Exam Specific Rules: {{ examRules }}
            </Trans>
          </p>,
        ]}
      />
    );
  }
}

export default BookingApproval;
