import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Header, Segment, Form, TextArea, Button } from "semantic-ui-react";
import { saveEvent, updateNoteText, addNote } from "../reducers/session";
import { connect } from "react-redux";
import NoteLog from "../components/NoteLog";
import EventLog from "../components/EventLog";
import { withTranslation } from "react-i18next";
import moment from "moment";

class CurrentUserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleSaveNote = this.handleSaveNote.bind(this);
    this.handleNoteText = this.handleNoteText.bind(this);
  }
  handleSaveNote() {
    let tmpEvent = {
      session_id: this.props.session.id,
      timestamp: moment.now(),
      event: JSON.stringify(this.props.noteText),
      proctor_id: 0,
    };
    this.props.addNote({
      event: tmpEvent,
      user_id: this.props.maximizedUser.id,
    });
    this.props.updateNoteText("");
  }
  handleNoteText(e) {
    this.props.updateNoteText(e.target.value);
  }
  render() {
    if (typeof this.props.session !== "object") {
      return null;
    }
    return (
      <React.Fragment>
        <Header as="h2" attached="top">
          {this.props.session.username}
        </Header>
        <Segment attached>
          <Form>
            <Form.Field
              id="form-textarea-control-opinion"
              control={TextArea}
              onChange={this.handleNoteText}
              value={this.props.noteText}
            />
            <Form.Field
              id="form-button-control-public"
              control={Button}
              content={this.props.t("general.save")}
              floated="right"
              onClick={this.handleSaveNote}
            />
          </Form>
          <NoteLog />
        </Segment>
        <EventLog
          events={this.props.events}
          header={this.props.t("general.eventLog")}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      noteText: state.session.noteText,
      notes: state.session.notes,
      maximizedUser: state.session.maximizedUser,
      currentExam: state.session.currentExam,
      events: state.session.events,
    }),
    { saveEvent, updateNoteText, addNote }
  )(CurrentUserDetails)
);
