import React, { Component } from "react";

class VolumeIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = { audioContext: null };
  }
  componentDidMount() {
    let meter = null;
    let canvasContext = null;
    let WIDTH = 500;
    let HEIGHT = 50;

    // grab our canvas
    canvasContext = document
      .getElementById("meter_" + this.props.user_id)
      .getContext("2d");

    // grab an audio context
    let audioContext = new AudioContext();
    this.setState({
      audioContext: audioContext,
    });

    // Create an AudioNode from the stream.
    let mediaStreamSource = audioContext.createMediaStreamSource(
      this.props.webcamStream
    );

    // Create a new volume meter and connect it.
    meter = createAudioMeter(audioContext);
    mediaStreamSource.connect(meter);

    // kick off the visual updating
    drawLoop();

    function drawLoop(time) {
      // clear the background
      canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
      var my_gradient = canvasContext.createLinearGradient(0, 0, 170, 0);
      // check if we're currently clipping
      if (meter.checkClipping()) canvasContext.fillStyle = "red";
      else my_gradient.addColorStop(0, "#0089a8");
      my_gradient.addColorStop(0.5, "#64c048");
      my_gradient.addColorStop(1, "#2c3c4c");
      canvasContext.fillStyle = my_gradient;
      // draw a bar based on the current volume
      canvasContext.fillRect(0, 0, meter.volume * WIDTH * 1.4, HEIGHT);
      window.requestAnimationFrame(drawLoop);
    }

    function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
      let processor = audioContext.createScriptProcessor(512);
      processor.onaudioprocess = volumeAudioProcess;
      processor.clipping = false;
      processor.lastClip = 0;
      processor.volume = 0;
      processor.clipLevel = clipLevel || 0.98;
      processor.averaging = averaging || 0.95;
      processor.clipLag = clipLag || 750;

      // this will have no effect, since we don't copy the input to the output,
      // but works around a current Chrome bug.
      processor.connect(audioContext.destination);

      processor.checkClipping = function () {
        if (!this.clipping) return false;
        if (this.lastClip + this.clipLag < window.performance.now())
          this.clipping = false;
        return this.clipping;
      };

      processor.shutdown = function () {
        this.disconnect();
        this.onaudioprocess = null;
      };

      return processor;
    }

    function volumeAudioProcess(event) {
      let buf = event.inputBuffer.getChannelData(0);
      let bufLength = buf.length;
      let sum = 0;
      let x;

      // Do a root-mean-square on the samples: sum up the squares...
      for (let i = 0; i < bufLength; i++) {
        x = buf[i];
        if (Math.abs(x) >= this.clipLevel) {
          this.clipping = true;
          this.lastClip = window.performance.now();
        }
        sum += x * x;
      }

      // ... then take the square root of the sum.
      let rms = Math.sqrt(sum / bufLength);

      // Now smooth this out with the averaging factor applied
      // to the previous sample - take the max here because we
      // want "fast attack, slow release."
      this.volume = Math.max(rms, this.volume * this.averaging);
    }
  }
  componentWillUnmount() {
    //this.state.audioContext.close();
  }
  render() {
    return (
      <canvas
        id={"meter_" + this.props.user_id}
        width={this.props.width}
        height="40"
        style={{ maxWidth: "100%" }}
        className="volumeIndicator"
      />
    );
  }
}

export default VolumeIndicator;
