import axios from "axios";

/**
 * Get all Coupons in System
 * @returns {Promise<AxiosResponse | void>}
 */
export const getCoupons = () => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/coupons').then(res => res.data)
        .catch(error => console.log(error));
};

/**
 * Get a coupon by name
 * @returns {Promise<AxiosResponse | void>}
 */
 export const getCouponByName = (couponName, examId) => {
     return new Promise(async (resolve, reject) => {
         try {
            const res = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/coupons/check?couponName=${couponName}&examId=${examId}`);
            if (res.data.error) {
                reject(res.data.errorMessage);
            }
            resolve(res.data.coupon);
         } catch (e) {
            reject(e.message);
         }
     });
};

/**
 * update coupon Status
 * @returns {Promise<AxiosResponse | void>}
 */
export const updateAvailability = (coupon) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/coupon/statusUpdate', {
        id: coupon.id,
        coupon_availability:coupon.coupon_availability
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};
/**
 * update existing coupon
 * @returns {Promise<AxiosResponse | void>}
 */
export const updateExisting = (coupon) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/coupon/updateCoupon', {
        id: coupon.id,
        coupon_name: coupon.coupon_name,
        coupon_value: coupon.coupon_value,
        coupon_value_type: coupon.coupon_value_type,
        coupon_exams:coupon.coupon_exams,
        coupon_availability:coupon.coupon_availability,
        coupon_max_uses: coupon.coupon_max_uses
    }, {
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};

/**
 * Create new coupon
 * @returns {Promise<AxiosResponse | void>}
 */
export const saveNewCoupon = (coupon) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/coupon', {
        coupon_name: coupon.coupon_name,
        coupon_value: coupon.coupon_value,
        coupon_value_type: coupon.coupon_value_type,
        coupon_exams:coupon.coupon_exams,
        coupon_availability:coupon.coupon_availability,
        coupon_max_uses: coupon.coupon_max_uses
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};