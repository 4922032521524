import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Loader,
  Dimmer,
  Grid,
  Segment,
  Message,
  Form,
  Icon,
} from "semantic-ui-react";
import {
  saveBooking,
  updateTaxRate,
  updateTaxType,
} from "../../reducers/booking";
import { sendEmail } from "./../../lib/emailService";
import BookingRequest from "./../emails/BookingRequest";
import ExamDetails from "./ExamDetails";
import { addNotification } from "./../../reducers/notification";
import { withTranslation } from "react-i18next";
import i18n from "./../../i18n";

class ConfirmBooking extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      processing: false,
      displayError: false,
    };
  }
  componentWillMount() {
    if (this.props.kc.tokenParsed.province !== undefined) {
      let TAXES = require("./../../TAXES.json");
      this.props.updateTaxRate(TAXES[this.props.kc.tokenParsed.province].rate);
      this.props.updateTaxType(TAXES[this.props.kc.tokenParsed.province].type);
    }
  }
  onSubmit = (event, data) => {
    event.preventDefault();
    this.setState({disabled: true});
    let formData = this.props.formData;
    let payment_info = null;

    this.setState({
      processing: true,
    });

    if (formData.exam.requires_payment) {
      if (formData.coupon) {
        let taxes = (formData.examPrice * this.props.bookings.taxRate) / 100;
        payment_info = {
          amount: formData.examPrice || "0.00",
          taxes: taxes,
          expdate: `${formData.expYear}${formData.expMonth}`,
          pan: formData.pan,
          couponId: formData.couponId,
          couponFree: formData.couponFree,
        };
      } else {
        let taxes = (formData.exam.cost * this.props.bookings.taxRate) / 100;
        payment_info = {
          amount: formData.exam.cost || "0.00",
          taxes: taxes,
          expdate: `${formData.expYear}${formData.expMonth}`,
          pan: formData.pan,
          couponId: null,
        };
      }
    }

    const form = new FormData();
    form.append("upload", formData.upload);
    form.append("user_id", this.props.kc.subject);
    form.append(
      "booking_date",
      formData.examDate.tz("Canada/Atlantic").format("YYYY-MM-DDTHH:mm:ss")
    );
    form.append("booking_time", formData.examTime);
    form.append("payment_info", JSON.stringify(payment_info));
    form.append("tax_rate", this.props.bookings.taxRate);
    form.append("tax_type", this.props.bookings.taxType);
    form.append("client_id", this.props.formData.clientId);
    form.append("exam_id", this.props.formData.exam.id);

    const t = i18n.getFixedT(this.props.kc.tokenParsed.locale || "en");

    this.props
      .saveBooking(form)
      .then((booking) => {
        sendEmail({
          to: this.props.kc.tokenParsed.email,
          subject: t("ConfirmBooking.pendingApproval"),
          component: <BookingRequest t={t} examTitle={booking.data.title} />,
        })
          .then(() => {
            this.props.onForward({ booking: booking, order: booking.data });
          })
          .catch((error) => {
            this.props.addNotification({
              message: this.props.t(error.response.data),
              type: "error",
              timeout: true,
            });
          });
      })
      .catch((error) => {
        this.props.addNotification({
          message: this.props.t(error.response.data),
          type: "error",
          timeout: true,
        });

        this.props.onBackward({
          pan: this.props.formData.pan,
          expMonth: this.props.formData.expMonth,
          expYear: this.props.formData.expYear,
          cvv: this.props.formData.cvv,
          error:
            error.response.data || this.props.t("ConfirmBooking.bookingError"),
        });
      });
  };

  render() {
    let formData = this.props.formData;
    let exam = formData.exam;
    let taxes;
    let discount;
    let total;
    if (formData.coupon) {
      discount = exam.cost - formData.examPrice;
      taxes = (formData.examPrice * this.props.bookings.taxRate) / 100;
      total = formData.examPrice + taxes;
    } else {
      taxes = (exam.cost * this.props.bookings.taxRate) / 100;
      total = exam.cost + taxes;
      discount = 0;
    }

    return (
      <Container>
        <Dimmer active={this.state.processing} inverted>
          <Loader>{this.props.t("general.loading")}</Loader>
        </Dimmer>
        <Form>
          <Message
            visible={this.state.displayError}
            error={true}
            onDismiss={() => this.setState({ displayError: false })}
          >
            <Message.Header>{this.props.t("general.error")}</Message.Header>
            <p>{this.props.t("ConfirmBooking.provinceMustBeSet")}</p>
          </Message>
          <ExamDetails
            step={this.props.step}
            examTitle={exam.title}
            duration={exam.duration}
            cost={formData.exam.requires_payment ? "$" + exam.cost : "$" + 0}
            examDate={formData.examDate.toString()}
            requires_payment={exam.requires_payment}
            taxes={taxes}
            discount={discount}
            total={total}
            coupon={formData.coupon}
            pan={formData.pan}
            expMonth={formData.expMonth}
            expYear={formData.expYear}
          />

          <Segment>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Button
                    onClick={this.props.onBackward}
                    negative
                    floated="left"
                  >
                    <Button.Content visible>
                      <Icon name="left arrow" />{" "}
                      {this.props.t("general.previous")}
                    </Button.Content>
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button onClick={this.onSubmit} positive floated="right" disabled={this.state.disabled}>
                    <Button.Content visible>
                      {this.state.disabled ? this.props.t("ConfirmBooking.confirming") :this.props.t("ConfirmBooking.confirmOrder")}{" "}
                      <Icon name="right arrow" />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
      bookings: state.bookings,
      notifications: state.notifications,
    }),
    {
      saveBooking,
      updateTaxRate,
      updateTaxType,
      addNotification,
    }
  )(ConfirmBooking)
);
