import axios from "axios";

export const sendRequestForRecordings = (booking_id) =>
  axios
    .post(
      `${process.env.REACT_APP_API_ADDRESS}/api/recordings`,
      {
        booking_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res)
    .catch((err) => console.log(err));
