import React, { Component } from "react";
import { Button, Modal, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "react-table/react-table.css";
import {
  fetchExams,
  deleteExamCreator,
  changeModal,
  updateExamToDelete,
  updateLimitNotice,
  updateRules,
  updateStudentRules,
  updateExamToUpdate,
  setIsUpdating,
  updateExamName,
  updateDuration,
  sendMail,
  updateOpenAddExam,
  updateCost,
  updateDescription,
  updatePaymentRequired,
  updateCostDisabled,
  updateDaysAvailable,
  updateAvailableFromTime,
  updateAvailableToTime,
  updateClient,
  updateCapacity,
  updateLimit,
  updateFileUploadRequired,
  updateFileUploadDescription,
} from "../reducers/exam";

class ExamTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.exam.allExams,
      statusFilter: 'active'
    };

    this.submitDelete = this.submitDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleUpdateExam = this.handleUpdateExam.bind(this);
  }
  componentWillMount() {
    this.props.fetchExams();
  }
  handleUpdateExam(id) {
    let exam = this.props.exam.allExams.filter((data) => data.id === id);
    exam = exam[0];
    this.props.updateExamName(exam.title);
    this.props.updateDuration(exam.duration);
    this.props.updateLimitNotice(exam.limit_notice); 
    this.props.updateRules(exam.rules);
    this.props.updateStudentRules(exam.student_rules);
    this.props.updateCost(exam.cost === null ? "" : exam.cost);
    this.props.updatePaymentRequired(exam.requires_payment);
    this.props.updateDescription(exam.description);
    this.props.updateCostDisabled(!exam.requires_payment);
    this.props.updateExamToUpdate(id);
    this.props.updateFileUploadRequired(exam.file_upload_required);
    this.props.updateFileUploadDescription(exam.file_upload_description);
    this.props.updateExamToUpdate(id);

    const clients = [];
    for (let client of exam.clients) {
      clients.push(client.id);
    }
    this.props.updateClient(clients);
    this.props.updateCapacity(exam.capacity);
    this.props.updateLimit(exam.limit); 
    this.props.updateDaysAvailable(JSON.parse(exam.available_days));
    if (exam.available_from_time) {
      this.props.updateAvailableFromTime(
        moment(exam.available_from_time, "HH:mm:ss")
      );
    }
    if (exam.available_to_time) {
      this.props.updateAvailableToTime(
        moment(exam.available_to_time, "HH:mm:ss")
      );
    }
    this.props.setIsUpdating(true);
  }
  handleDeleteExam(id) {
    let exam = this.props.exam.allExams.filter((data) => data.id === id);
    exam = exam[0];
    this.props.updateExamToDelete(exam);
    this.props.changeModal();
  }
  filterTable(status){
    let exams;
    if(status.value == "active"){
      exams = this.props.exam.allExams.filter((data) => data.deleted === null);
      this.setState({statusFilter: "active"});
    }
    if(status.value == "all"){
      exams = this.props.exam.allExams;
      this.setState({statusFilter: "all"});
    }
    if(status.value == "deactivated"){
      exams = this.props.exam.allExams.filter((data) => data.deleted !== null);
      this.setState({statusFilter: "deactivated"});
    }
    this.setState({
      data: exams,
    });
  }
  submitDelete() {
    this.props.deleteExamCreator(this.props.exam.examToDelete);
    this.props.changeModal();
  }
  closeModal() {
    this.props.changeModal();
  }
  columns = [
    {
      Header: this.props.t("general.title"),
      accessor: "title",
      filterMethod: (filter, row) =>
        row[filter.id]
          .toLowerCase()
          .startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.duration"),
      accessor: "duration",
      filterMethod: (filter, row) =>
        row[filter.id].toString().startsWith(filter.value),
    },
    {
      Header: this.props.t("Exam.cost"),
      accessor: "cost",
      filterable: false,
    },
    {
      Header: this.props.t("ExamTable.learningInstitutions"),
      Cell: (row) => {
        if (row.original.clients) {
          let spanList = row.original.clients.map((client, index) => {
            return (
              <li key={`learning_instituations_${index}`}>
                {client.organization_name}
              </li>
            );
          });
          return <ul className="exams-list">{spanList}</ul>;
        }
      },
      style: {
        verticalAlign: "middle",
        alignSelf: "center",
      },
      sortable: true,
      filterable: true,
    },
    {
      Header: this.props.t("ExamTable.added"),
      accessor: "added",
      filterable: false,
      Cell: (row) => {
        return moment(row.original.createdAt).format("MMMM Do, YYYY");
      },
    },
    {
      Header: this.props.t("general.status"),
      accessor: "status",
      sortable: true,
      filterable: false,
      Cell: (row) => {
        return (
          <React.Fragment>
            {row.original.deleted == null ? (
              <span
                style={{ color: "green" }}
              >{this.props.t("general.active")}
              </span>
            ) : <span
                style={{ color: "red" }}
              >{this.props.t("general.deactivated")}
              </span>}
          </React.Fragment>
        );
      },
    },
    {
      Header: this.props.t("ExamTable.bookingLimit"),
      accessor: "added",
      filterable: false,
      Cell: (row) => {
        return row.original.limit;
      },
    },
    {
      Header: this.props.t("general.actions"),
      Cell: (row) => {
        return (
          <Dropdown button text="View">
            <Dropdown.Menu className="droppy">
              <Dropdown.Item
                style={{ zIndex: 2000 }}
                onClick={() => {
                  this.handleUpdateExam(row.original.id);
                  this.props.updateOpenAddExam();
                }}
              >
                {this.props.t("general.edit")}
              </Dropdown.Item>
              <Dropdown.Item
                style={{ zIndex: 2000 }}
                onClick={() => this.handleDeleteExam(row.original.id)}
              >
                {this.props.t("general.deactivate")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      style: {
        overflow: "inherit",
      },
      sortable: false,
      filterable: false,
    },
  ];
  render() {
      let options = [
        { value: "active", text: this.props.t("general.active") },
        { value: "all", text: this.props.t("general.all") },
        { value: "deactivated", text: this.props.t("general.deactivated") },
      ];
    return (
      <div>
        <Modal
          size="small"
          className="modalHOTFIX"
          open={this.props.exam.open}
          closeIcon
          onClose={() => {
            this.props.changeModal();
          }}
        >
          <Modal.Header>{this.props.t("ExamTable.deleteExam")}</Modal.Header>
          <Modal.Content>
            <p>
              {this.props.t("ExamTable.confirmDelete")}
              {this.props.exam.examToDelete.title}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.closeModal}>
              {this.props.t("general.no")}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={this.props.t("general.yes")}
              onClick={this.submitDelete}
            />
          </Modal.Actions>
        </Modal>
        <div style={{ marginBottom: 10, padding: 10}}>
          <label style={{ fontWeight: "bold", padding: 10,}}>{this.props.t("general.status")}: </label>
          <Dropdown
            value={this.state.statusFilter}
            placeholder={this.props.t("general.status")}
            onChange={(e, data) =>
              this.filterTable(data)
            }
            search
            selection
            options={options}
          />
        </div>
        <ReactTable
          ref="examTable"
          columns={this.columns}
          data={this.state.data.length > 0 ? this.state.data : this.props.exam.allExams.filter((data) => data.deleted === null)}
          filterable
          defaultPageSize={10}
          className="-striped -highlight test"
          minRows={0}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => ({ exam: state.exam }), {
    fetchExams,
    deleteExamCreator,
    updateLimitNotice,
    updateRules,
    updateStudentRules,
    changeModal,
    updateExamToDelete,
    updateExamToUpdate,
    setIsUpdating,
    updateExamName,
    updateDuration,
    sendMail,
    updateOpenAddExam,
    updateCost,
    updateDescription,
    updatePaymentRequired,
    updateCostDisabled,
    updateDaysAvailable,
    updateAvailableFromTime,
    updateAvailableToTime,
    updateClient,
    updateCapacity,
    updateLimit,
    updateFileUploadRequired,
    updateFileUploadDescription,
  })(ExamTable)
);
