import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import MainMenu from "./../components/MainMenu";
import VodVideo from "../components/VodVideo";
import { fetchSessionById } from "./../reducers/learners";

class Replay extends Component {
  componentWillMount() {
    this.props.fetchSessionById(this.props.match.params.session_id);
  }
  render() {
    return (
      <Container>
        <MainMenu />
        {this.props.learners.sessionToReplay.session_guid !== undefined ? (
          <VodVideo session={this.props.learners.sessionToReplay} />
        ) : null}
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    learners: state.learners,
  }),
  { fetchSessionById }
)(Replay);
