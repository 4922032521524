import React from "react";
import { Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const StatusDropdown = (props) => {
  const options = [
    { value: "pending", text: props.t("general.pending") },
    { value: "completed", text: props.t("general.completed") },
    { value: "approved", text: props.t("general.approved") },
    { value: "rejected", text: props.t("general.rejected") },
    { value: "cancelled", text: props.t("general.cancelled") },
    { value: "banned", text: props.t("general.banned") },
  ];
  return (
    <Dropdown
      style={{ background: "#0089a8", color: "white" }}
      options={options}
      onChange={(e, data) => props.updateStatus(data.value)}
      selection
      defaultValue={"approved"}
    ></Dropdown>
  );
};

export default withTranslation()(StatusDropdown);
