import React from "react";
import "semantic-ui-css/semantic.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Grid, Container, Modal, Button, Dimmer, Loader, Segment } from "semantic-ui-react";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import { fetchAllRooms, updateShowCreateRoom} from "../reducers/room";
import MainMenu from "./../components/MainMenu";
import { connect } from "react-redux";
import CheckinQueue from "./../components/CheckinQueue";
import CreateRoom from "./../components/CreateRoom";
import { withTranslation } from "react-i18next";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

class ExamSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      learners: [],
      exam: "",
      showCreateRoom: false,
    };
  }
  componentDidMount() {
    this.props.fetchAllRooms();
  }

  render() {
    return (
      <Container>        
        <MainMenu />
        <Modal
          open={this.state.open}
          closeIcon
          onClose={() => {
            this.setState({ open: false });
          }}
        >
          <Modal.Content>
            <CheckinQueue
              close={() => this.setState({ open: false })}
              room={this.state.room}
              ws={this.props.socket}
            />
          </Modal.Content>
        </Modal>

        <CreateRoom
          close={() => this.setState({ showCreateRoom: false })}
          open={this.state.showCreateRoom}
        />
        <Grid>
          <Grid.Column>
            <Button
              color="green"
              floated="right"
              onClick={() => this.props.updateShowCreateRoom(true)}
            >
              {this.props.t("ExamSchedule.createRoom")}
            </Button>
          </Grid.Column>
        </Grid>

        <Grid centered columns={2}>
          &nbsp;
        </Grid>
        <Dimmer.Dimmable as={Segment} dimmed={this.props.rooms.showLoader} style={{ border: "none", boxShadow: "none"}} >
          <Grid centered columns={2}>
            <Dimmer active={this.props.rooms.showLoader} inverted>
                <Loader>{this.props.t("general.loading")}</Loader>
            </Dimmer>
            {
              <BigCalendar
                events={this.props.rooms.events}
                style={{ height: "80vh", width: "80vw" }}
                views={["month", "day", "week"]}
                defaultView="week"
                step={60}
                showMultiDayTimes
                onSelectEvent={(event) => {
                  this.setState({
                    open: true,
                    room: event.room,
                  });
                }}
                defaultDate={new Date()}
              />
            }
          </Grid>
        </Dimmer.Dimmable>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      rooms: state.rooms,
    }),
    { fetchAllRooms, updateShowCreateRoom}

  )(ExamSchedule)
);
