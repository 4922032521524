import axios from "axios";

export const getAllRooms = () => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/rooms').then(res => res.data).catch(error => console.log(error));
};

export const storeRoom = (room) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/rooms',{...room}, {
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export const moveUserToRoom = (booking_id, room_id) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/rooms/move',{booking_id, room_id}, {
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export const deleteRoom = (room_id) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/rooms/delete/' + room_id, {}, {
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.data).catch(error => console.log(error));
}