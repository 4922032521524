import axios from "axios";

/**
 * Get all Clients in System
 * @returns {Promise<AxiosResponse | void>}
 */
export const getClients = () => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/clients').then(res => res.data)
        .catch(error => console.log(error));
};

/**
 * update client Status
 * @returns {Promise<AxiosResponse | void>}
 */
export const updateStatus = (client) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/client/statusUpdate', {
        id: client.id,
        online:client.online
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};

/**
 * update client Status
 * @returns {Promise<AxiosResponse | void>}
 */
export const updateExisting = (client) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/client/updateClient', {
        id: client.id,
        name: client.organization_name,
        contact: client.client_contact,
        email: client.client_email,
        online:client.online
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};

/**
 * update client Status
 * @returns {Promise<AxiosResponse | void>}
 */
export const saveNewClient = (client) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/clients', {
        name: client.organization_name,
        contact: client.client_contact,
        email: client.client_email,
        online:client.online
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};