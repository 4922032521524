import React from "react";
import { Dropdown } from "semantic-ui-react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { withTranslation } from "react-i18next";

const CouponTable = (props) => (
  <ReactTable
    columns={[
      {
        Header: props.t("general.name"),
        accessor: "coupon_name",
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("CouponTable.value"),
        accessor: "coupon_value",
        Cell: (row) => {
          return (
            <div>
              <span className="value-cell">
                {row.original.coupon_value}
                {row.original.coupon_value_type}
              </span>
            </div>
          );
        },
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("CouponTable.exams"),
        Cell: (row) => {
          if (row.original.coupon_exams.length > 0) {
            let selectedExams = props.allExams.filter((exam) => {
              return row.original.coupon_exams.includes(exam.id);
            });
            let spanList = selectedExams.map((exam, index) => {
              return <li key={`exam_coupon_${exam.id}`}>{exam.title}</li>;
            });
            return <ul className="exams-list">{spanList}</ul>;
          } else {
            return <span className="exams-cell">All</span>;
          }
        },
        style: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("CouponTable.maxUses"),
        accessor: "coupon_max_uses",
        Cell: (row) => {
          return (
            <div>
              <span className="value-cell">{row.original.coupon_max_uses}</span>
            </div>
          );
        },
        style: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("CouponTable.timesUsed"),
        accessor: "coupon_times_used",
        Cell: (row) => {
          return (
            <div>
              <span className="value-cell">
                {row.original.coupon_times_used}
              </span>
            </div>
          );
        },
        style: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        Header: props.t("general.active"),
        Cell: (row) => {
          if (row.original.coupon_availability === true) {
            return (
              <i
                style={{ color: "#5cb84b", fontSize: "26px" }}
                className="check icon"
              ></i>
            );
          } else {
            return (
              <i
                style={{ color: "#db4737", fontSize: "26px" }}
                className="close icon"
              ></i>
            );
          }
        },
        style: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: props.t("general.actions"),
        Cell: (row) => {
          if (row.original.coupon_availability === true) {
            return (
              <Dropdown
                style={{ background: "#0089a8", color: "white" }}
                button
                text="View"
              >
                <Dropdown.Menu className="droppy">
                  <Dropdown.Item
                    style={{ zIndex: 2000 }}
                    onClick={() => {
                      props.handleUpdateCoupon(row.original.id);
                    }}
                  >
                    {props.t("general.edit")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ zIndex: 2000 }}
                    onClick={() => props.handleCouponStatus(row.original.id)}
                  >
                    {props.t("general.deactivate")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          } else {
            return (
              <Dropdown
                style={{ background: "#0089a8", color: "white" }}
                button
                text="View"
              >
                <Dropdown.Menu className="droppy">
                  <Dropdown.Item
                    style={{ zIndex: 2000 }}
                    onClick={() => {
                      props.handleUpdateCoupon(row.original.id);
                    }}
                  >
                    {props.t("general.edit")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ zIndex: 2000 }}
                    onClick={() => props.handleCouponStatus(row.original.id)}
                  >
                    {props.t("general.activate")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          }
        },
        style: {
          overflow: "inherit",
          textAlign: "center",
          alignSelf: "center",
        },
        sortable: false,
        filterable: false,
      },
    ]}
    data={props.data}
    defaultPageSize={10}
    className="-striped -highlight test"
    minRows={0}
  />
);

export default withTranslation()(CouponTable);
