import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Header,
  Grid,
  Divider,
  Icon,
  Message,
} from "semantic-ui-react";
import { withRouter, NavLink } from "react-router-dom";
import parseTimezone from "../../lib/helperServices";
import { fetchScheduledBookings } from "../../reducers/booking";
import ExamBlock from "./ExamBlock";
import moment from "moment-timezone";
import { withTranslation, Trans } from "react-i18next";

class ScheduledExams extends Component {
  goToBookExams = () => {
    this.props.history.push(`./exams/book/`);
  };
  goToDownload = () => {
    this.props.history.push(`./download/`);
  };

  componentWillMount() {
    this.props.fetchScheduledBookings(this.props.kc.tokenParsed.sub);
  }

  /**
   * Returns a 'table' of scheduled exams. As well as the modal that transfers to
   * booking of an exam.
   * @returns {*}
   */
  render() {
    let timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    let timezone_array = this.props.kc.tokenParsed.timezone.split("");
    const timeFormat =
      localStorage.getItem("lang") === "en"
        ? "MMMM Do YYYY - h:mm A"
        : "MMMM Do YYYY - HH:mm";

    return (
      <div>
        <Container style={{ marginTop: "2em" }}>
          <div>
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    style={{
                      textTransform: "uppercase",
                      fontSize: "55px",
                      textAlign: "center",
                    }}
                    as="h1"
                  >
                    {this.props.t("ScheduledExams.welcome")}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <div className="home-buttons ui two column centered grid">
                  <div className="four column centered row">
                    <Grid.Column textAlign="center">
                      <Button onClick={this.goToDownload} positive>
                        {this.props.t("ScheduledExams.downloadApplication")}
                        <br />
                        <Icon name="download" />
                      </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Button onClick={this.goToBookExams} positive>
                        {this.props.t("ScheduledExams.bookExam")}
                        <br />
                        <Icon name="calendar plus" />
                      </Button>
                    </Grid.Column>
                  </div>
                </div>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Divider className="home-divider" />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={4}>
                  <Header textAlign="center" as="h1">
                    {this.props.t("ScheduledExams.header")}
                  </Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Divider className="home-divider" />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid>
              <Grid.Row>
                <Message className="home-message">
                  <Trans i18nKey="ScheduledExams.downloadInstructions">
                    Within 30 minutes of your exam start time, please download
                    the <NavLink to="/download">application</NavLink>. Once the
                    download is complete, please enter your login credentials
                    again and select the exam by clicking on the title. This
                    will notify the Proctor you are ready to begin. The Proctor
                    will walk you through the check-in process.
                    <strong>
                      PLEASE NOTE: Ensure that you have downloaded the latest
                      version of the eProctor application client or you may
                      experience technical difficulties and not have access to
                      all new features. Should you have questions about what
                      version numbers, please contact support.
                    </strong>
                  </Trans>
                </Message>
              </Grid.Row>
              <Grid.Row>
                {this.props.bookings.map((data, i) => {
                  if (data.user_id === this.props.kc.tokenParsed.sub) {
                    return (
                      <Grid.Column key={`${i}_scheduled_exam`} mobile={16} tablet={10} computer={5}>
                        <ExamBlock
                          timezone={`${timezone_array[0]}${timezone_array[2]}`}
                          past={false}
                          date={moment(data.booking_date)
                            .tz(timezone)
                            .format(timeFormat)}
                          status={data.approved}
                          title={
                            data.exam.title !== undefined ? data.exam.title : ""
                          }
                        />
                      </Grid.Column>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      (state) => ({
        bookings: state.bookings.scheduledBookings,
        kc: state.keycloak,
      }),
      { fetchScheduledBookings }
    )(ScheduledExams)
  )
);
