import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class CallButton extends Component {
  render() {
    const { user_id } = this.props;
    let icon = "eye";
    if (this.props.connected) {
      icon = "close";
    }
    return (
      <Button
        title={this.props.t("WebCamUser.callLearner")}
        circular
        icon={icon}
        size={this.props.size}
        onClick={() => {
          if (this.props.connected) {
            this.props.stopVideoCall(user_id);
          } else {
            this.props.startVideoCall(user_id);
          }
        }}
      />
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      sessions: state.session.sessions,
      connected: state.session.faceToFaceConnected,
    }),
    {}
  )(CallButton)
);
