const fr = {
  general: {
    emailAddress: "adresse e-mail",
    phoneNumber: "numéro de téléphone",
    error: "erreur",
    success: "succès",
    next: "prochain",
    previous: "précédent",
    hours: "heures",
    loading: "Chargement",
    coupon: "coupons",
    couponCode: "code promo",
    apply: "appliquer",
    reset: "réinitialiser",
    exam: "examen",
    exams: "examens",
    duration: "durée",
    myDashboard: "mon tableau de bord",
    pendingRequests: "demandes en attente",
    scheduledExams: "examens programmés",
    learners: "élèves",
    settings: "paramètres",
    application: "Application",
    download: "télécharger",
    camera: "caméra",
    approved: "approuvé",
    rejected: "rejetée",
    deleted: "Supprimé",
    approve: "approuvé",
    reject: "rejetée",
    banned: "banni",
    completed: "complété",
    pending: "en attente",
    deleted: "Deleted",
    username: "nom d'utilisateur",
    firstName: "prénom",
    lastName: "nom de famille",
    email: "adresse e-mail",
    birthDate: "date de naissance",
    learningInstitution: "établissement d'enseignement",
    learningInstitutions: "établissements d'enseignement",
    status: "statut",
    error: "Erreur",
    actions: "Actions",
    search: "recherche",
    close: "Close",
    sunday: "Dimanche",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    time: "heure",
    no: "Non",
    yes: "Oui",
    edit: "modifier",
    delete: "effacer",
    online: "en ligne",
    name: "nom",
    deactivate: "désactiver",
    activate: "Activate",
    view: "voir",
    date: "date",
    save: "sauvegarder",
    cancel: "annuler",
    year: "année",
    softBooted: "retiré",
    flagged: "Signalé",
    confirm: "Confirmer",
    back: "arrière",
    accept: "accepter",
    decline: "rejeter",
    activate: "Activer",
    deactivate: "Deactivate",
    examName: "Nom de l’examen",
    eventLog: "Registre des événements",
    scheduling: "planifier",
    scheduledExams: "examens programmés",
    enter: "Entrer",
    total: "total",
    missingFieldsErrorMessage: "Remplir tous les champs en surbrillance",
    all: "Toute",
    title: "titre",
    fileUploader: "téléchargeur de fichier",
    active: "Actif",
    language: "Langue",
    formattedPrice: "{{ price }}$",
    deactivated: "Désactivé",
    limit: "Combien de fois un étudiant peut-il réserver ?",
    limitNotice: "Avis de limite",
    nextBooking: "Prochaine heure de réservation disponible:",
    book: "Book",
    note: "VEUILLEZ NOTER:",
    cancelled: "Annulé",
    request: "Demande",
  },
  App: {
    dashboard: "tableau de bord",
    accountDetails: "détails du compte",
    downloadApplication: "télécharger l'application",
    logout: "déconnection",
    loggingOut: "Fermeture de session...",
  },
  AccountDetails: {
    header: "détails du compte",
    timezone: "fuseau horaire",
    selectTimezone: "Sélectionnez le fuseau horaire",
    learningInstitution: "établissement d'enseignement",
    selectLearningInstitution: "Select Learning Institution",
    province: "Province",
    selectProvince: "Sélectionnez la province",
    dateOfBirth: "date de naissance",
    streetAddress: "adresse municipale",
    city: "ville",
    postalCode: "code postal",
    personalHeadshot: "plan du visage personnel",
    saveAccountDetails: "enregistrer les détails du compte",
    updatingError:
      "Une erreur s’est produite lors de la mise à jour des détails du compte.",
    updatingSuccess: "Votre compte a été mis à jour.",
    PST: "heure du Pacifique (PT) - UTC-8",
    MST: "heure des Rocheuses  (MT) - UTC-7",
    CST: "heure du Centre (CT) - UTC-6",
    EST: "heure de l'Est (ET) - UTC-5",
    AST: "heure de l'Atlantique (AT) - UTC-4",
    NST: "heure de Terre-Neuve (NT) - UTC-3:30",
    AB: "Alberta",
    BC: "Colombie-Britannique",
    MB: "Manitoba",
    NB: "Nouveau-Brunswick",
    NL: "Terre-Neuve-et-Labrador",
    NT: "Territoires du Nord-Ouest",
    NS: "Nouvelle-Écosse",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Île-du-Prince-Édouard",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  },
  ExamDetails: {
    header: "Réservez un examen",
    examPrice: "Prix de l’examen",
    examDetails: "Détails de l’examen",
    price: "prix",
    description: "description",
    notes: "Notes",
    tax: "taxe",
    discount: "réduction",
    datePending: "Date (en attente): ",
    creditCardNumber: "numéro de carte de crédit",
    creditCardExpiration: "Expiration de la carte de crédit",
  },
  SelectExam: {
    header: "Réservez un examen",
    selectTrainingProvider: "Veuillez sélectionner un fournisseur de formation",
    selectExamProvider: "Veuillez sélectionner un fournisseur d’examen",
    selectExam: "Veuillez sélectionner un examen",
  },
  ConfirmBooking: {
    provinceMustBeSet:
      "La province doit être définie dans les détails du compte afin de traiter la commande.",
    confirmOrder: "confirmer la commande",
    pendingApproval:
      "Demande de réservation eProctor - En attente d’approbation",
    bookingError: "Impossible de traiter votre commande.",
    maxCapacity: "L'examen a atteint la capacité de réservation maximale.",
    unableBookTime: "Impossible de réserver pour l'heure spécifiée",
    dayBlocked: "Impossible de réserver pour le jour spécifié ",
    noFilesUploaded: "Aucun fichier téléchargé. ",
  },
  SelectDates: {
    selectDate: "Veuillez sélectionner la date",
    fullyBooked: "Réservation complète",
  },
  PaymentDetails: {
    header: "détails de paiement",
    fileTooLargeError: "Le fichier doit être de 5 MO ou moins.",
    invalidFileTypeError: "Le fichier doit être de type jpeg ou png",
    couponError: "Erreur de coupon",
    couponApplied: "Coupon appliqué",
    expMonth: "Expiration - Mois",
    expYear: "Expiration - Année (2 chiffres)",
    ccv: "carte de crédit CVV2",
  },
  Receipt: {
    header: "Réserver un examen - Demande soumise",
    confirmEligibilityMessage: `Nous vous remercions d’avoir planifié votre examen avec eProctor Canada. Nous avons reçu votre demande et nous confirmerons votre admissibilité. Vous recevrez un email de confirmation lorsque l’examen aura été confirmé, le moment auquel le paiement ci-dessous sera traité.`,
    downloadEproctorClientMessage: `Veuillez tester le système que vous utiliserez pour prendre votre examen en téléchargeant l’application eProctor et en exécutant les tests de configuration requis. Cela confirmera que votre système est capable d’exécuter l’application et que vous pourrez accéder à votre examen lorsqu’il est programmé.`,
    questionsMessage: `Si vous avez des questions ou si vous souhaitez modifier cette demande, veuillez contacter support@eproctorcanada.com`,
    bookingDetails: "Détails de la réservation",
    orderDetails: "Détails de la commande",
  },
  Dashboard: {
    scheduledAdminLabel: "voir les examens programmés actuels et futurs",
    pendingRequestsAdminlabel:
      "Examiner et approuver les demandes d’examen en attente",
    examsAdminLabel: "voir, ajouter et modifier les examens pour les élèves",
    learnersAdminLabel: "reviser les détails des élèves",
    scheduleExams: "Planifier des examens",
    rescheduleAdminLabel: "reprogrammer et retirer les examens des élèves",
    settingsAdminLabel:
      "voir et modifier les paramètres de cet établissement d’enseignement",
  },
  ScheduledExams: {
    header: "examens programmés",
    welcome: "bienvenue",
    downloadApplication: "télécharger l'application",
    bookExam: "Réservez un examen",
    downloadInstructions: `Veuillez télécharger <1>l'application</1> dans les 30 minutes qui prédéde le début de l'examen. Une fois que le téléchargement 
      est complété, veuillez entrer vos identifiants de connexion de nouveau et selectionner l'examen en cliquant sur le titre. Ceci avertira le 
      surveillant que vous êtes prêt à commencer. Le surveillant vous guidera tout au long du processus d'enregistrement.
      <3>
      VEUILLEZ NOTER: Assurez-vous de télécharger la dernière version de l'application client eProctor ou vous pouvez avoir des difficultés 
      techniques et ne pas avoir accès à toutes les fonctionnalités nécessaires. Si vous avez des questions à propos des numéros de version, 
      veuillez contacter le suport technique.
      </3>`,
  },
  ExamBlock: {
    examCompleted: "Cet examen est terminé",
    bookingApproved: "La réservation a été approuvée.",
    notPresentForExam: "Absence pour l’examen programmé",
    bookingRejected: "Réservation refusée",
    approvalTimeFrameMessage: `Votre examen sera approuvé dans un délai d'un jour ouvrable. Vous pouvez vous attendre à recevoir une notification par courriel après approbation.`,
  },
  PastExams: {
    header: "examens passés",
  },
  ClientDownload: {
    header: "télécharger l'application",
    instructionsStepOne:
      "Sélectionnez et téléchargez l’application pour votre système",
    instructionsStepTwo: `Ouvrez l’application et sélectionnez l’examen programmé à écrire.`,
    instructionsStepThree: `Suivez les instructions à mesure qu’elles sont présentées pour atteindre eProctor Canada. Vos identifiants de connexion pour eProctor Canada et le système du fournisseur d’examens (p. ex., PSKN) sont requis`,
    systemRequirements: "configuration minimale requise",
    networkRequirements: "exigences de réseau",
    uploadSpeed: "vitesse de téléchargement",
    downloadSpeed: "vitesse de téléchargement",
    minUploadSpeed: "minimum de 2.5 Mbits/s",
    minDownloadSpeed: "minimum de 2.5 Mbits/s",
    computerRequirements: "spécifications de l'ordinateur requises",
    webcamWithMicrophone: "Webcam avec microphone (ou microphone séparé)",
    windows: "Windows",
    minWindowsVersion: "Windows 7 ou une version supérieure",
    mac: "Mac",
    minMacVersion: "OS X 10.9 ou une version supérieure",
    downloadMessage: "Votre téléchargement devrait commencer automatiquement.",
    didntWork: "Ça n’a pas marché ? Cliquez <1>ici</> et réessayez",
    applicationDownload: "téléchargement de l'application",
  },
  PendingRequests: {
    receiptEmailSubject: "Reçu d’achat eProctor - Réservation confirmée",
    bookingConfirmedEmail: "Confirmation de la réservation eProctor",
    bookingRejectedEmail: "Demande d’examen eProctor - Rejetée",
    submittedDate: "Date de soumission",
    bookingDate: "date de réservation",
    downloadFile: "télécharger le fichier",
    paymentError: "Erreur de paiement",
  },
  RejectModal: {
    rejectBooking: "Rejeter la réservation",
    rejectReasoning: "raison du rejet",
  },
  RescheduleDates: {
    sendUserNotification:
      "Souhaitez-vous envoyer à l’utilisateur une notification des modifications ?",
  },
  Exam: {
    errorAddingHeader: "Erreur d’ajout d’examen",
    daysAvailable: "jours disponible",
    days: "Jours",
    timesAvailable: "heures disponible",
    capacity: "capacité",
    cost: "coût",
    addExam: "Ajouter un examen",
    notApplicable: "ne s'applique pas",
    enableFileUpload: "Activer le téléchargement de fichiers (certificats)",
    checkinScript: "Règles / texte d'enregistrement du surveillant",
    studentRules: "Règles pour les étudiants",
    uploadDescription: "télécharger la description",
    from: "de",
    to: "à",
    limit: "limite",
  },
  ExamTable: {
    deleteExam: "Supprimer un examen",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer cet examen?",
    confirmCancel: "Êtes-vous sûr de vouloir annuler cette réservation?",
    learningInstitutions: "Établissement(s) d’enseignement",
    added: "ajouté",
    bookingLimit: "Limite de réservation",
  },
  RescheduleExam: {
    header: "Reporter l’examen",
    resheduleEmailSubject: "Réservation eProctor reproctor reprogrammée",
    dateAndTimeSlot: "Créneau horaire et date",
    reschedule: "Reporter",
    deleteBooking: "Supprimer une réservation",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer?",
    showFutureBookings: "Afficher uniquement les réservations futures",
    cancelExam: "Annuler l'examen",
    cancelExamMsg:
      "Are you sure you want to cancel this booking? User will not automatically be refunded when booking is cancelled.",
  },
  Learners: {
    replays: "Replays",
    registeredOn: "enregistrée le",
    replays: "rejouer",
    viewBookings: "View Bookings",
  },
  LearnerBookings: {
    bookingsFor: "Bookings for",
    recordings: "Recordings",
    confirmRequestRecordings: "Request the following recordings?",
    requestDownloadAgain: "Request Failed. Try Again?",
  },
  InstitutionsTable: {
    addInstitution: "Ajouter une institution",
    institution: "Institution",
    addOrEditInstitution: "Ajouter/modifier une institution",
    errorAddingClient: "Erreur d’ajout du client",
    institutionName: "Nom de l’institution",
    pointOfContactName: "point de contact",
    pointOfContactEmail: "email du contact",
    availabilityStatus: "statut de disponibilité",
    pointOfContact: "Point de contact",
    contactEmail: "email du contact",
  },
  BlockedDays: {
    header: "Jours bloqués",
    addBlockedDay: "Ajouter une journée bloquée",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer cette journée bloquée?",
    missingFieldsError: "Veuillez indiquer le nom et la date.",
    everyYearLabel: "La date doit être bloquée chaque année",
    everyYear: "chaque année",
    allDay: "Toute la journée",
    allDayLabel: "Toute la journée?",
    times: "Créneau horaire à bloquer",
    overlapError:
      "Les jours bloqués se chevauchent avec les jours/créneaux horaires bloqués existants pour des examens donnés.",
  },
  ExamSchedule: {
    createRoom: "créer une salle",
  },
  ProctoringSession: {
    expandView: "Vue élargie",
    cycleMaxUsers: "Nombre maximal d’utilisateurs du cycle",
    faceToFaceDisconnected: "L’utilisateur s’est déconnecté du face a face",
    faceToFaceConnected: "Vous êtes maintenant connecté",
    joinedSession: "rejoint la séance ",
    noLearnersInExam: "Personne dans l’examen en ce moment",
    noLearnersInExamMessage:
      "Nous vous ferons savoir quand quelqu’un se joindra à nous.",
    pushExamUrl: "Envoyer l’URL de l’examen",
    url: "URL",
    pushExam: "Envoyer l'examen",
    pushExamAllUsers: "Envoyer l’examen à tous les utilisateurs",
    lostConnection: "Lost Connection",
    reJoinedSession: "Rejoined Session",
  },
  WebCamUser: {
    minimize: "Minimiser",
    maximize: "Maximiser",
    ban: "Interdiction",
    signOut: "Fermeture de session",
    flag: "signaler",
    singleLink: "Lien unique",
    linkAll: "Lier tout",
    banUser: "Bannir l’utilisateur",
    confirmBan: "Est-il acceptable de bannir",
    softBanUser: "Utilisateur de démarrage logiciel",
    confirmSoftBoot: "Est-il acceptable de retiré",
    callLearner: "Appeler l'étudiant",
    userDisconnectedHeader: "Connection Lost",
    userDisconnectedMessage: "Attempting to restablish connection.",
  },
  Checkin: {
    learnerRejoinedHeader: "l'étudiant a rejoint",
    learnerRejoinedMessage: "l'étudiant a rejoint l'enregistrement",
    learnerLeftHeader: "l'étudiant est parti",
    learnerLeftMessage: `l'étudiant a quitté la zone d'enregistrement ou a des problèmes de réseau.`,
    webcamNotReadableHeader: "Webcam non lisible",
    webcamNotReadableMessage: `Impossible d’accéder à la webcam du procteur. Veuillez vous assurer que la webcam n’est pas utilisée par un autre programme.`,
    checkinScript: "Script d’enregistrement",
    noRules: "Aucune règle pour l’examen.",
  },
  Chat: {
    proctor: "(Procteur)",
    typeMessageHere: "Ecrire message ici",
  },
  CheckinQueue: {
    header: "File d’attente d’enregistrement",
    clientVersion: "Version client",
    checkIn: "Enregistrement",
    noRoomsAvailable: "Aucune salle disponible",
    moveUser: "Déplacer l’utilisateur",
    noUsersInRoom: "Aucun utilisateur dans cette salle.",
    deleteRoom: "Supprimer la salle",
  },
  CouponTable: {
    over100Error: "Le coupon ne peut pas dépasser 100 %.",
    addCoupon: "Ajouter un coupon",
    addOrEditCoupon: "Ajouter/modifier un coupon",
    missingFieldsErrorHeader: "Erreur d’ajout d’un coupon",
    couponCode: "code promo",
    couponValue: "valeur du coupon",
    couponValueType: "Type de valeur du coupon",
    valueType: "Type de valeur du coupon",
    selectExams: "Veuillez sélectionner un examen",
    maxUses: "Nombre d’utilisations maximal",
    availabilityStatus: "statut de disponibilité",
    value: "valeur",
    exams: "Examen(s)",
    maxUses: "Utilisations maximales",
    timesUsed: "nombre de fois utilisées",
    deactivateCoupon: "Désactiver le coupon",
    activateCoupon: "Activer le coupon",
    deactivateCouponConfirm: "Êtes-vous sûr de vouloir désactiver ce coupon?",
    activateCouponConfirm: "Êtes-vous sûr de vouloir désactiver ce coupon?",
  },
  JoinExamModal: {
    joinAnExam: "Joindre un examen",
    join: "Joindre",
    leaveExam: "Quitter l’examen",
    joinExam: "Se joindre à l’examen",
  },
  CreateRoom: {
    addRoom: "Ajouter une salle",
    roomTitle: "Titre de la salle",
    dateAndTime: "Date et heure",
    submit: "Soumettre",
  },
  PictureUploader: {
    clickToUpload: "Cliquez ici pour télécharger.",
    fileTooLargeError: "Le fichier doit être de 5 MO ou moins.",
    incorrectFileTypeError: "Le fichier doit être de type JPEG ou PNG",
    text: "cliquez sur la case pour télécharger le plan du visage",
  },
  Emails: {
    emailSubject: "Objet du courriel",
    supportCenter: "Centre de soutien eProctor",
    tollFree: "Sans frais",
    BookingApproval: {
      header: "Réservation confirmée pour {{ examTitle }}",
      message: `Votre examen au {{ bookingDate }} {{ timezone }} a été approuvé. Veuillez porter particulièrement attention aux points suivants :`,
      timezoneWarning: `Assurez-vous que le fuseau horaire de votre ordinateur et de votre compte eProctor correspond a l'endroit ou vous etes. (Par exemple, si vous êtes en Alberta, votre fuseau horaire devrait être l’heure des Rocheuses (HR) - UTC-7.) Vous pouvez vérifier ou mettre à jour ce paramètre dans vos « Paramètres du compte ».`,
      preExamInstructions: `Avant de passer votre examen, effectuez un
            <1>test de vitesse</1>  à l’endroit où vous prévoyez d'écrire pour vous assurer que vos vitesses Internet répondent aux
            <3>
              exigences du système
            </3>
            eProctor Canada.`,
      downloadLatestClient: `Le jour de votre examen, téléchargez la dernière version de
            <1>
              l’application eProctor Canada.
            </1>
            Si vous n’avez pas la dernière version de l’application, vous ne pourrez pas accéder à l’examen.`,
      checkinTime: `30 minutes avant votre examen, connectez-vous à l'application eProctor pour terminer le processus de validation et d'enregistrement.`,
      lateWarning: `Si vous avez plus de 15 minutes de retard, vous ne serez pas autorisé à passer l’examen.`,
      examDuration: "Durée de l’examen: {{duration}} heures",
      examRules: "Règles spécifiques à l’examen: {{ examRules }}",
    },
    BookingRejected: {
      header: "Demande de réservation eProctor - Rejetée",
      invoiceNumber: `invoice #: {{invoiceNumber}}`,
      message: `Demande de réservation pour {{ name }} pour prendre {{ examTitle }}
             a été rejeté`,
      reason: "Raison fournie: {{ reason }}",
      supportContact: `Pour obtenir de plus amples renseignements, veuillez contacter le Centre de soutien eProctor par email à support@eproctorcanada.com ou par téléphone au 1-844-595-2561.`,
    },
    BookingRequest: {
      header: "Demande de réservation pour {{ examTitle }} En attente",
      bookingCode: "Code de réservation: {{ code }}",
      approvalTimeFrame:
        "Veuillez noter que la réservation d’un examen peut prendre jusqu’à 2 jours ouvrables.",
      postApprovalInstructions: `Une fois votre examen approuvé, vous recevrez une notification par email avec votre confirmation de paiement.  Si vous avez des questions, veuillez contacter le Centre de soutien eProctor par email à support@eproctorcanada.com ou par téléphone au 1-844-595-2561.`,
    },
    BookingReschedule: {
      header: "Reprogrammation de réservation confirmée pour {{examTitle}}",
      message: `{{ name }}, Votre examen précédent a été reporté à
            {{ bookingDate }} {{ timezone }}.  Veuillez vous connecter à votre compte eProctor 30 minutes avant l’examen pour terminer le processus de validation et d’enregistrement.`,
      duration: "Durée de l’examen: {{duration}} heures",
      examRules: "Règles spécifiques à l’examen: {{ examRules }}",
      defaultRules:
        "En plus de ce qui précède, veuillez préparer ce qui suit :",
      ruleOne: `L’étudiant doit rester dans la salle d’examen jusqu’à la fin de l’examen. Les pauses toilettes ne sont pas permises.`,
      ruleTwo:
        "Assurez-vous que la zone d’examen est calme et qu’il n’y a pas de distractions.",
      ruleThree: `Aucun téléphone cellulaire, y compris les montres intelligentes et autres appareils électroniques, n’est autorisé dans la zone au moment de l’examen.`,
      ruleFour: "Carte d’identité avec photo émise par le gouvernement",
    },
    EmailReminder: {
      header: "Rappel d’examen eProctor - {{ examTitle }}",
      message: `Cette note a pour but de vous rappeler votre examen prévu avec eProctor.`,
      examDate: "{{ examTitle }} on {{ date }}",
      checkinInstructions: `vous pouvez commencer le processus d’enregistrement 30 minutes avant l’heure de l’examen.  Les éléments suivants seront requis lors de l’enregistrement:`,
      checkinRequirementOne: "Carte d’identité avec photo",
      loginCredentialsMessage:
        "Identifiants de connexion (nom d’utilisateur et mot de passe) pour votre système d’examen hôte (PSKN)",
    },
    PurchaseReceipt: {
      header: "eProctor - Merci pour votre commande.",
      invoiceNumber: "facture #",
      businessNumber: "Business #808349930RT0001",
    },
    UserRegistration: {
      welcome: "Bienvenue sur eProctor",
      welcomeMessage: `Bienvenue à eProctor, le meilleur fournisseur d’examen en ligne au Canada. Pour planifier votre examen, veuillez 
        consulter le site http://eproctorcanada.com.  Pour plus d'information ou pour obtenir du soutien technique, veuillez contacter 
        support@eproctorcanada.com ou composer le 1-844-595-2561.`,
    },
  },
};

export default fr;
