import {getCoupons, updateAvailability, updateExisting, saveNewCoupon, getCouponByName} from '../lib/couponServices';

const initState = {
    coupon: '',
    coupons: [],
    showAvailModal: false,
    showEditCoupon: false,
    couponToChange: {},
    couponName: '',
    couponValue: '',
    couponValueType: '',
    couponExams: [],
    isUpdating: false,
    couponAvailability: false,
    couponMaxUses: 0,
    couponToApply: {}
};

const LOAD_COUPONS = 'LOAD_COUPONS';
const SHOW_AVAIL_MODAL = 'SHOW_AVAIL_MODAL';
const SHOW_EDIT_COUPON = 'SHOW_EDIT_COUPON';
const UPDATE_COUPON_STATUS = 'UPDATE_COUPON_STATUS';
const SET_IS_UPDATING = 'SET_IS_UPDATING';
const UPDATE_COUPON_NAME = 'UPDATE_COUPON_NAME';
const UPDATE_COUPON_VALUE = 'UPDATE_COUPON_VALUE';
const UPDATE_COUPON_AVAILABILITY = 'UPDATE_COUPON_AVAILABILITY';
const UPDATE_COUPON_VALUE_TYPE = 'UPDATE_COUPON_VALUE_TYPE';
const UPDATE_COUPON_EXAMS = 'UPDATE_COUPON_EXAMS';
const UPDATE_MAX_USES = 'UPDATE_MAX_USES';
const UPDATE_COUPON_TO_APPLY = 'UPDATE_COUPON_TO_APPLY';

export const loadCoupons = (coupons) => ({type:LOAD_COUPONS,payload:coupons});
export const updateAvailModal = (val) => ({type:SHOW_AVAIL_MODAL});
export const updateEditCoupon = (val) => ({type:SHOW_EDIT_COUPON});
export const updateCouponAvailability = (val) => ({type:UPDATE_COUPON_AVAILABILITY,payload:val});
export const updateCouponName = (val) => ({type:UPDATE_COUPON_NAME,payload:val});
export const updateCouponValue = (val) => ({type:UPDATE_COUPON_VALUE,payload:val});
export const updateCouponValueType = (val) => ({type:UPDATE_COUPON_VALUE_TYPE,payload:val});
export const updateCouponExams = (val) => ({type:UPDATE_COUPON_EXAMS,payload:val});
export const updateCouponStatus = (val) => ({type:UPDATE_COUPON_STATUS,payload:val});
export const updateMaxUses = (payload) => ({type:UPDATE_MAX_USES, payload});
export const setIsUpdating = (val) => ({type:SET_IS_UPDATING,payload:val});
export const updateCouponToApply = (payload) => ({type: UPDATE_COUPON_TO_APPLY, payload});

export const fetchCoupons = () => {
    return (dispatch) => {
        getCoupons()
            .then(coupons => dispatch(loadCoupons(coupons)));
    }
};

export const fetchCouponByName = (name) => {
    return (dispatch) => {
        getCouponByName(name)
            .then(coupon => dispatch(updateCouponToApply(coupon)));
    }
};

export const changeCouponStatus = (coupon) => {
    return (dispatch) => {
        updateAvailability(coupon)
            .then(coupon => dispatch(fetchCoupons()));
    }
};

export const updateExistingCoupon = (coupon) => {
    return (dispatch) => {
        updateExisting(coupon)
            .then(coupon => dispatch(fetchCoupons()));
    }
};

export const saveCoupon = (coupon) => {
    return (dispatch) => {
        saveNewCoupon(coupon)
            .then(coupon => dispatch(fetchCoupons()))
    }
};

export default (state = initState,action) => {
    switch(action.type){
        case UPDATE_COUPON_NAME:
            return {...state,couponName: action.payload};
        case UPDATE_COUPON_VALUE:
            return {...state,couponValue: action.payload};
        case UPDATE_COUPON_VALUE_TYPE:
            return {...state,couponValueType: action.payload};
        case UPDATE_COUPON_AVAILABILITY:
            return {...state,couponAvailability: action.payload};
        case UPDATE_COUPON_EXAMS:
            return {...state,couponExams: action.payload};
        case LOAD_COUPONS:
            return {...state,coupons: action.payload};
        case UPDATE_COUPON_TO_APPLY:
            return {...state, couponToApply: action.payload};
        case SHOW_AVAIL_MODAL:
            return {...state,showAvailModal: !state.showAvailModal};
        case SHOW_EDIT_COUPON:
            return {...state,showEditCoupon: !state.showEditCoupon};
        case UPDATE_COUPON_STATUS:
            return {...state,couponToChange:action.payload};
        case SET_IS_UPDATING:
            return {...state,isUpdating: action.payload};
        case UPDATE_MAX_USES:
            return {...state, couponMaxUses: action.payload};    
        default:
            return state;
    }
}
