const initState = {
    comments:[],
    chatBoxes: [],
    newMessage: false,
    typing:false,
};

const COMMENT_ADD = 'COMMENT_ADD';
const UPDATE_NEW_MESSAGE = 'UPDATE_NEW_MESSAGE';
const UPDATE_TYPING = 'UPDATE_TYPING';
const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
const CLEAR_COMMENTS_FOR_USER = 'CLEAR_COMMENTS_FOR_USER';
const ADD_CHAT_BOX = 'ADD_CHAT_BOX';
const UPDATE_CHAT_BOXES = 'UPDATE_CHAT_BOXES';
const CLEAR_CHAT_BOXES = 'CLEAR_CHAT_BOXES';

export const addComment = (val) => ({type:COMMENT_ADD,payload:val});
export const updateNewMessage = (val) => ({type:UPDATE_NEW_MESSAGE,payload:val});
export const updateTyping = (val) => ({type:UPDATE_TYPING, payload:val});
export const clearComments = () => ({type:CLEAR_COMMENTS}); 
export const clearCommentsForUser = (val) => ({type:CLEAR_COMMENTS_FOR_USER, payload:val});
export const addChatBox = (payload) => ({type: ADD_CHAT_BOX, payload});
export const updateChatBoxes = (payload) => ({type: UPDATE_CHAT_BOXES, payload});
export const clearChatBoxes = () => ({type: CLEAR_CHAT_BOXES});

export default (state = initState, action) => {
    switch(action.type){
        case COMMENT_ADD:
            const chatBoxes = state.chatBoxes.map(chatBox => {
                if (chatBox.user_id === action.payload.user_id) {
                    chatBox.comments.push({
                        room: action.payload.room,
                        text: action.payload.text,
                        timestamp: action.payload.timestamp,
                        author: action.payload.author
                    });
                }

                return chatBox;
            });
            
            return {...state, chatBoxes};
        case UPDATE_NEW_MESSAGE:
            return {...state,newMessage:action.payload};
        case UPDATE_TYPING:
            return {...state,typing:action.payload};
        case CLEAR_COMMENTS:
            return {...state,comments:[]};
        case CLEAR_COMMENTS_FOR_USER:
            return {...state,comments: state.comments.filter((data, i) => data.room !== action.payload)};
        case ADD_CHAT_BOX:
            return {...state, chatBoxes: [...state.chatBoxes, action.payload]};
        case UPDATE_CHAT_BOXES:
            return {...state, chatBoxes: action.payload};
        case CLEAR_CHAT_BOXES:
            return {...state, chatBoxes: []};    
        default:
            return state;
    }
}