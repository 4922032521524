import axios from "axios/index";

/**
 * Creates a proctored Session.
 * @param session
 * @returns {AxiosPromise}
 */
export const createSession = (session) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/sessions', {
        session_guid:session.session_guid,
        user_id:session.user_id,
        start_time: session.start_time,
        end_time: session.end_time,
        remote_ip: session.remote_ip,
        remote_system: session.remote_system,
        exam_id: session.exam_id
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};

/**
 * List all Learner Sessions.
 * @returns {Promise<AxiosResponse | void>}
 */
export const listLearnerSessions = () => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/sessions/learners').then(res => res.data)
        .catch(error => console.log(error));
};

/**
 * List all Sessions for a single Learner
 * @param id
 * @returns {Promise.<TResult>}
 */
export const listLearnerSessionById = (id) => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/sessions/learner/' + id).then(res => res.data)
        .catch(error => console.log(error));
};

/**
 * Create a Session Event.
 * @param event
 * @returns {AxiosPromise}
 */
export const createEvent = (event) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/events', {
        session_id:event.session_id,
        timestamp:event.timestamp,
        event:event.event,
        proctor_id:event.proctor_id
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    });
};

export const getSessionById = (session_guid) => {
    return axios.get(process.env.REACT_APP_API_ADDRESS+'/api/sessions/' + session_guid).then(res => res.data).catch(error => console.log(error));
};