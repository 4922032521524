import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Segment, Grid } from "semantic-ui-react";
import ProctoringSession from "./ProctoringSession";
import Exam from "./Exam";
import Dashboard from "./Dashboard";
import ClientDownload from "./ClientDownload";
import BookExam from "./BookExam";
import StudentDashboard from "./StudentDashboard";
import Logout from "../components/Logout";
import Notification from "./../components/Notification";
import AccountDetails from "./AccountDetails";
import ExamSchedule from "./ExamSchedule";
import PendingRequests from "./PendingRequests";
import Learners from "./Learners";
import LearnerBookings from "./LearnerBookings";
import RescheduleExam from "./RescheduleExam";
import Settings from "./Settings";
import Replay from "./Replay";
import Checkin from "./Checkin";
import {
  addNotification,
  dismissNotification,
} from "./../reducers/notification";
import "../App.css";
import io from "socket.io-client";
import FixedMenu from "./../components/FixedMenu";
import { withTranslation } from "react-i18next";

const _ = require("underscore");

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = io(process.env.REACT_APP_WEBSOCKET_DB, {
      query: `bearer=${this.props.kc.token}`,
    });
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state = {
      active: "dashboard",
    };
  }
  componentDidMount() {
    this.socket.on("learnerCheckIn", (message) => {
      const path = window.location.href.split("/");
      const user_id = path.slice(-1)[0];
      const room_id = path.slice(-2)[0];
      const currentPath = path.slice(-3)[0];

      const link = `/checkIn/${message.room}/${message.user_id}`;
      if (link !== `/${currentPath}/${room_id}/${user_id}`) {
        if (
          this.props.kc.tokenParsed.realm_access.roles.indexOf("proctor") > -1
        ) {
          this.props.addNotification({
            message: `${message.username} is ready to check in.`,
            title: "Check In",
            link: link,
          });
        }
      }
    });

    this.socket.on("removeCheckInNotification", (message) => {
      this.props.notification.notifications.forEach((data) => {
        if (data.message === `${message} is ready to check in.`) {
          this.props.dismissNotification(data.id);
        }
      });
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !("notification" in nextProps);
  }
  handleMenuClick(active) {
    this.setState({ active });
  }
  render() {
    let isProctor =
      this.props.kc.tokenParsed.realm_access.roles.indexOf("proctor") > -1;

    let RBACRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          //if the logged in user has one of the roles required to view this route.
          let intersect = _.intersection(
            rest.allowedRoles,
            this.props.kc.realmAccess.roles
          );

          if (intersect.length > 0) {
            if (props.location.pathname === "/exams/book/") {
              let url = `${
                process.env.REACT_APP_API_ADDRESS
              }/api/images/profile/${
                this.props.kc.tokenParsed.sub
              }_user_profile_img.png?token=${
                this.props.kc.token
              }&cb=${Math.round(new Date().getTime() / 1000)})`;
              var http = new XMLHttpRequest();
              http.open("HEAD", url, false);
              http.send();

              if (http.status === 404) {
                this.props.addNotification({
                  message: "Please Add a Personal Headshot before continuing.",
                  type: "error",
                });
                return <Redirect to="/account-details" />;
              }
            }

            return <Component {...props} socket={this.socket} />;
          } else {
            this.props.addNotification({
              message: "Access Denied",
              type: "error",
              timeout: true,
            });
            return <Redirect to="/" />;
          }
        }}
      />
    );
    return (
      <Router>
        <div>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0em" }}>
              <FixedMenu
                name={this.props.kc.tokenParsed.name}
                logout={this.props.kc.logout}
                setActiveMenuItem={this.handleMenuClick}
                active={this.state.active}
                lang={this.state.lang}
              />
              <Segment
                inverted
                textAlign="center"
                style={{
                  minHeight: 51,
                  padding: "1em 0em",
                  background: "white",
                }}
                vertical
              />
            </Grid.Row>
            <div className="grad-bar"></div>
            <Grid.Row>
              <Notification />
            </Grid.Row>
          </Grid>
          <Switch>
            <RBACRoute
              allowedRoles={["can-view-proctoring"]}
              path="/proctoring/:room_id"
              component={ProctoringSession}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/exam"
              component={Exam}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/scheduled-exams"
              component={ExamSchedule}
            />
            <RBACRoute
              allowedRoles={["learner"]}
              path="/exams/book"
              component={BookExam}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/pending-requests"
              component={PendingRequests}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/learner-details/:user_id"
              component={LearnerBookings}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/learner-details"
              component={Learners}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/schedule"
              component={RescheduleExam}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/settings"
              component={Settings}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/replay/:session_id"
              component={Replay}
            />
            <RBACRoute
              allowedRoles={["proctor", "institution"]}
              path="/checkIn/:room_id/:learner_id"
              component={Checkin}
            />
            <Route path="/download" component={ClientDownload} />
            <Route path="/account-details" component={AccountDetails} />
            <Route
              path="/logout"
              component={() => (
                <Logout loadingText={this.props.t("App.loggingOut")} />
              )}
            />
            {isProctor ? (
              <Route path="/" component={Dashboard} />
            ) : (
              <Route path="/" component={StudentDashboard} />
            )}
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    kc: state.keycloak,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    addNotification,
    dismissNotification,
  })(App)
);
