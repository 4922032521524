import React, {Component} from "react";
import {connect} from "react-redux";
import {Dropdown, Container, Checkbox, Grid} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  fetchApprovedBookings,
  updateExamToDelete,
  deleteExamCreator,
  updateBookingToEdit,
  updateDeleteModal,
  updateShowOnlyFutureBookings,
  rescheduleBooking,
  fetchBookings,
  cancelBooking,
} from "./../reducers/booking";
import MainMenu from "./../components/MainMenu";
import "react-datepicker/dist/react-datepicker.css";
import BookingReschedule from "./../components/emails/BookingReschedule";
import RescheduleExamModal from "../components/RescheduleExamModal";
import DeleteBookingModal from "../components/DeleteBookingModal";
import parseTimezone from "../lib/helperServices";
import {sendEmail} from "./../lib/emailService";
import {withTranslation} from "react-i18next";
import moment from "moment-timezone";
import i18n from "./../i18n";
import StatusDropdown from "../components/Bookings/StatusDropdown";
import {addNotification} from "./../reducers/notification";
import ConfirmationModal from "../components/ConfirmationModal";

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayError: false,
      openDelete: false,
      error: "",
      showRescheduleModal: false,
      statusFilter: "approved",
      showConfirmationModal: false,
      selectedBooking: null,
      showOnlyFuture: true,
      modalText: {
        header: "",
        msg: "",
      },
    };
    this.submitDelete = this.submitDelete.bind(this);
    this.handleUpdateShowFutureBookings =
      this.handleUpdateShowFutureBookings.bind(this);
    this.handleUpdateBooking = this.handleUpdateBooking.bind(this);
    this.handleUpdateStatusFilter = this.handleUpdateStatusFilter.bind(this);
    this.submitCancel = this.submitCancel.bind(this);
  }
  componentDidMount() {
    this.props.fetchBookings("approved", true);
  }
  handleShowResheduleModal(id) {
    const selectedBooking = this.props.bookings[this.state.statusFilter].find(
      (elem) => elem.id === id
    );
    this.setState({selectedBooking, showRescheduleModal: true});
  }

  handleUpdateBooking(data) {
    const {examDate, booking, sendRescheduleEmail} = data;
    this.props
      .rescheduleBooking(
        {
          examDate,
          id: booking.id,
          user_id: booking.user.user_id,
        },
        this.state.statusFilter,
        this.state.showOnlyFuture
      )
      .then((response) => {
        const updatedBooking = response.booking;
        const userData = response.userData;

        let timezone = parseTimezone(userData.attributes.timezone[0]);

        if (sendRescheduleEmail) {
          let userLanguage = "";

          if (userData.attributes && userData.attributes.locale) {
            userLanguage = i18n.getFixedT(userData.attributes.locale[0]);
          } else {
            userLanguage = i18n.getFixedT("en");
          }

          sendEmail({
            to: userData.email,
            subject: userLanguage("RescheduleExam.resheduleEmailSubject"),
            component: (
              <BookingReschedule
                t={userLanguage}
                name={`${userData.firstName} ${userData.lastName}`}
                examTitle={booking.exam.title}
                timezone={userData.attributes.timezone[0]}
                bookingDate={moment(updatedBooking.examDate)
                  .tz(timezone)
                  .format("MMMM Do YYYY - h:mm A")}
                duration={booking.exam.duration}
                examRules={
                  booking.exam.student_rules
                    ? booking.exam.student_rules
                    : "N/A"
                }
              />
            ),
          });
        }

        this.props.addNotification({
          title: this.props.t("general.success"),
          message:
            "Exam rescheduled successfully for: " +
            moment(updatedBooking.examDate).format("MMMM Do YYYY - h:mm A"),
          type: "success",
          timeout: true,
        });
      })
      .catch((e) => {
        console.log(e);

        this.props.addNotification({
          title: this.props.t("general.error"),
          message: "Rescheduling Error.",
          type: "error",
          timeout: true,
        });
      });

    this.setState({showRescheduleModal: false});
  }
  handleDeleteExam(id) {
    const selectedBooking = this.props.bookings[this.state.statusFilter].find(
      (elem) => elem.id === id
    );
    this.setState({
      selectedBooking,
      showConfirmationModal: true,
      modalText: {
        header: this.props.t("ExamTable.deleteExam"),
        msg: this.props.t("ExamTable.confirmDelete"),
      },
      submit: this.submitDelete,
    });
  }
  submitDelete() {
    this.props.deleteExamCreator(
      this.state.selectedBooking,
      this.state.statusFilter,
      this.state.showOnlyFuture
    );
    this.setState({showConfirmationModal: false});
    this.props.fetchBookings(
      this.state.statusFilter,
      this.state.showOnlyFuture
    );
  }
  submitCancel() {
    this.props.cancelBooking(
      this.state.selectedBooking,
      this.state.statusFilter,
      this.state.showOnlyFuture
    );
    this.setState({showConfirmationModal: false});
    this.props.fetchBookings(
      this.state.statusFilter,
      this.state.showOnlyFuture
    );
  }
  handleUpdateShowFutureBookings() {
    this.setState({showOnlyFuture: !this.state.showOnlyFuture}, () => {
      this.props.fetchBookings(
        this.state.statusFilter,
        this.state.showOnlyFuture
      );
    });
  }
  l;
  handleUpdateStatusFilter(statusFilter) {
    this.props.fetchBookings(statusFilter, this.state.showOnlyFuture);
    this.setState({statusFilter});
  }
  defaultFilterMethod = (filter, row, column) => {
    if (column.id === "submittedDate") {
      let startDate = moment(this.state.startDate).format();
      let endDate = moment(this.state.endDate).format();
      const id = filter.pivotId || filter.id;
      let date = moment(row[id]).format();
      return moment(startDate).isBefore(date) && moment(endDate).isAfter(date);
    } else {
      const id = filter.pivotId || filter.id;
      return row[id] !== undefined ? row[id] === filter.value : true;
    }
  };
  handleShowCancelModal(id) {
    const selectedBooking = this.props.bookings[this.state.statusFilter].find(
      (elem) => elem.id === id
    );
    this.setState({
      selectedBooking,
      showConfirmationModal: true,
      modalText: {
        header: this.props.t("RescheduleExam.cancelExam"),
        msg: this.props.t("RescheduleExam.cancelExamMsg"),
      },
      submit: this.submitCancel,
    });
  }

  columns = [
    {
      Header: this.props.t("general.username"),
      accessor: "user.username",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.firstName"),
      accessor: "user.first_name",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.lastName"),
      accessor: "user.last_name",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("RescheduleExam.dateAndTimeSlot"),
      accessor: "booking_date",
      Cell: (row) =>
        moment(row.original.booking_date).format("MMMM Do YYYY, h:mm a"),
      id: "bookingDate",
      sortable: true,
      sort: "desc",
      filterable: false,
    },
    {
      Header: this.props.t("general.examName"),
      accessor: "exam.title",
      id: "exam",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.status"),
      accessor: "approved",
      id: "status",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.actions"),
      Cell: (row) => {
        return (
          <Dropdown
            button
            text={this.props.t("general.actions")}
            style={{overflow: "inherit !important"}}>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.handleShowResheduleModal(row.original.id);
                }}>
                {this.props.t("RescheduleExam.reschedule")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.handleDeleteExam(row.original.id)}>
                {this.props.t("general.delete")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.handleShowCancelModal(row.original.id)}>
                {this.props.t("general.cancel")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      style: {
        overflow: "inherit",
      },
      sortable: false,
      filterable: false,
    },
  ];

  render() {
    const bookings = this.props.bookings[this.state.statusFilter] || [];
    return (
      <Container>
        <MainMenu />
        <RescheduleExamModal
          open={this.state.showRescheduleModal}
          booking={this.state.selectedBooking}
          updateBooking={this.handleUpdateBooking}
          close={() => this.setState({showRescheduleModal: false})}
        />
        <ConfirmationModal
          open={this.state.showConfirmationModal}
          close={() => this.setState({showConfirmationModal: false})}
          submit={this.state.submit}
          headerText={this.state.modalText.header}
          confirmationMsg={this.state.modalText.msg}
        />
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <StatusDropdown
                  status={this.state.status}
                  updateStatus={this.handleUpdateStatusFilter}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  onClick={this.handleUpdateShowFutureBookings}
                  label={this.props.t("RescheduleExam.showFutureBookings")}
                  checked={this.state.showOnlyFuture}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ReactTable
                  ref="schedule"
                  columns={this.columns}
                  data={bookings}
                  filterable
                  defaultPageSize={10}
                  className="-striped -highlight"
                  defaultFilterMethod={this.defaultFilterMethod}
                  minRows={0}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      bookings: state.bookings,
    }),
    {
      addNotification,
      fetchBookings,
      fetchApprovedBookings,
      updateExamToDelete,
      deleteExamCreator,
      updateBookingToEdit,
      updateDeleteModal,
      updateShowOnlyFutureBookings,
      rescheduleBooking,
      cancelBooking,
    }
  )(Schedule)
);
