import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Header, Grid, Divider } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import parseTimezone from "../../lib/helperServices";
import { fetchPastBookings } from "../../reducers/booking";
import ExamBlock from "./ExamBlock";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";

class PastExams extends Component {
  goToBookExams = () => {
    this.props.history.push(`./exams/book/`);
  };

  componentDidMount() {
    this.props.fetchPastBookings(this.props.kc.tokenParsed.sub);
  }

  /**
   * Returns a 'table' of scheduled exams. As well as the modal that transfers to
   * booking of an exam.
   * @returns {*}
   */
  render() {
    let timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    let timezone_array = this.props.kc.tokenParsed.timezone.split("");
    const timeFormat =
      localStorage.getItem("lang") === "en"
        ? "MMMM Do YYYY - h:mm A"
        : "MMMM Do YYYY - HH:mm";
    return (
      <div>
        <Container style={{ marginTop: "2em" }}>
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Divider className="home-divider" />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={4}>
                  <Header textAlign="center" as="h1">
                    {this.props.t("PastExams.header")}
                  </Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Divider className="home-divider" />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid>
              <Grid.Row>
                {this.props.bookings.map((data, i) => {
                  if (data.user_id === this.props.kc.tokenParsed.sub) {
                    return (
                      <Grid.Column key={`${i}_past_exam`} mobile={16} tablet={10} computer={5}>
                        <ExamBlock
                          timezone={`${timezone_array[0]}${timezone_array[2]}`}
                          past={true}
                          date={moment(data.booking_date)
                            .tz(timezone)
                            .format(timeFormat)}
                          status={data.approved}
                          title={
                            data.exam.title !== undefined ? data.exam.title : ""
                          }
                        />
                      </Grid.Column>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      (state) => ({
        bookings: state.bookings.pastBookings,
        kc: state.keycloak,
      }),
      { fetchPastBookings }
    )(PastExams)
  )
);
