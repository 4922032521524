import {updateStatus, getClients, updateExisting, saveNewClient} from '../lib/clientService';

const initState = {
    client: '',
    clients: [],
    showStatusModal: false,
    showEditModal: false,
    showAvailModal: false,
    showEditCoupon: false,
    clientToChange: {},
    clientName: '',
    clientContact: '',
    clientEmail: '',
    isUpdating: false,
    online: false
};

const UPDATE_CLIENT_NAME = 'UPDATE_CLIENT_NAME';
const UPDATE_CLIENT_CONTACT = 'UPDATE_CLIENT_CONTACT';
const UPDATE_CLIENT_EMAIL = 'UPDATE_CLIENT_EMAIL';
const UPDATE_CLIENT = 'UPDATE_CLIENT';
const LOAD_CLIENTS = 'LOAD_CLIENTS';
const SHOW_STATUS_MODAL = 'SHOW_DELETE_MODAL';
const SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL';
const SHOW_AVAIL_MODAL = 'SHOW_AVAIL_MODAL';
const SHOW_EDIT_COUPON = 'SHOW_EDIT_COUPON';
const UPDATE_CLIENT_STATUS = 'UPDATE_CLIENT_STATUS';
const SET_IS_UPDATING = 'SET_IS_UPDATING';
const UPDATE_CLIENT_AVAILABILITY = 'UPDATE_CLIENT_AVAILABILITY';

export const updateClientName = (val) => ({type:UPDATE_CLIENT_NAME,payload:val});
export const updateClientContact = (val) => ({type:UPDATE_CLIENT_CONTACT,payload:val});
export const updateClientEmail = (val) => ({type:UPDATE_CLIENT_EMAIL,payload:val});
export const loadClients = (clients) => ({type:LOAD_CLIENTS,payload:clients});
export const updateClient = (client_id) => ({type:UPDATE_CLIENT,payload:client_id});
export const updateStatusModal = (val) => ({type:SHOW_STATUS_MODAL});
export const updateEditModal = (val) => ({type:SHOW_EDIT_MODAL});
export const updateAvailModal = (val) => ({type:SHOW_AVAIL_MODAL});
export const updateEditCoupon = (val) => ({type:SHOW_EDIT_COUPON});
export const updateClientStatus = (val) => ({type:UPDATE_CLIENT_STATUS,payload:val});
export const updateClientAvailability = (val) => ({type:UPDATE_CLIENT_AVAILABILITY,payload:val});
export const setIsUpdating = (val) => ({type:SET_IS_UPDATING,payload:val});

export const fetchClients = () => {
    return (dispatch) => {
        getClients()
            .then(clients => dispatch(loadClients(clients)));
    }
};

export const changeClientStatus = (client) => {
    return (dispatch) => {
        updateStatus(client)
            .then(client => dispatch(fetchClients()));
    }
};

export const updateExistingClient = (client) => {
    return (dispatch) => {
        updateExisting(client)
            .then(client => dispatch(fetchClients()));
    }
};

export const saveClient = (client) => {
    return (dispatch) => {
        saveNewClient(client)
            .then(client => dispatch(fetchClients()));
    }
};

export default (state = initState,action) => {
    switch(action.type){
        case UPDATE_CLIENT_NAME:
            return {...state,clientName: action.payload};
        case UPDATE_CLIENT_CONTACT:
            return {...state,clientContact: action.payload};
        case UPDATE_CLIENT_EMAIL:
            return {...state,clientEmail: action.payload};
        case LOAD_CLIENTS:
            return {...state,clients: action.payload};
        case UPDATE_CLIENT:
            return {...state,client:action.payload};
        case SHOW_STATUS_MODAL:
            return {...state,showStatusModal: !state.showStatusModal};
        case SHOW_EDIT_MODAL:
            return {...state,showEditModal: !state.showEditModal};
        case SHOW_AVAIL_MODAL:
            return {...state,showAvailModal: !state.showAvailModal};
        case SHOW_EDIT_MODAL:
            return {...state,showEditCoupon: !state.showEditCoupon};
        case UPDATE_CLIENT_STATUS:
            return {...state,clientToChange:action.payload};
        case UPDATE_CLIENT_AVAILABILITY:
            return {...state,online:action.payload};
        case SET_IS_UPDATING:
            return {...state,isUpdating: action.payload};
        default:
            return state;
    }
}
