import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Button,
  Grid,
  Segment,
  Message,
  Form,
  Input,
  Icon,
  Progress,
} from "semantic-ui-react";
import { fetchCoupons, fetchCouponByName } from "../../reducers/coupons";
import Dropzone from "react-dropzone";
import ExamDetails from "./ExamDetails";
import { withTranslation } from "react-i18next";
import { getCouponByName } from "./../../lib/couponServices";

class PaymentDetails extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      pan: this.props.formData.pan || "",
      expMonth: this.props.formData.expMonth || "",
      expYear: this.props.formData.expYear || "",
      cvv: this.props.formData.cvv || "",
      displayError: this.props.formData.error !== undefined,
      errorMessage: this.props.formData.error || "",
      upload: null,
      uploadPercentage: 0,
      coupon: this.props.formData.coupon || "",
      displayCouponError: false,
      couponError: "",
      examPrice: 0,
      couponId: null,
      displayCouponApplied: false,
      couponApplied: "Coupon has been applied successfully.",
      couponFree: false,
    };
  }
  componentWillMount() {
    //this.props.fetchCoupons();
  }
  componentDidMount() {
    let formData = this.props.formData;
    let exam = formData.exam;
    if (!exam.requires_payment && !exam.file_upload_required) {
      this.props.onForward({
        pan: "N/A",
        expMonth: "N/A",
        expYear: "",
        cvv: "",
      });
    }
    this.setState({
      examPrice: exam.cost,
    });
  }
  resetCoupon = (event, data) => {
    let formData = this.props.formData;
    let exam = formData.exam;
    this.setState({
      examPrice: exam.cost,
      coupon: "",
      couponId: 0,
      displayCouponError: false,
      displayCouponApplied: false,
    });
  };
  applyCoupon = async (event, data) => {
    event.preventDefault();
    try {
      let formData = this.props.formData;
      let exam = formData.exam;
      const coupon = await getCouponByName(this.state.coupon, exam.id);
      if (coupon) {
        this.setState({
          couponId: coupon.id,
          coupon: coupon.coupon_name,
        });
        if (coupon.coupon_value_type === "%") {
          let newCostPer = exam.cost - (exam.cost * coupon.coupon_value) / 100;
          if (newCostPer <= 0) {
            this.setState({
              examPrice: 0,
              displayCouponApplied: true,
              pan: "N/A",
              expMonth: "N/A",
              expYear: "",
              cvv: "",
              couponFree: true,
            });
          } else {
            this.setState({
              examPrice: newCostPer,
              displayCouponApplied: true,
            });
          }
        } else {
          let newCostDollar = exam.cost - coupon.coupon_value;
          if (newCostDollar <= 0) {
            this.setState({
              examPrice: 0,
              displayCouponApplied: true,
              pan: "N/A",
              expMonth: "N/A",
              expYear: "",
              cvv: "",
              couponFree: true,
            });
          } else {
            this.setState({
              examPrice: newCostDollar,
              displayCouponApplied: true,
            });
          }
        }
      }
    } catch (e) {
      this.setState({
        displayCouponError: true,
        couponError: e,
      });
    }
  };
  onSubmit = (event, data) => {
    event.preventDefault();
    this.props.onForward({
      pan: this.state.pan || "N/A",
      expMonth: this.state.expMonth || "N/A",
      expYear: this.state.expYear,
      cvv: this.state.cvv,
      upload: this.state.upload,
      coupon: this.state.coupon,
      examPrice: this.state.examPrice,
      couponId: this.state.couponId,
      couponFree: this.state.couponFree,
    });
  };

  handleCouponChange = (event, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };
  handleInputChange = (event, data) => {
    if (isNaN(data.value)) {
      return;
    }
    let tooLong = false;
    switch (data.name) {
      case "pan":
        if (data.value.length > 16) {
          tooLong = true;
        }
        break;
      case "expMonth":
        if (data.value.length > 2) {
          tooLong = true;
        }
        break;
      case "expYear":
        if (data.value.length > 2) {
          tooLong = true;
        }
        break;
      case "cvv":
        if (data.value.length > 3) {
          tooLong = true;
        }
        break;
      default:
        break;
    }
    if (!tooLong) {
      this.setState({
        [data.name]: data.value,
      });
    }
  };
  validateForm() {
    let validated = false;

    if (
      this.props.formData.exam.requires_payment &&
      this.state.pan &&
      this.state.pan.length === 16 &&
      this.state.expMonth &&
      this.state.expMonth.length === 2 &&
      this.state.cvv &&
      this.state.cvv.length === 3
    ) {
      validated = true;
    }

    //if requires payment and validation failed then return, otherwise check if file uploads required
    if (this.props.formData.exam.requires_payment && !validated) {
      return false;
    }

    if (this.props.formData.exam.file_upload_required) {
      return this.state.upload;
    }

    return validated;
  }
  onDrop = (files, rejected) => {
    if (files[0]) {
      this.setState({ upload: files[0], uploadPercentage: 100 });
    } else {
      this.setState({
        errorMessage:
          rejected[0].size > 2097152
            ? this.props.t("PaymentDetails.fileTooLargeError")
            : this.props.t("PaymentDetails.invalidFileTypeError"),
        displayError: true,
      });
    }
  };
  render() {
    let formData = this.props.formData;
    let exam = formData.exam;

    return (
      <Container>
        <Form>
          <ExamDetails
            step={this.props.step}
            examTitle={exam.title}
            duration={exam.duration}
            cost={this.state.examPrice || 0.0}
            examDate={formData.examDate}
            requires_payment={exam.requires_payment}
          />

          <Message
            visible={this.state.displayError}
            error={true}
            onDismiss={() => this.setState({ displayError: false })}
          >
            <Message.Header>{this.props.t("general.error")}</Message.Header>
            <p>{this.state.errorMessage}</p>
          </Message>
          {exam.file_upload_required ? (
            <Form.Field>
              <Segment>
                <span>
                  <Message info>
                    <Message.Header>
                      {this.props.t("general.fileUploader")}
                    </Message.Header>
                    <p>{exam.file_upload_description}</p>
                  </Message>
                  <Dropzone
                    maxSize={5242880}
                    style={{
                      maxWidth: "100%",
                      minHeight: "200px",
                      borderWidth: "2px",
                      borderColor: "rgb(102, 102, 102)",
                      borderStyle: "dashed",
                      borderRadius: "5px",
                    }}
                    accept="image/jpeg, image/png, .pdf, .doc, .docx"
                    onDrop={this.onDrop.bind(this)}
                  >
                    <Grid centered>
                      <Grid.Row></Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <h4>
                            {this.state.upload ? this.state.upload.name : null}
                          </h4>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          {this.state.uploadPercentage > 0 && (
                            <Progress
                              percent={this.state.uploadPercentage}
                              success
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Dropzone>
                </span>
              </Segment>
            </Form.Field>
          ) : null}

          {exam.requires_payment ? (
            <React.Fragment>
              <Segment>
                <Header>{this.props.t("general.coupon")}</Header>
                <Message
                  visible={this.state.displayCouponError}
                  error={true}
                  onDismiss={() =>
                    this.setState({
                      displayCouponError: false,
                      couponError: "",
                    })
                  }
                >
                  <Message.Header>
                    {this.props.t("PaymentDetails.couponError")}
                  </Message.Header>
                  <p>{this.state.couponError}</p>
                </Message>
                <Message
                  visible={this.state.displayCouponApplied}
                  success={true}
                  onDismiss={() =>
                    this.setState({ displayCouponApplied: false })
                  }
                >
                  <Message.Header>
                    {this.props.t("PaymentDetails.couponApplied")}
                  </Message.Header>
                  <p>{this.state.couponApplied}</p>
                </Message>
                <label
                  style={{
                    color: "rgba(0,0,0,.87)",
                    fontSize: ".92857143em",
                    fontWeight: "700",
                  }}
                >
                  {" "}
                  {this.props.t("general.couponCode")}
                </label>
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-coupon"
                    error={this.state.displayCouponError}
                    name="coupon"
                    value={this.state.coupon}
                    onChange={this.handleCouponChange}
                    control={Input}
                  />
                  <Button onClick={this.applyCoupon} floated="left">
                    <Button.Content visible>
                      {this.props.t("general.apply")}
                    </Button.Content>
                  </Button>
                  <Button onClick={this.resetCoupon} negative floated="left">
                    <Button.Content visible>
                      {this.props.t("general.reset")}
                    </Button.Content>
                  </Button>
                </Form.Group>
              </Segment>
              {this.state.examPrice > 0 ? (
                <Segment>
                  <Header>{this.props.t("PaymentDetails.header")}</Header>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-credit-number"
                      error={this.state.cardInputError}
                      name="pan"
                      value={this.state.pan}
                      onChange={this.handleInputChange}
                      control={Input}
                      label={this.props.t("ExamDetails.creditCardNumber")}
                      placeholder="IE: XXXX XXXX XXXX XXX"
                      onBlur={(e) =>
                        e.target.value.length !== 16
                          ? this.setState({ cardInputError: true })
                          : this.setState({ cardInputError: false })
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-credit-month"
                      error={this.state.monthInputError}
                      value={this.state.expMonth}
                      onChange={this.handleInputChange}
                      control={Input}
                      label={this.props.t("PaymentDetails.expMonth")}
                      name="expMonth"
                      placeholder="IE: 07"
                      onBlur={(e) =>
                        e.target.value.length !== 2
                          ? this.setState({ monthInputError: true })
                          : this.setState({ monthInputError: false })
                      }
                    />

                    <Form.Field
                      id="form-input-control-credit-year"
                      error={this.state.yearInputError}
                      value={this.state.expYear}
                      onChange={this.handleInputChange}
                      control={Input}
                      label={this.props.t("PaymentDetails.expYear")}
                      name="expYear"
                      placeholder="IE: 21"
                      onBlur={(e) =>
                        e.target.value.length !== 2
                          ? this.setState({ yearInputError: true })
                          : this.setState({ yearInputError: false })
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-credit-cvv"
                      error={this.state.cvvInputError}
                      value={this.state.cvv}
                      onChange={this.handleInputChange}
                      control={Input}
                      label={this.props.t("PaymentDetails.ccv")}
                      name="cvv"
                      placeholder="IE: 321"
                      onBlur={(e) =>
                        e.target.value.length !== 3
                          ? this.setState({ cvvInputError: true })
                          : this.setState({ cvvInputError: false })
                      }
                    />
                  </Form.Group>
                </Segment>
              ) : null}
            </React.Fragment>
          ) : null}
          <Segment>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Button
                    onClick={this.props.onBackward}
                    negative
                    floated="left"
                  >
                    <Button.Content visible>
                      <Icon name="left arrow" />{" "}
                      {this.props.t("general.previous")}
                    </Button.Content>
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    onClick={this.onSubmit}
                    positive
                    floated="right"
                    disabled={!this.validateForm() && this.state.examPrice > 0}
                  >
                    <Button.Content visible>
                      {this.props.t("general.next")} <Icon name="right arrow" />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
      coupons: state.coupons,
    }),
    {
      fetchCoupons,
      fetchCouponByName,
    }
  )(PaymentDetails)
);
