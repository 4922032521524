
import React from 'react';
import { List,Grid,Segment,Header, Button } from 'semantic-ui-react'
import {connect} from 'react-redux';
import {updateOpen} from '../reducers/vods';
import moment from 'moment';
import {NavLink} from 'react-router-dom';


class VodVideo extends React.Component {

    determineOffset(examStart,eventTime) {
        let startDate = moment(examStart);
        let eventDate = moment(eventTime);
        return moment.duration(eventDate.diff(startDate)).asSeconds();
    }

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handlePlayButton = this.handlePlayButton.bind(this);
        this.vids = [];
    }

    handlePlayButton(){
        this.vids.forEach( (vid) => {
           vid.play();
        });
    };

    handleClick(event,offset){
        this.vids.forEach( (vid) => {
            vid.currentTime = offset-5;
            vid.play();
        });
    }

    render() {
        let session_dsk_src = `${process.env.REACT_APP_API_ADDRESS}/api/video?id=${this.props.session.examinationId.id}&session=${this.props.session.session_guid}&token=${this.props.kc.token}`;
        let session_web_src = `${process.env.REACT_APP_API_ADDRESS}/api/video?id=${this.props.session.examinationId.id}&session=${this.props.session.session_guid}&webcam=true&token=${this.props.kc.token}`;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        <Header><NavLink to="/settings">{this.props.session.examinationId.client.organization_name}</NavLink> - Exam Replay - {this.props.session.user.username} - {this.props.session.examinationId.title} - {moment(this.props.session.start_time).format('MMMM Do, YYYY')}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Column width={10}>
                    <Segment>
                        <video controls id="two"
                               style={{maxWidth: '100%'}}
                               ref={(vid) => { this.vids.push(vid) }}
                               src={session_web_src}
                        />
                    </Segment>
                    <Segment>
                        <video controls id="one"
                               style={{maxWidth: '100%'}}
                               ref={(vid) => { this.vids.push(vid) }}
                               src={session_dsk_src}
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Segment style={{height:935,overflow:'auto'}}>
                        <Header>Event Log</Header>
                        <Button onClick={this.handlePlayButton}>
                            <Button.Content visible>Play Videos</Button.Content>
                        </Button>
                        <List>
                            {this.props.session.sessionEvents.map((event,i)=>{
                                let offset = this.determineOffset(this.props.session.createdAt,event.createdAt);
                                return(
                                    <List.Item key={i}>{event.event} -
                                        (<a onClick={this.handleClick.bind(this,event,offset)}>{offset}</a>)
                                    </List.Item>
                                )
                            })}
                        </List>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

export default connect(
    (state) => ({
        kc: state.keycloak,
        open: state.vods.open
    }),
    {updateOpen}
)(VodVideo);


