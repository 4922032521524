import React, { Component } from "react";
import { connect } from "react-redux";
import parseTimezone from "../../lib/helperServices";
import {
  Container,
  Header,
  Button,
  Grid,
  Dropdown,
  Segment,
  Form,
  Icon,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import ExamDetails from "./ExamDetails";
import {
  fetchBookingTimesForDate,
  fetchBookingsForUser,
  fetchNextAvailableBooking,
} from "../../reducers/exam";
import "moment-timezone/builds/moment-timezone-with-data-2012-2022";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";
import { getBlockedDaysForSixMonths } from "./../../reducers/booking";

class SelectDates extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let times = [];
    moment.tz.setDefault("Canada/Atlantic");
    this.timeFormat = localStorage.getItem("lang") === "en" ? "hh:mm a" : "HH:mm";
    if (
      this.props.formData.exam.available_from_time &&
      this.props.formData.exam.available_to_time
    ) {
      let available_from_time = moment(
        this.props.formData.exam.available_from_time,
        "hh:mm:ss"
      );
      let available_to_time = moment(
        this.props.formData.exam.available_to_time,
        "hh:mm:ss"
      );

      while (available_from_time.isBefore(available_to_time)) {
        times.push({
          text: available_from_time.format(this.timeFormat),
          value: available_from_time.format(this.timeFormat),
        });
        available_from_time.add(1, "hour");
      }
    } else {
      times = [
        { text: "9:00 AM", value: "9:00" },
        { text: "10:00 AM", value: "10:00" },
        { text: "11:00 AM", value: "11:00" },
        { text: "12:00 PM", value: "12:00" },
        { text: "1:00 PM", value: "13:00" },
        { text: "2:00 PM", value: "14:00" },
        { text: "3:00 PM", value: "15:00" },
      ];
    }

    let timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    let timezone_array = this.props.kc.tokenParsed.timezone.split("");

    this.available_times = times.map((data) => {
      data.text = `${moment(data.text, this.timeFormat)
        .tz(timezone)
        .format(this.timeFormat)} (${timezone_array[0]}${timezone_array[2]})`;
      return data;
    });

    this.state = {
      examDate: null,
      examTime: null,
      available_times:
        this.props.formData.available_times || this.available_times,
      excludedDates: [],
      datePickerLoading: true,
      bookLimit: "",
      nextBookingTimezone: null,
    };
  }
  async componentDidMount() {
    let timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    let timeFormat = localStorage.getItem("lang") === "en" ? "hh:mm a" : "HH:mm";

    const excludedDates = this.getExcludedDates();
    this.setState({ excludedDates, datePickerLoading: false });
    this.props.getBlockedDaysForSixMonths(this.props.formData.exam.id);
    this.props
      .fetchBookingsForUser(
        this.props.formData.exam.id,
        this.props.kc.tokenParsed.sub
      )
      .then((limit) => {
        this.setState({ bookLimit: limit });
      });
    let nextAvailable = await this.props.fetchNextAvailableBooking(
      this.props.formData.exam.id,
      this.props.kc.tokenParsed.sub
    );

    if (nextAvailable) {
      const nextBooking = moment(nextAvailable, ["YYYY-MM-DD HH:mm:ss"]);
      const nextBookingTimezone = moment(nextAvailable, ["YYYY-MM-DD "+timeFormat]).tz(timezone);
      this.setState({
        nextBookingTimezone:nextBookingTimezone,
        examDate: nextBooking,
        examTime: nextBooking.format(timeFormat)
      });

      this.props.fetchBookingTimesForDate(
        this.props.formData.exam.id,
        nextBooking.format("YYYY-MM-DD")
      ).then((times) => {
        let mappedTimes = times.map((time) => {
          return moment(time, ["HH:mm"]).format(this.timeFormat);
        });
        let availableTimes = this.available_times.map((available) => {
          return Object.assign({}, available, {
            disabled: mappedTimes.indexOf(available.value) !== -1,
            text:
              mappedTimes.indexOf(available.value) !== -1
                ? `${available.text} - ${this.props.t(
                    "SelectDates.fullyBooked"
                  )}`
                : available.text,
          });
        });
        this.setState({available_times:availableTimes});
      });
    } 
  }
  getExcludedDates() {
    let daysAvailable = JSON.parse(this.props.formData.exam.available_days);
    let excludeDates = [];
    if (daysAvailable && daysAvailable.length > 0) {
      const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
      let days = [];
      for (let day of daysOfWeek) {
        if (daysAvailable.indexOf(day) === -1) {
          days.push(daysOfWeek.indexOf(day) + 1);
        }
      }
      let today = moment();
      let end = today.clone().add(5, "month");

      while (today.isBefore(end)) {
        if (days.indexOf(today.isoWeekday()) !== -1) {
          excludeDates.push(today.format("YYYY-MM-DD"));
        }

        today.add(1, "day");
      }
      var now = moment();
      excludeDates.push(now.format("YYYY-MM-DD"));
      var tomorrow = now.add(1, "day");
      excludeDates.push(tomorrow.format("YYYY-MM-DD"));
    }


    return excludeDates;
  }
  updateExamDate = (date) => {
    if (date) {
      this.props
        .fetchBookingTimesForDate(
          this.props.formData.exam.id,
          date.format("YYYY-MM-DD")
        )
        .then((times) => {
          let mappedTimes = times.map((time) => {
            return moment(time, ["HH:mm"]).format(this.timeFormat);
          });

          let availableTimes = this.available_times.map((available) => {
            return Object.assign({}, available, {
              disabled: mappedTimes.indexOf(available.value) !== -1,
              text:
                mappedTimes.indexOf(available.value) !== -1
                  ? `${available.text} - ${this.props.t(
                      "SelectDates.fullyBooked"
                    )}`
                  : available.text,
            });
          });
          this.setState({
            available_times: availableTimes,
            examDate: date,
            examTime: null,
          });
        });
    } else {
      this.setState({
        examDate: date,
        examTime: null,
      });
    }
  };

  updateExamTime = (e, { text, value }) => {
    let examDate = new moment(
      `${this.state.examDate.clone().format("YYYY-MM-DD")} ${value}`,
      "YYYY-MM-DD " + this.timeFormat
    );
    this.setState({
      examTime: value,
      examDate: examDate,
    });
  };
  onSubmit = (event, data) => {
    event.preventDefault();
    if (this.state.examDate && this.state.examTime) {
      this.props.onForward({
        examDate: this.state.examDate,
        examTime: this.state.examTime,
      });
    }
  };
  render() {
    let formData = this.props.formData;
    let exam = formData.exam;
    return (
      <Container>
        {this.state.bookLimit ? (
          <Form>
            <Form.Field>
              <Segment>
                <Header>{this.props.t("general.note")}</Header>
                {this.state.bookLimit}
              </Segment>
              <Button
                onClick={() => {
                  this.setState({
                    examDate: null,
                    examTime: null,
                  });
                  this.props.onBackward();
                }}
                negative
                floated="left"
              >
                <Button.Content visible>
                  <Icon name="left arrow" />
                  {this.props.t("general.previous")}
                </Button.Content>
              </Button>
            </Form.Field>
          </Form>
        ) : (
          <Form>
            <ExamDetails
              step={this.props.step}
              examTitle={exam.title}
              duration={exam.duration}
              cost={exam.cost || 0.0}
            />
            <Form.Field>
              <Segment.Group horizontal>
                <Segment>
                  <Header>{this.props.t("general.nextBooking")}</Header>
                  <Grid columns={1} relaxed="very">
                    <Grid.Column>
                      <p align="center" style={{ padding: 10 }}>
                        {this.state.nextBookingTimezone === null
                          ? ""
                          : this.state.nextBookingTimezone.format(
                              "MMMM DD,YYYY "+this.timeFormat
                            )}
                      </p>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Segment>
                  <Header>{this.props.t("general.date")}</Header>
                  <DatePicker
                    disabled={this.state.datePickerLoading}
                    selected={this.state.examDate}
                    onChange={this.updateExamDate}
                    minDate={moment()}
                    maxDate={moment().add(5, "months")}
                    excludeDates={[
                      ...this.state.excludedDates,
                      ...this.props.blockedDaysInMonth.map(function (date) {
                        let day = date.day.toString();
                        day = day.length === 1 ? `0${date.day}` : date.day;
                        let month = date.month.toString();
                        month =
                          month.length === 1 ? `0${date.month}` : date.month;
                        let year = date.every_year
                          ? new Date().getFullYear()
                          : date.year;
                        if (date.all_day === true) {
                          return `${year}-${month}-${day}`;
                        }
                      }),
                    ]}
                    disabledKeyboardNavigation={true}
                  />
                </Segment>
                <Segment>
                  <Header>{this.props.t("general.time")}</Header>
                  <Dropdown
                    fluid
                    disabled={this.state.examDate === null}
                    style={{ marginRight: 10 }}
                    onChange={this.updateExamTime}
                    value={this.state.examTime}
                    placeholder={
                      this.state.examDate
                        ? this.props.t("general.time")
                        : this.props.t("SelectDates.selectDate")
                    }
                    search
                    selection
                    options={this.state.available_times}
                  />
                </Segment>
              </Segment.Group>
            </Form.Field>
            <Segment>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button
                      onClick={() => {
                        this.setState({
                          examDate: null,
                          examTime: null,
                        });
                        this.props.onBackward();
                      }}
                      negative
                      floated="left"
                    >
                      <Button.Content visible>
                        <Icon name="left arrow" />
                        {this.props.t("general.previous")}
                      </Button.Content>
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      onClick={this.onSubmit}
                      disabled={
                        this.state.examDate == null ||
                        this.state.examTime == null
                      }
                      positive
                      floated="right"
                    >
                      <Button.Content visible>
                        {this.props.t("general.next")}
                        <Icon name="right arrow" />
                      </Button.Content>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        )}
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
      blockedDaysInMonth: state.bookings.blockedDaysInMonth,
      userBookings: state.bookings.userBookings,
      nextBooking: state.nextBooking,
    }),
    {
      fetchBookingTimesForDate,
      getBlockedDaysForSixMonths,
      fetchBookingsForUser,
      fetchNextAvailableBooking,
    }
  )(SelectDates)
);
