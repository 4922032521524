export default function parseTimezone(timezone, province = "") {
  switch (timezone) {
    case "NST":
    case "NT":
    case "NDT":
      timezone = "Canada/Newfoundland";
      break;
    case "AST":
    case "ADT":
    case "AT":
      timezone = "Canada/Atlantic";
      break;
    case "EST":
    case "EDT":
    case "ET":
      timezone = "Canada/Eastern";
      break;
    case "CT":
    case "CDT":
    case "CST":
      if (province === "SK") {
        timezone = "Canada/Saskatchewan";
      } else {
        timezone = "Canada/Central";
      }
      break;
    case "MT":
    case "MDT":
    case "MST":
      timezone = "Canada/Mountain";
      break;
    case "PT":
    case "PDT":
    case "PST":
      timezone = "Canada/Pacific";
      break;
  }
  return timezone;
}
