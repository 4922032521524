import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class MainMenu extends Component {
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    return (
      <Menu
        pointing
        className="admin-menu-tabs"
        widths={6}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Menu.Item
          as={NavLink}
          to="/scheduled-exams"
          name="scheduled-exams"
          active={activeItem === "scheduled-exams"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.scheduledExams")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/pending-requests"
          name="pending-requests"
          active={activeItem === "pending-requests"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.pendingRequests")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/exam"
          name="exams"
          active={activeItem === "exams"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.exams")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/schedule"
          name="schedule"
          active={activeItem === "scheduling"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.scheduling")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/learner-details"
          name="learners"
          active={activeItem === "learners"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.learners")}
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/settings"
          name="settings"
          active={activeItem === "settings"}
          onClick={this.handleItemClick}
        >
          {this.props.t("general.settings")}
        </Menu.Item>
      </Menu>
    );
  }
}

export default withTranslation()(MainMenu);
