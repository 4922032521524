import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Container,
  Icon,
  Message,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  fetchAllBookings,
  updateApproved,
  updateRejectModal,
  updateReason,
} from "./../reducers/booking";
import RejectModal from "../components/RejectModal";
import { fetchLearners } from "./../reducers/learners";
import MainMenu from "./../components/MainMenu";
import DatePicker from "react-datepicker";
import { sendEmail } from "./../lib/emailService";
import PurchaseReceipt from "./../components/emails/PurchaseReceipt";
import BookingApproval from "./../components/emails/BookingApproval";
import BookingRejected from "./../components/emails/BookingRejected";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { withTranslation } from "react-i18next";
import moment from "moment";
import i18n from "./../i18n";

const STATUS_COLUMN = 8;
const EXAM_COLUMN = 5;
const SUBMITTED_COLUMN = 6;

class PendingRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.bookings.allBookings,
      displayError: false,
      openReject: false,
      error: "",
      reason: "",
      id: "",
    };

    this.handleDownloadUpload = this.handleDownloadUpload.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.updateApproved = this.updateApproved.bind(this);
  }
  componentWillMount() {
    this.props.fetchAllBookings();
  }
  updateReason(value) {
    this.props.updateReason(value);
  }
  rejectReasoning(details) {
    this.props.updateRejectModal();
    this.setState({
      id: details.id,
    });
  }
  handleDownloadUpload(id) {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/api/bookings/${id}/upload`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .trim();
        link.setAttribute(
          "download",
          fileName.substring(1, fileName.length - 1)
        );
        document.body.appendChild(link);
        link.click();
      });
  }
  componentDidMount() {
    this.refs.pendingRequests.filterColumn(
      this.columns[STATUS_COLUMN],
      "pending"
    );
  }

  defaultFilterMethod = (filter, row, column) => {
    if (column.id === "submittedDate") {
      let startDate = moment(this.state.startDate).format();
      let endDate = moment(this.state.endDate).format();
      const id = filter.pivotId || filter.id;
      let date = moment(row[id]).format();
      return moment(startDate).isBefore(date) && moment(endDate).isAfter(date);
    } else {
      const id = filter.pivotId || filter.id;
      return row[id] !== undefined ? row[id] === filter.value : true;
    }
  };
  renderStatusColumn(row) {
    const status = row.original.approved;
    const color = ["approved", "completed"].includes(status) ? "green" : "red";
    return (
      <span style={{ color: status === "pending" ? null : color }}>
        {`${this.props.t(`general.${status}`)} `}
      </span>
    );
  }

  updateApproved = (details) => {
    const timeFormat =
      localStorage.getItem("lang") === "en"
        ? "MMMM Do YYYY - h:mm A"
        : "MMMM Do YYYY - HH:mm";
    this.props
      .updateApproved(details)
      .then((booking) => {
        const t = i18n.getFixedT(
          booking.attributes.locale ? booking.attributes.locale[0] : "en"
        );

        if (details.approved === "approved") {
          sendEmail({
            to: booking.email,
            subject: t("PendingRequests.receiptEmailSubject"),
            component: (
              <PurchaseReceipt
                t={t}
                timeFormat={timeFormat}
                name={`${booking.firstName} ${booking.lastName}`}
                invoiceNumber={
                  booking.status.order ? booking.status.order.order_id : "N/A"
                }
                examTitle={booking.status.exam.title}
                examCost={
                  booking.status.request
                    ? Number(booking.status.request.amount).toFixed(2)
                    : "0"
                }
                taxRate={
                  booking.status.request
                    ? booking.status.request.tax_rate
                    : "N/A"
                }
                taxCost={
                  booking.status.request
                    ? Number(booking.status.request.taxes).toFixed(2)
                    : "N/A"
                }
                total={
                  booking.status.request
                    ? Number(
                        booking.status.request.amount +
                          booking.status.request.taxes
                      ).toFixed(2)
                    : "$0"
                }
              />
            ),
          });

          let timezone = "";
          let acr = "";
          switch (booking.attributes.timezone[0]) {
            case "NST":
              timezone = "Canada/Newfoundland";
              acr = "NT";
              break;
            case "AST":
              timezone = "Canada/Atlantic";
              acr = "AT";
              break;
            case "EST":
              timezone = "Canada/Eastern";
              acr = "ET";
              break;
            case "CST":
              if (booking.attributes.province[0] === "SK") {
                timezone = "Canada/Saskatchewan";
              } else {
                timezone = "Canada/Central";
              }
              acr = "CT";
              break;
            case "MST":
              timezone = "Canada/Mountain";
              acr = "MT";
              break;
            case "PST":
              timezone = "Canada/Pacific";
              acr = "PT";
              break;
          }

          sendEmail({
            to: booking.email,
            subject: t("PendingRequests.bookingConfirmedEmail"),
            component: (
              <BookingApproval
                name={`${booking.firstName} ${booking.lastName}`}
                t={t}
                examTitle={booking.status.exam.title}
                timezone={acr}
                bookingDate={moment(booking.status.booking_date)
                  .tz(timezone)
                  .format("MMMM Do YYYY - h:mm A")}
                duration={booking.status.exam.duration}
                examRules={
                  booking.status.exam.student_rules
                    ? booking.status.exam.student_rules
                    : "N/A"
                }
              />
            ),
          });
        } else {
          this.props.updateRejectModal();
          sendEmail({
            to: booking.email,
            subject: t("PendingRequests.bookingRejectedEmail"),
            component: (
              <BookingRejected
                t={t}
                name={`${booking.firstName} ${booking.lastName}`}
                invoiceNumber={
                  booking.status.order ? booking.status.order.order_id : "N/A"
                }
                examTitle={booking.status.exam.title}
                reason={this.props.bookings.reasoning}
              />
            ),
          });
        }
      })
      .catch((error) => {
        this.setState({
          displayError: true,
          error: error,
        });
      });
  };

  columns = [
    {
      Header: this.props.t("general.username"),
      accessor: "user.username",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.firstName"),
      accessor: "user.first_name",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.lastName"),
      accessor: "user.last_name",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.birthDate"),
      accessor: "dob",
      Cell: (row) => {
        if (row.original.user.dob !== null) {
          return (
            <span>{moment(row.original.user.dob).format("MMMM Do, YYYY")}</span>
          );
        } else {
          return <span></span>;
        }
      },
      id: "birthDate",
      sortable: true,
      filterable: true,
    },
    {
      Header: this.props.t("general.learningInstitution"),
      accessor: "client.organization_name",
      id: "org",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.exam"),
      accessor: "exam.title",
      id: "exam",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("PendingRequests.submittedDate"),
      accessor: "createdAt",
      Cell: (row) => moment(row.original.createdAt).format("MMMM Do, YYYY"),
      id: "submittedDate",
      sortable: true,
      filterable: false,
    },
    {
      Header: this.props.t("PendingRequests.bookingDate"),
      accessor: "booking_date",
      Cell: (row) =>
        moment(row.original.booking_date).format("MMMM Do YYYY, h:mm a"),
      id: "bookingDate",
      sortable: true,
      sort: "desc",
      filterable: false,
    },
    {
      Header: this.props.t("general.status"),
      accessor: "approved",
      sortable: true,
      filterable: true,
      Cell: (row) => {
        return (
          <React.Fragment>
            {this.renderStatusColumn(row)}
            {row.original.exam.file_upload_required ? (
              <a
                href="#"
                style={{ color: "black" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleDownloadUpload(row.original.id);
                }}
              >
                <Icon name="file outline" size="large" />
              </a>
            ) : null}
          </React.Fragment>
        );
      },
      id: "status",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
    },
    {
      Header: this.props.t("general.actions"),
      Cell: (row) => {
        let disabled = row.original.approved !== "pending";
        return (
          <Dropdown
            button
            text="View"
            style={{ overflow: "inherit !important" }}
            disabled={disabled}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => this.handleDownloadUpload(row.original.id)}
              >
                {this.props.t("PendingRequests.downloadFile")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  this.updateApproved({
                    id: row.original.id,
                    approved: "approved",
                  })
                }
              >
                {this.props.t("general.approve")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.rejectReasoning({ id: row.original.id })}
              >
                {this.props.t("general.reject")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      style: {
        overflow: "inherit",
      },
      sortable: false,
      filterable: false,
    },
  ];

  render() {
    let options = [
      { value: "approved", text: this.props.t("general.approved") },
      { value: "rejected", text: this.props.t("general.rejected") },
      { value: "pending", text: this.props.t("general.pending") },
      { value: "completed", text: this.props.t("general.completed") },
    ];
    return (
      <Container>
        <MainMenu />
        <div>
          <Form>
            <Message
              visible={this.state.displayError}
              error={true}
              onDismiss={() => this.setState({ displayError: false })}
            >
              <Message.Header>
                {this.props.t("PendingRequests.paymentError")}
              </Message.Header>
              <p>{this.state.error}</p>
            </Message>
            <Form.Group inline>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setState({ startDate: date })}
              />
              <Icon name="calendar" size="big" />
              <label>to</label>
              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setState({ endDate: date })}
              />
              <Icon name="calendar" size="big" />
              <Button
                onClick={(e, data) =>
                  this.refs.pendingRequests.filterColumn(
                    this.columns[SUBMITTED_COLUMN],
                    data.value
                  )
                }
                style={{ marginRight: 170 }}
                disabled={
                  this.state.startDate === "" || this.state.endDate === ""
                }
              >
                {this.props.t("general.search")}
              </Button>
              <Dropdown
                style={{ marginRight: 10 }}
                placeholder="Exam"
                onChange={(e, data) =>
                  this.refs.pendingRequests.filterColumn(
                    this.columns[EXAM_COLUMN],
                    data.value
                  )
                }
                search
                selection
                options={this.props.bookings.exams}
              />
              <Dropdown
                value="pending"
                placeholder="Status"
                onChange={(e, data) =>
                  this.refs.pendingRequests.filterColumn(
                    this.columns[STATUS_COLUMN],
                    data.value
                  )
                }
                search
                selection
                options={options}
              />
            </Form.Group>
          </Form>
          <ReactTable
            ref="pendingRequests"
            columns={this.columns}
            data={this.props.bookings.allBookings}
            filterable
            defaultPageSize={10}
            className="-striped -highlight"
            defaultFilterMethod={this.defaultFilterMethod}
            minRows={0}
          />
        </div>
        <RejectModal
          open={this.props.bookings.showRejectModal}
          updateRejectModal={this.props.updateRejectModal}
          updateReason={this.updateReason}
          updateApproved={this.updateApproved}
          bookingId={this.state.id}
        />
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      bookings: state.bookings,
    }),
    {
      fetchAllBookings,
      fetchLearners,
      updateApproved,
      updateRejectModal,
      updateReason,
    }
  )(PendingRequests)
);
