import {getAllRooms, storeRoom, moveUserToRoom, deleteRoom} from '../lib/roomService';
import moment from 'moment';

const initState = {
    rooms:[],
    events:[],
    showCreateRoom: false,
    availableRooms: [],
    showLoader: true,
};

const LOAD_ALL_ROOMS = 'LOAD_ALL_ROOMS';
const UPDATE_SHOW_CREATE_ROOM = 'UPDATE_SHOW_CREATE_ROOM';
const POPULATE_AVAILABLE_ROOMS =  'POPULATE_AVAILABLE_ROOMS';

export const loadAllRooms = (rooms) => ({type:LOAD_ALL_ROOMS,payload:rooms});
export const updateShowCreateRoom = (payload) => ({type: UPDATE_SHOW_CREATE_ROOM, payload});
export const populatedAvailableRooms = (slot_datetime) => ({type: POPULATE_AVAILABLE_ROOMS, payload: slot_datetime});

export const fetchAllRooms = () => {
    return (dispatch) => {
        getAllRooms().then(rooms => dispatch(loadAllRooms(rooms)));
    }
};

export const moveUser = (booking_id, room_id) => {
    return async () => {
        return await moveUserToRoom(booking_id, room_id);
    }
};

export const sendDeleteRoom = (room_id) => {
    return async () => {
        await deleteRoom(room_id);
    }
};

export const saveRoom = (room) => {
    return async (dispatch) => {
        await storeRoom(room);
    }
}

export default (state = initState, action) => {
    switch(action.type){
        case LOAD_ALL_ROOMS:
            const events = [];
            for (let room of action.payload) {
                const start = moment(room.slot_datetime);
                //finding the longest exam in the room
                let duration = 0;
                for (let booking of room.bookings) {
                    if (booking.exam.duration > duration) {
                        duration = booking.exam.duration;
                    }
                }
                const end = moment(room.slot_datetime).add(duration,'hours');

                events.push({
                    start:start._d,
                    end:end._d,
                    room,
                    title: room.title
                });
            }
            return {...state, rooms: action.payload, events, showLoader: false};
        case UPDATE_SHOW_CREATE_ROOM:
            return {...state, showCreateRoom: action.payload};
        case POPULATE_AVAILABLE_ROOMS:
            //filter for rooms that are at the same time but not the current room
            const availableRooms = state.rooms.filter((room) => {
                if (room.slot_datetime === action.payload.slot_datetime && room.id !== action.payload.id) {
                    return room;
                }
            });
            return {...state, availableRooms};    
        default:
            return state;
    }
}
