const initState = {
  profile_image: "",
  emailAddress: "",
  firstName: "",
  lastName: "",
  timezone: "",
  learningInstitution: 0,
  province: "",
  hideSuccessMessage: true,
  hideErrorMessage: true,
  phone: "",
  streetAddress: "",
  birthday: "",
  city: "",
  postalCode: "",
};

const UPDATE_EMAIL_ADDRESS = "UPDATE_EMAIL_ADDRESS";
const UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME";
const UPDATE_LAST_NAME = "UPDATE_LAST_NAME";
const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
const UPDATE_LEARNING_INSTITUTION = "UPDATE_LEARNING_INSTITUTION";
const UPDATE_HIDE_SUCCESS = "UPDATE_HIDE_SUCCESS";
const UPDATE_HIDE_ERROR = "UPDATE_HIDE_ERROR";
const UPDATE_PROVINCE = "UPDATE_PROVINCE";
const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
const UPDATE_PHONE = "UPDATE_PHONE";
const UPDATE_BIRTHDAY = "UPDATE_BIRTHDAY";
const UPDATE_STREET_ADDRESS = "UPDATE_STREET_ADDRESS";
const UPDATE_CITY = "UPDATE_CITY";
const UPDATE_POSTAL_CODE = "UPDATE_POSTAL_CODE";

export const updateEmailAddress = (emailAddress) => ({
  type: UPDATE_EMAIL_ADDRESS,
  payload: emailAddress,
});
export const updateFirstName = (firstName) => ({
  type: UPDATE_FIRST_NAME,
  payload: firstName,
});
export const updateLastName = (lastName) => ({
  type: UPDATE_LAST_NAME,
  payload: lastName,
});
export const updateTimezone = (timezone) => ({
  type: UPDATE_TIMEZONE,
  payload: timezone,
});
export const updateLearningInstitution = (learningInstitution) => ({
  type: UPDATE_LEARNING_INSTITUTION,
  payload: learningInstitution,
});
export const updateHideSuccess = (success) => ({
  type: UPDATE_HIDE_SUCCESS,
  payload: success,
});
export const updateHideError = (success) => ({
  type: UPDATE_HIDE_ERROR,
  payload: success,
});
export const updateProvince = (province) => ({
  type: UPDATE_PROVINCE,
  payload: province,
});
export const updateProfileImage = (image) => ({
  type: UPDATE_PROFILE_IMAGE,
  payload: image,
});
export const updatePhone = (phone) => ({ type: UPDATE_PHONE, payload: phone });
export const updateBirthday = (birthday) => ({
  type: UPDATE_BIRTHDAY,
  payload: birthday,
});
export const updateStreetAddress = (streetAddress) => ({
  type: UPDATE_STREET_ADDRESS,
  payload: streetAddress,
});
export const updateCity = (city) => ({ type: UPDATE_CITY, payload: city });
export const updatePostalCode = (postalCode) => ({
  type: UPDATE_POSTAL_CODE,
  payload: postalCode,
});

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_ADDRESS:
      return { ...state, emailAddress: action.payload };
    case UPDATE_FIRST_NAME:
      return { ...state, firstName: action.payload };
    case UPDATE_LAST_NAME:
      return { ...state, lastName: action.payload };
    case UPDATE_TIMEZONE:
      return { ...state, timezone: action.payload };
    case UPDATE_LEARNING_INSTITUTION:
      return { ...state, learningInstitution: action.payload };
    case UPDATE_HIDE_SUCCESS:
      return { ...state, hideSuccessMessage: action.payload };
    case UPDATE_HIDE_ERROR:
      return { ...state, hideErrorMessage: action.payload };
    case UPDATE_PROVINCE:
      return { ...state, province: action.payload };
    case UPDATE_PROFILE_IMAGE:
      return { ...state, profile_image: action.payload };
    case UPDATE_PHONE:
      return { ...state, phone: action.payload };
    case UPDATE_BIRTHDAY:
      return { ...state, birthday: action.payload };
    case UPDATE_STREET_ADDRESS:
      return { ...state, streetAddress: action.payload };
    case UPDATE_CITY:
      return { ...state, city: action.payload };
    case UPDATE_POSTAL_CODE:
      return { ...state, postalCode: action.payload };
    default:
      return state;
  }
};
