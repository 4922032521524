import React, { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Modal,
  Form,
  Checkbox,
  Dropdown,
  Message,
  Input,
  Label,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  updateExamName,
  updateDuration,
  saveExam,
  updateAvailableFrom,
  updateAvailableTo,
  setIsUpdating,
  updateExamCreator,
  updateCost,
  updateDescription,
  updateLimitNotice,
  updateRules,
  updateStudentRules,
  updatePaymentRequired,
  updateOpenAddExam,
  updateCostDisabled,
  fetchClients,
  updateClient,
  updateDaysAvailable,
  updateAvailableFromTime,
  updateAvailableToTime,
  updateCapacity,
  updateLimit,
  updateFileUploadDescription,
  updateFileUploadRequired,
} from "../reducers/exam";
import ExamTable from "../components/ExamTable";
import MainMenu from "./../components/MainMenu";
import DatePicker from "react-datepicker";
import moment from "moment";
import { withTranslation, Trans } from "react-i18next";

class Exam extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.updateExamName = this.updateExamName.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.update = this.update.bind(this);
    this.updateCost = this.updateCost.bind(this);
    this.updateFileUploadDescription =
      this.updateFileUploadDescription.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.handleAddExam = this.handleAddExam.bind(this);
    this.handleAvailableFromTime = this.handleAvailableFromTime.bind(this);
    this.handleAvailableToTime = this.handleAvailableToTime.bind(this);
    this.handleFileUploadRequired = this.handleFileUploadRequired.bind(this);

    this.state = {
      nameError: false,
      durationError: false,
      clientError: false,
      learningInstitutionError: false,
      costError: false,
      error: false,
      startTime: null,
      endTime: null,
      excludedToTimes: [],
      excludedFromTimes: [],
      fileUploadDescriptionError: false,
    };
  }
  componentDidMount() {
    this.props.fetchClients();
  }

  submitForm(e){
    e.preventDefault();
    let error = false;
    if(this.props.exam.examName.length === 0){
        this.setState({nameError:true});
        error = true;
    }
    if(this.props.exam.duration.length === 0){
        this.setState({durationError:true});
        error = true;
    }

    if(this.props.exam.client.length === 0){
        this.setState({clientError:true});
        error = true;
    }

    if(this.props.exam.requires_payment && this.props.exam.cost.length === 0){
        this.setState({costError:true});
        error = true;
    }
    this.setState({error:error});
    if(!error){
      if(this.props.exam.isUpdating){
        this.props.updateExamCreator({
          id:this.props.exam.examToUpdate,
          title:this.props.exam.examName,
          description: this.props.exam.description,
          rules: this.props.exam.rules,
          student_rules: this.props.exam.student_rules,
          client_id: this.props.exam.client,
          available_from:moment().format(),
          available_to:moment().format(),
          duration:this.props.exam.duration,
          capacity: this.props.exam.capacity,
          limit: this.props.exam.limit,
          limit_notice: this.props.exam.limit_notice,
          requires_payment:this.props.exam.requires_payment,
          cost: this.props.exam.cost === '' ? null : this.props.exam.cost,
          available_days: JSON.stringify(this.props.exam.daysAvailable),
          available_from_time: this.props.exam.available_from_time ? moment(this.props.exam.available_from_time).format('h:mm:ss a') : null,
          available_to_time: this.props.exam.available_to_time ? moment(this.props.exam.available_to_time).format('h:mm:ss a') : null,
          file_upload_required: this.props.exam.fileUploadRequired,
          file_upload_description: this.props.exam.fileUploadDescription
        });
      } else {
        this.props.saveExam({
          title:this.props.exam.examName,
          description: this.props.exam.description,
          rules: this.props.exam.rules,
          student_rules: this.props.exam.student_rules,
          client_id: this.props.exam.client,
          available_from:moment().format(),
          available_to:moment().format(),
          duration:this.props.exam.duration,
          requires_payment:this.props.exam.requires_payment,
          cost: this.props.exam.cost === '' ? null : this.props.exam.cost,
          available_days: JSON.stringify(this.props.exam.daysAvailable),
          available_from_time: this.props.exam.available_from_time ? moment(this.props.exam.available_from_time).format('h:mm a') : null,
          available_to_time: this.props.exam.available_to_time ? moment(this.props.exam.available_to_time).format('h:mm a') : null,
          capacity: this.props.exam.capacity,
          limit: this.props.exam.limit,
          limit_notice: this.props.exam.limit_notice,
          file_upload_required: this.props.exam.fileUploadRequired,
          file_upload_description: this.props.exam.fileUploadDescription
        });
      }
      this.props.updateOpenAddExam();
    }
  }
  updateExamName(value) {
    this.props.updateExamName(value);
  }
  updateCost(value) {
    this.props.updateCost(value);
  }
  updateFileUploadDescription(value) {
    this.props.updateFileUploadDescription(value);
  }
  updateDescription(value) {
    this.props.updateDescription(value);
  }
  updateRules(value) {
    this.props.updateRules(value);
  }
  updateLimitNotice(value) {
    this.props.updateLimitNotice(value);
  }
  updateDaysAvailable(value) {
    this.props.updateDaysAvailable(value);
  }
  updateStudentRules(value) {
    this.props.updateStudentRules(value);
  }
  cancelUpdate() {
    this.props.setIsUpdating(false);
    this.updateExamName("");
    this.updateDuration("");
  }
  update() {
    this.props.updateExamCreator({
      id: this.props.exam.examToUpdate.id,
      title: this.props.exam.examName,
      duration: this.props.exam.duration,
      limit_notice: this.props.exam.limit_notice,
      rules: this.props.exam.rules,
      student_rules: this.props.exam.student_rules,
      start_time: this.props.exam.startDate + " " + this.props.exam.startTime,
    });
    this.props.setIsUpdating(false);
    this.updateExamName("");
    this.updateDuration("");
  }
  handleAddExam() {
    this.props.updateOpenAddExam();
    this.props.updateExamName("");
    this.props.updateDuration("");
    this.props.updateCost("");
    this.props.updateRules("");
    this.props.updateLimitNotice("");
    this.props.updateStudentRules("");
    this.props.updatePaymentRequired(true);
    this.props.updateDescription("");
    this.props.updateCostDisabled(false);
    this.props.setIsUpdating(false);
    this.props.updateDaysAvailable([]);
    this.props.updateAvailableToTime("");
    this.props.updateAvailableFromTime("");
    this.props.updateFileUploadRequired(false);
    this.props.updateFileUploadDescription("");
  }
  handleAvailableFromTime(time) {
    this.props.updateAvailableFromTime(time);
    let startTime = moment("12:00 am", "h:mm a");
    let selected = moment(time.format("h:mm a"), "h:mm a");
    let excludedTimes = [];

    while (selected.isAfter(startTime) || selected.isSame(startTime)) {
      excludedTimes.push(moment(selected.format("h:mm a"), "h:mm a"));
      selected.subtract("1", "hour");
    }
    this.setState({ excludedToTimes: excludedTimes });
  }
  handleAvailableToTime(time) {
    this.props.updateAvailableToTime(time);
    let endTime = moment("11:00 pm", "h:mm a");
    let selected = moment(time.format("h:mm a"), "h:mm a");
    let excludedTimes = [];

    while (selected.isBefore(endTime) || selected.isSame(endTime)) {
      excludedTimes.push(moment(selected.format("h:mm a"), "h:mm a"));
      selected.add("1", "hour");
    }
    this.setState({ excludedFromTimes: excludedTimes });
  }
  handleFileUploadRequired() {
    this.props.updateFileUploadRequired(!this.props.exam.fileUploadRequired);
    this.props.updateFileUploadDescription("");
    this.setState({ fileUploadDescriptionError: false });
  }
  render() {
    const durationOptions = [
      { text: "", value: "" },
      { text: "0:30", value: 0.5 },
      { text: "1:00", value: 1 },
      { text: "1:30", value: 1.5 },
      { text: "2:00", value: 2 },
      { text: "2:30", value: 2.5 },
      { text: "3:00", value: 3 },
      { text: "3:30", value: 3.5 },
      { text: "4:00", value: 4 },
    ];
    const count = 5;
    return (
      <Container>
        <MainMenu />
        <Modal open={this.props.exam.openAddExam} className="modalHOTFIX">
          <Modal.Header>{this.props.t("Exam.addExam")}</Modal.Header>
          <Modal.Content>
            <Form>
              <Message
                visible={this.state.error}
                onDismiss={() => this.setState({ error: false })}
                header={this.props.t("Exam.errorAddingHeader")}
                content={this.props.t("general.missingFieldsErrorMessage")}
                error
              />
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-name"
                      error={this.state.nameError}
                      name="name"
                      value={this.props.exam.examName}
                      onChange={(e) => this.updateExamName(e.target.value)}
                      control={Input}
                      label={this.props.t("general.name")}
                      onBlur={(e) =>
                        e.target.value.length === 0
                          ? this.setState({ nameError: true })
                          : this.setState({ nameError: false })
                      }
                    />

                    <Form.Field
                      control="select"
                      onChange={(e) =>
                        this.props.updateDuration(e.target.value)
                      }
                      error={this.state.durationError}
                      label={this.props.t("general.duration")}
                      defaultValue={this.props.exam.duration}
                      onBlur={(e) =>
                        e.target.value.length === 0
                          ? this.setState({ durationError: true })
                          : this.setState({ durationError: false })
                      }
                    >
                      {durationOptions.map((data, i) => {
                        return (
                          <option key={i} value={data.value}>
                            {data.text}
                          </option>
                        );
                      })}
                    </Form.Field>

                    <Form.Field>
                      <label>
                        {this.props.t("general.learningInstitutions")}
                      </label>
                      <Dropdown
                        onChange={(e, data) =>
                          this.props.updateClient(data.value)
                        }
                        placeholder={this.props.t(
                          "general.learningInstitutions"
                        )}
                        fluid
                        multiple
                        selection
                        options={this.props.exam.clients}
                        defaultValue={this.props.exam.client}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>{this.props.t("Exam.daysAvailable")}</label>
                      <Dropdown
                        onChange={(e, data) =>
                          this.updateDaysAvailable(data.value)
                        }
                        placeholder={this.props.t("Exam.days")}
                        fluid
                        multiple
                        selection
                        options={[
                          {
                            key: "sunday",
                            text: this.props.t("general.sunday"),
                            value: "sunday",
                          },
                          {
                            key: "monday",
                            text: this.props.t("general.monday"),
                            value: "monday",
                          },
                          {
                            key: "tuesday",
                            text: this.props.t("general.tuesday"),
                            value: "tuesday",
                          },
                          {
                            key: "wednesday",
                            text: this.props.t("general.wednesday"),
                            value: "wednesday",
                          },
                          {
                            key: "thursday",
                            text: this.props.t("general.thursday"),
                            value: "thursday",
                          },
                          {
                            key: "friday",
                            text: this.props.t("general.friday"),
                            value: "friday",
                          },
                          {
                            key: "saturday",
                            text: this.props.t("general.saturday"),
                            value: "saturday",
                          },
                        ]}
                        defaultValue={this.props.exam.daysAvailable}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>{this.props.t("Exam.timesAvailable")}</label>
                      <Form.Group inline>
                        <label>{this.props.t("Exam.from")}:</label>
                        <DatePicker
                          selected={
                            this.props.exam.available_from_time === ""
                              ? null
                              : this.props.exam.available_from_time
                          }
                          onChange={(time) =>
                            this.handleAvailableFromTime(time)
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="h:mm a"
                          timeCaption={this.props.t("general.time")}
                          excludeTimes={this.state.excludedFromTimes}
                        />
                        <label>{this.props.t("Exam.to")}:</label>
                        <DatePicker
                          selected={
                            this.props.exam.available_to_time === ""
                              ? null
                              : this.props.exam.available_to_time
                          }
                          onChange={(time) => this.handleAvailableToTime(time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="h:mm a"
                          timeCaption={this.props.t("general.time")}
                          excludeTimes={this.state.excludedToTimes}
                        />
                      </Form.Group>
                    </Form.Field>

                    <Form.Field>
                      <label>{this.props.t("Exam.capacity")}</label>
                      <div className="ui input">
                        <input
                          value={this.props.exam.capacity}
                          onChange={(e) =>
                            this.props.updateCapacity(e.target.value)
                          }
                          type="number"
                          min="0"
                          max="15"
                          placeholder={`${this.props.t("Exam.capacity")}...`}
                        />
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="11">
                            <label>{this.props.t("Exam.cost")}</label>
                          </Grid.Column>
                          <Grid.Column width="5">
                            <Checkbox
                              onChange={() => {
                                this.props.updatePaymentRequired(
                                  !this.props.exam.requires_payment
                                );
                                this.props.updateCostDisabled(
                                  !this.props.exam.costDisabled
                                );
                                this.props.updateCost("");
                                this.setState({ costError: false });
                              }}
                              label={this.props.t("Exam.notApplicable")}
                              checked={!this.props.exam.requires_payment}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Form.Field
                              id="form-input-control-name"
                              error={this.state.costError}
                              name="name"
                              value={this.props.exam.cost}
                              onChange={(e) => this.updateCost(e.target.value)}
                              control={Input}
                              disabled={this.props.exam.costDisabled}
                              onBlur={(e) =>
                                e.target.value.length === 0
                                  ? this.setState({ costError: true })
                                  : this.setState({ costError: false })
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form.Field>
                    <Form.Field>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Checkbox
                              onChange={this.handleFileUploadRequired}
                              label={this.props.t("Exam.enableFileUpload")}
                              checked={this.props.exam.fileUploadRequired}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Form.Field
                              disabled={!this.props.exam.fileUploadRequired}
                              error={this.state.fileUploadDescriptionError}
                            >
                              <label>
                                {this.props.t("Exam.uploadDescription")}
                              </label>
                              <Input
                                id="form-input-control-name"
                                name="name"
                                value={this.props.exam.fileUploadDescription}
                                onChange={(e) =>
                                  this.updateFileUploadDescription(
                                    e.target.value
                                  )
                                }
                                control={Input}
                                onBlur={(e) =>
                                  e.target.value.length === 0
                                    ? this.setState({
                                        fileUploadDescriptionError: true,
                                      })
                                    : this.setState({
                                        fileUploadDescriptionError: false,
                                      })
                                }
                              ></Input>
                            </Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                  <Form.Field>
                      <label>{this.props.t("general.limit")}</label>
                      <input
                          value={this.props.exam.limit}
                          onChange={(e) =>
                            this.props.updateLimit(e.target.value)
                          }
                          type="number"
                          min="0"
                          max="15"
                          placeholder={`${this.props.t("Exam.limit")}...`}
                        />
                    </Form.Field>
                    <Form.Field>
                      <label>{this.props.t("general.limitNotice")}</label>
                      <textarea
                        onChange={(e) => this.updateLimitNotice(e.target.value)}
                        value={this.props.exam.limit_notice}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>{this.props.t("general.description")}</label>
                      <textarea
                        onChange={(e) => this.updateDescription(e.target.value)}
                        value={this.props.exam.description}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>{this.props.t("Exam.checkinScript")}</label>
                      <textarea
                        onChange={(e) => this.updateRules(e.target.value)}
                        value={this.props.exam.rules}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label> {this.props.t("Exam.studentRules")}</label>
                      <textarea
                        onChange={(e) =>
                          this.updateStudentRules(e.target.value)
                        }
                        value={this.props.exam.student_rules}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      onClick={() => this.props.updateOpenAddExam()}
                      color="red"
                      floated="right"
                    >
                      {this.props.t("general.cancel")}
                    </Button>
                    <Button
                      onClick={(e) => this.submitForm(e)}
                      color="green"
                      floated="right"
                    >
                      {this.props.t("general.save")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => this.handleAddExam()}
                floated="right"
                color="green"
              >
                {this.props.t("Exam.addExam")}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ExamTable />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      exam: state.exam,
    }),
    {
      updateExamName,
      updateDuration,
      updateRules,
      updateLimitNotice,
      updateStudentRules,
      saveExam,
      updateAvailableFrom,
      updateAvailableTo,
      setIsUpdating,
      updateExamCreator,
      updateCost,
      updateDescription,
      updatePaymentRequired,
      updateOpenAddExam,
      updateCostDisabled,
      fetchClients,
      updateClient,
      updateDaysAvailable,
      updateAvailableFromTime,
      updateAvailableToTime,
      updateCapacity,
      updateLimit,
      updateFileUploadDescription,
      updateFileUploadRequired,
    }
  )(Exam)
);
