import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Message } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import axios from "axios/index";
import { connect } from "react-redux";
import { Progress } from "semantic-ui-react";
import { updateProfileImage } from "../reducers/accountDetails";
import { withTranslation } from "react-i18next";

const Preview = (props) =>
  props.error ? (
    <div style={{ textAlign: "center", marginTop: "86px", color: "red" }}>
      {props.error}
    </div>
  ) : props.file ? (
    <div>
      <img style={{ maxWidth: "100%" }} src={props.file.preview} />
    </div>
  ) : (
    <div style={{ textAlign: "center", marginTop: "86px" }}>{props.text}</div>
  );

class PictureUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: null, error: null, uploadPercentage: 0 };
    this.text = props.text
      ? props.text
      : this.props.t("PictureUploader.clickToUpload");
  }

  componentDidMount = () => {
    let image = new Image();

    image.addEventListener("error", (error) => {
      console.log(error);
    });

    image.addEventListener("load", () => {
      this.setState({
        file: {
          preview: image.src,
        },
      });
    });

    image.src =
      `${process.env.REACT_APP_API_ADDRESS}/api/images/profile/` +
      `${this.props.kc.tokenParsed.sub}_user_profile_img.png?token=${
        this.props.kc.token
      }&cb=${Math.round(new Date().getTime() / 1000)})`;
  };

  onDrop = (files, rejected) => {
    if (files[0]) {
      let image = new Image();
      let file = files[0];

      this.setState({ error: null });

      image.addEventListener("load", () => {
        this.setState({
          file: file,
        });

        const data = new FormData();
        data.append("photo", file);
        data.append("user_id", this.props.kc.tokenParsed.sub);
        axios
          .post(process.env.REACT_APP_API_ADDRESS + "/api/upload", data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.setState({ uploadPercentage: 100 }, () => {
              setTimeout(() => {
                this.setState({ uploadPercentage: 0 });
              }, 1000);
            });
            this.props.updateProfileImage(true);
          })
          .catch((error) => {
            console.log("error: ", error);
          });
      });

      image.src = window.URL.createObjectURL(file);
    } else {
      let error = "";
      if (rejected[0].size > 2097152) {
        error = this.props.t("PictureUploader.fileTooLargeError");
      } else {
        error = this.props.t("PictureUploader.incorrectFileTypeError");
      }
      this.setState({ error: error });
    }
  };

  render() {
    const { uploadPercentage } = this.state;
    return (
      <span>
        <Message info>
          <Message.Header>
            {this.props.t("general.fileUploader")}
          </Message.Header>
          <p>{this.props.t("PictureUploader.text")}</p>
        </Message>
        <Dropzone
          maxSize={5242880}
          style={{
            maxWidth: "100%",
            minHeight: "200px",
            borderWidth: "2px",
            borderColor: "rgb(102, 102, 102)",
            borderStyle: "dashed",
            borderRadius: "5px",
          }}
          accept="image/jpeg, image/png"
          onDrop={this.onDrop.bind(this)}
        >
          <Preview
            file={this.state.file}
            text={this.text}
            error={this.state.error}
          />
          {uploadPercentage > 0 && (
            <Progress percent={uploadPercentage} indicating />
          )}
        </Dropzone>
      </span>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { updateProfileImage }
  )(PictureUploader)
);
