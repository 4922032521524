import React, { Component } from "react";
import { connect } from "react-redux";
import InstitutionsTable from "../components/InstitutionsTable";
import BlockedDays from "../components/BlockedDays";
import { Grid, Container, Menu, Header } from "semantic-ui-react";
import Coupons from "./Coupons";
import MainMenu from "./../components/MainMenu";
import { getBlockedDays } from "./../reducers/booking";
import { withTranslation } from "react-i18next";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "learning_institutions",
    };
  }
  componentDidMount() {
    this.props.getBlockedDays();
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  render() {
    const { activeItem } = this.state;
    return (
      <Container>
        <MainMenu />
        <Header style={{ textAlign: "center", fontSize: "26px" }}>
          {this.props.t("general.settings")}
        </Header>
        <Grid style={{ border: "1px solid #efefef" }}>
          <Grid.Row>
            <Menu secondary>
              <Menu.Item
                name="learning_institutions"
                active={activeItem === "learning_institutions"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="blocked_days"
                active={activeItem === "blocked_days"}
                onClick={this.handleItemClick}
              />
            </Menu>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {this.state.activeItem === "learning_institutions" ? (
                <InstitutionsTable />
              ) : (
                <BlockedDays />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Coupons />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { getBlockedDays }
  )(Settings)
);
