import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Transition, Message} from 'semantic-ui-react'
import {dismissNotification} from './../reducers/notification';
import {NavLink} from 'react-router-dom'


class Notification extends Component{
    constructor(props){
        super(props);
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    handleDismiss(key){
        this.props.dismissNotification(key);
    }
    render(){
        return(
            <div style={{position:'fixed',zIndex:100,top:65,right:15}}>
                <Transition.Group animation="drop" duration={500}>
                    {this.props.notification.notifications.map((data,i) => {
                        if(data.timeout && data.visible){
                            setTimeout(() => {
                                this.props.dismissNotification(data.id);
                            },5000);
                        }
                        return (data.visible && <Message key={i}
                             {...(data.type ? {[data.type] : true} : {info: true})}
                            onDismiss={this.handleDismiss.bind(this,data.id)}
                            visible={false}
                            header={data.title}
                            onClick={data.callback} content={data.link ? (<NavLink onClick={() => this.handleDismiss(data.id)} to={data.link}>{data.message}</NavLink>) : data.message}
                            />);
                    })}

                </Transition.Group>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        notification: state.notification
    }),
    {dismissNotification}
)(Notification);