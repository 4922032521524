const initState = {
  open: false,
};

const UPDATE_OPEN = "UPDATE_OPEN";

export const updateOpen = (val) => ({ type: UPDATE_OPEN, payload: val });

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_OPEN:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
