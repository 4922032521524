import ReactDOMServer from 'react-dom/server';
import axios from "axios/index";


/**
 * Send An email.
 * @param email
 * @returns {Promise<AxiosResponse>}
 */
export const sendEmail = (email) => {
    return axios.post(process.env.REACT_APP_API_ADDRESS+'/api/send',{
        from: 'noreply@eproctorcanada.com', // sender address
        to:email.to,
        subject:email.subject,
        cc: (email.cc === undefined) ? '' : email.cc,
        bcc: (email.bcc === undefined) ? '' : email.bcc,
        html:ReactDOMServer.renderToStaticMarkup(email.component)
    },{
        headers: {
            'Accept':'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.data);
};