import axios from "axios";

/**
 * Create a User.
 * @param user
 * @returns {AxiosPromise}
 */
export const createOrUpdateUser = (user) => {
  return axios
    .post(
      process.env.REACT_APP_API_ADDRESS + "/api/users",
      {
        user_id: user.user_id,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        learning_institution:
          user.learning_institution !== undefined
            ? user.learning_institution
            : null,
        dob: user.dob,
        phone: user.phone,
        province: user.province !== undefined ? user.province : null,
        timezone: user.timezone !== undefined ? user.timezone : null,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => console.log(err));
};

export const getAllUsers = () =>
  axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/users`)
    .then((res) => res.data)
    .catch((e) => console.log(e));

export const getUser = (user_id) =>
  axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/users/${user_id}`)
    .then((res) => res.data)
    .catch((e) => console.log("getUser error: ", e));
