import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container, Grid, Card, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class Dashboard extends Component {
  render() {
    return (
      <Container style={{ marginTop: "3em" }} className="card-container">
        <Grid columns={3} stackable>
          <Grid.Row centered>
            <Grid.Column>
              <Grid celled="internally">
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-view-exam") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/scheduled-exams"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("general.scheduledExams")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="calendar" size="huge" />
                        <p>{this.props.t("Dashboard.scheduledAdminLabel")}</p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-view-pending-requests") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/pending-requests"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("general.pendingRequests")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="checked calendar" size="huge" />
                        <p>
                          {this.props.t("Dashboard.pendingRequestsAdminlabel")}
                        </p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-modify-exam") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/exam"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("general.exams")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="university" size="huge" />
                        <p>{this.props.t("Dashboard.examsAdminLabel")}</p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3} stackable>
          <Grid.Row centered>
            <Grid.Column>
              <Grid>
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-view-learner-details") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/learner-details"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("general.learners")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="graduation" size="huge" />
                        <p>{this.props.t("Dashboard.learnersAdminLabel")}</p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-view-learner-details") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/schedule"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("Dashboard.scheduleExams")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="clock" size="huge" />
                        <p>{this.props.t("Dashboard.rescheduleAdminLabel")}</p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row centered>
                  {this.props.kc.hasRealmRole("can-modify-settings") && (
                    <Card
                      style={{ minHeight: 200, color: "black" }}
                      as={NavLink}
                      to="/settings"
                    >
                      <Card.Content className="card-header">
                        <Card.Header>
                          {this.props.t("general.settings")}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>
                        <Icon name="settings" size="huge" />
                        <p>{this.props.t("Dashboard.settingsAdminLabel")}</p>
                      </Card.Content>
                    </Card>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    {}
  )(Dashboard)
);
