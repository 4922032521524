import axios from "axios";
/**
 * Get all scheduled Exams.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getScheduledBookings = (user_id) => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/bookings/" + user_id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(error));
};

/**
 * Get all past Exams.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getPastBookings = (user_id) => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/bookings/past/" + user_id)
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(error));
};

export const getAllBookingsByUserId = (uuid) =>
  axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/recordings/${uuid}`)
    .then((res) => res.data)
    .catch();

/**
 * Get all future Exams.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getFutureBookings = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/bookings/future")
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(error));
};

/**
 * Create a blocked day
 * @returns {Promise<AxiosResponse | void>}
 */
export const createBlockedDay = (blockedDay) => {
  return axios.post(
    process.env.REACT_APP_API_ADDRESS + "/api/bookings/block",
    blockedDay,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};
/**
 * Get all approved Bookings.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getApprovedBookings = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/bookings/approved")
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(error));
};

export const getBookings = async (status, only_future) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_ADDRESS +
        `/api/bookings?status=${status}&only_future=${only_future}`
    );
    return res.data;
  } catch (error) {
    return console.log(error);
  }
};

export const getAllBookings = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/bookings")
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Fetch all blocked Days
 * @returns {Promise<AxiosResponse | void>}
 */
export const fetchBlockedDays = () => {
  return axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/bookings/block`)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * Delete a blocked day
 * @param {*} id
 * @returns {Promise<AxiosResponse | void>}
 */
export const deleteBlock = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_ADDRESS}/api/bookings/block/${id}`
  );
};

/**
 * Take exam and return all blocks for that exam
 * @param {*} exam
 * @returns {Promise<AxiosResponse | void>}
 */
export const fetchBlockedDaysInMonth = (exam) => {
  return axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/bookings/block?exam=${exam}`)
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * Provided a booking ID return the information around it.
 * @param booking_id
 * @returns {Promise<AxiosResponse | void>}
 */
export const getBookingByBookingId = (booking_id) => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/booking/" + booking_id)
    .then((booking) => {
      return axios
        .get(
          process.env.REACT_APP_API_ADDRESS +
            "/api/user/" +
            booking.data.user_id
        )
        .then((user) => {
          user.data.attributes.profile_image = user.data.attributes
            .profile_image
            ? JSON.parse(user.data.attributes.profile_image)
            : null;
          return { ...booking.data, ...user.data };
        });
    })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
};

export const updateBookingApproved = (booking) => {
  return axios
    .post(
      process.env.REACT_APP_API_ADDRESS + "/api/bookings/updateApproved",
      {
        id: booking.id,
        approved: booking.approved,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) =>
      axios
        .get(
          process.env.REACT_APP_API_ADDRESS +
            "/api/user/" +
            response.data.status.user_id
        )
        .then((user) => ({ ...user.data, ...response.data }))
    )
    .catch((error) => console.log(error));
};

export const reschedule = async (booking) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_ADDRESS + "/api/bookings/reschedule",
      {
        id: booking.id,
        booking_date: booking.examDate,
        booking_time: booking.examTime,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const user = await axios.get(
      process.env.REACT_APP_API_ADDRESS + "/api/user/" + booking.user_id
    );

    return { userData: user.data, booking: booking, response: response };
  } catch (e) {
    return e;
  }
};

export const updateExistingBooking = (booking) => {
  return axios
    .post(
      process.env.REACT_APP_API_ADDRESS + "/api/bookings/updateExisting",
      {
        id: booking.id,
        booking_date: booking.examDate,
        booking_time: booking.examTime,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) =>
      axios
        .get(process.env.REACT_APP_API_ADDRESS + "/api/user/" + booking.user_id)
        .then((user) => ({ ...user.data, ...response.data.data }))
    )
    .catch((error) => console.log(error));
};

/**
 * Delete a specified Exam
 * @param id
 * @returns {Promise<AxiosResponse | void>}
 */
export const deleteExam = (id) => {
  return axios
    .delete(process.env.REACT_APP_API_ADDRESS + "/api/booking/delete/" + id)
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

export const cancel = async (id) => {
  try {
    const res = axios.post(
      process.env.REACT_APP_API_ADDRESS + `/api/booking/cancel`,
      { id },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (e) {
    return false;
  }
};

/**
 * Create a new Booking
 * @param exam
 * @returns {AxiosPromise}
 */
export const createBooking = (booking) => {
  return axios.post(
    process.env.REACT_APP_API_ADDRESS + "/api/bookings",
    booking,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * update existing blocked Day
 * @returns {Promise<AxiosResponse | void>}
 */
export const updateExisting = (blockedDay) => {
  return axios.post(
    process.env.REACT_APP_API_ADDRESS + "/api/bookings/updateBlockedDay",
    {
      id: blockedDay.id,
      name: blockedDay.name,
      date: blockedDay.date,
      year: blockedDay.year,
      every_year: blockedDay.everyYear,
      exams: blockedDay.exams,
      all_day: blockedDay.allDay,
      from_time: blockedDay.fromTime,
      to_time: blockedDay.toTime,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};
