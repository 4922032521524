import {
  createExam,
  getExams,
  deleteExam,
  updateExam,
  getClients,
  getBookingsForDate,
  getExam,
  getBookingsForUser,
  getNextAvailableBooking,
} from "../lib/examServices";
import { sendEmail } from "../lib/emailService";

const initState = {
  examName: "",
  duration: "",
  limit_notice: "",
  rules: "",
  student_rules: "",
  available_from: "",
  available_to: "",
  requires_payment: false,
  cost: "",
  capacity: 1,
  limit: 0,
  client: "",
  description: "",
  allExams: [],
  startDate: "",
  open: false,
  examToDelete: {},
  examToUpdate: null,
  isUpdating: false,
  openAddExam: false,
  costDisabled: false,
  clients: [],
  daysAvailable: [],
  available_from_time: "",
  available_to_time: "",
  exam: {},
  fileUploadDescription: "",
  fileUploadRequired: false,
};

const UPDATE_EXAM_NAME = "UPDATE_EXAM_NAME";
const UPDATE_DURATION = "UPDATE_DURATION";
const LOAD_EXAMS = "LOAD_EXAMS";
const UPDATE_AVAILABLE_FROM = "UPDATE_AVAILABLE_FROM";
const UPDATE_AVAILABLE_TO = "UPDATE_AVAILABLE_TO";
const CHANGE_MODAL = "CHANGE_MODAL";
const UPDATE_EXAM_TO_DELETE = "UPDATE_EXAM_TO_DELETE";
const UPDATE_EXAM_TO_UPDATE = "UPDATE_EXAM_TO_UPDATE";
const SET_IS_UPDATING = "SET_IS_UPDATING";
const UPDATE_COST = "UPDATE_COST";
const UPDATE_LIMIT_NOTICE = "UPDATE_LIMIT_NOTICE";
const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION";
const UPDATE_RULES = "UPDATE_RULES";
const UPDATE_STUDENT_RULES = "UPDATE_STUDENT_RULES";
const UPDATE_END_DATE = "UPDATE_END_DATE";
const UPDATE_OPEN_ADD_EXAM = "UPDATE_OPEN_ADD_EXAM";
const UPDATE_REQUIRES_PAYMENT = "UPDATE_REQUIRES_PAYMENT";
const UPDATE_COST_DISABLED = "UPDATE_COST_DISABLED";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const LOAD_CLIENTS = "LOAD_CLIENTS";
const UPDATE_DAYS_AVAILABLE = "UPDATE_DAYS_AVAILABLE";
const UPDATE_AVAILABLE_FROM_TIME = "UPDATE_AVAILABLE_FROM_TIME";
const UPDATE_AVAILABLE_TO_TIME = "UPDATE_AVAILABLE_TO_TIME";
const UPDATE_CAPACITY = "UPDATE_CAPACITY";
const UPDATE_LIMIT = "UPDATE_LIMIT";
const LOAD_EXAM = "LOAD_EXAM";
const UPDATE_FILE_UPLOAD_DESCRIPTION = "UPDATE_FILE_UPLOAD_DESCRIPTION";
const UPDATE_FILE_UPLOAD_REQUIRED = "UPDATE_FILE_UPLOAD_REQUIRED";

export const updateExamName = (val) => ({
  type: UPDATE_EXAM_NAME,
  payload: val,
});
export const updateDuration = (val) => ({
  type: UPDATE_DURATION,
  payload: val,
});
export const loadExams = (exams) => ({ type: LOAD_EXAMS, payload: exams });
export const loadExam = (exam) => ({ type: LOAD_EXAM, payload: exam });
export const updateAvailableFrom = (val) => ({
  type: UPDATE_AVAILABLE_FROM,
  payload: val,
});
export const updateAvailableTo = (val) => ({
  type: UPDATE_AVAILABLE_TO,
  payload: val,
});
export const changeModal = (val) => ({ type: CHANGE_MODAL, payload: val });
export const updateExamToDelete = (val) => ({
  type: UPDATE_EXAM_TO_DELETE,
  payload: val,
});
export const updateExamToUpdate = (val) => ({
  type: UPDATE_EXAM_TO_UPDATE,
  payload: val,
});
export const setIsUpdating = (val) => ({ type: SET_IS_UPDATING, payload: val });
export const updateCost = (cost) => ({ type: UPDATE_COST, payload: cost });
export const updateDescription = (description) => ({
  type: UPDATE_DESCRIPTION,
  payload: description,
});
export const updateLimitNotice = (limit_notice) => ({
  type: UPDATE_LIMIT_NOTICE,
  payload: limit_notice,
});
export const updateRules = (rules) => ({ type: UPDATE_RULES, payload: rules });
export const updateStudentRules = (student_rules) => ({
  type: UPDATE_STUDENT_RULES,
  payload: student_rules,
});
export const updateEndDate = (endDate) => ({
  type: UPDATE_END_DATE,
  payload: endDate,
});
export const updatePaymentRequired = (payment_required) => ({
  type: UPDATE_REQUIRES_PAYMENT,
  payload: payment_required,
});
export const updateOpenAddExam = () => ({ type: UPDATE_OPEN_ADD_EXAM });
export const updateCostDisabled = (value) => ({
  type: UPDATE_COST_DISABLED,
  payload: value,
});
export const loadClients = (clients) => ({
  type: LOAD_CLIENTS,
  payload: clients,
});
export const updateClient = (client_id) => ({
  type: UPDATE_CLIENT,
  payload: client_id,
});
export const updateDaysAvailable = (day) => ({
  type: UPDATE_DAYS_AVAILABLE,
  payload: day,
});
export const updateAvailableFromTime = (time) => ({
  type: UPDATE_AVAILABLE_FROM_TIME,
  payload: time,
});
export const updateAvailableToTime = (time) => ({
  type: UPDATE_AVAILABLE_TO_TIME,
  payload: time,
});
export const updateCapacity = (capacity) => ({
  type: UPDATE_CAPACITY,
  payload: capacity,
});
export const updateLimit = (limit) => ({ type: UPDATE_LIMIT, payload: limit });
export const updateFileUploadDescription = (payload) => ({
  type: UPDATE_FILE_UPLOAD_DESCRIPTION,
  payload,
});
export const updateFileUploadRequired = (payload) => ({
  type: UPDATE_FILE_UPLOAD_REQUIRED,
  payload,
});

export const sendMail = (email) => {
  return (dispatch) => {
    sendEmail(email);
  };
};

export const saveExam = (exam) => {
  return (dispatch) => {
    createExam(exam).then((response) => {
      dispatch(fetchExams(response));
    });
  };
};

export const deleteExamCreator = (exam) => {
  return (dispatch) => {
    deleteExam(exam.id).then((exams) => dispatch(fetchExams()));
  };
};

export const fetchClients = () => {
  return (dispatch) => {
    getClients().then((clients) => dispatch(loadClients(clients)));
  };
};

export const updateExamCreator = (exam) => {
  return (dispatch) => {
    updateExam(exam).then((exams) => dispatch(fetchExams()));
  };
};

export const fetchExams = () => {
  return (dispatch) => {
    getExams().then((exams) => dispatch(loadExams(exams)));
  };
};

export const fetchExam = (room, user) => {
  return (dispatch) => {
    getExam(room, user).then((exam) => dispatch(loadExam(exam)));
  };
};

export const fetchBookingTimesForDate = (exam, date) => (dispatch) => {
  // Function is expected to return a promise
  return getBookingsForDate(exam, date).then((times) => {
    return times;
  });
};

export const fetchBookingsForUser = (exam, user) => (dispatch) => {
  // Function is expected to return a promise
  return getBookingsForUser(exam, user).then((bookings) => {
    return bookings;
  });
};

export const fetchNextAvailableBooking = (exam, user) => async (dispatch) => {
  // Function is expected to return a promise
  const booking = await getNextAvailableBooking(exam, user);
  return booking;
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_EXAM_NAME:
      return { ...state, examName: action.payload };
    case UPDATE_DURATION:
      return { ...state, duration: action.payload };
    case UPDATE_COST:
      return { ...state, cost: action.payload };
    case UPDATE_DESCRIPTION:
      return { ...state, description: action.payload };
    case UPDATE_FILE_UPLOAD_DESCRIPTION:
      return { ...state, fileUploadDescription: action.payload };
    case UPDATE_FILE_UPLOAD_REQUIRED:
      return { ...state, fileUploadRequired: action.payload };
    case UPDATE_LIMIT_NOTICE:
      return { ...state, limit_notice: action.payload };
    case UPDATE_RULES:
      return { ...state, rules: action.payload };
    case UPDATE_STUDENT_RULES:
      return { ...state, student_rules: action.payload };
    case UPDATE_REQUIRES_PAYMENT:
      return { ...state, requires_payment: action.payload };
    case LOAD_EXAMS:
      return { ...state, allExams: action.payload };
    case UPDATE_AVAILABLE_FROM:
      let available_from = action.payload.split("T");
      return { ...state, startDate: available_from[0] };
    case UPDATE_AVAILABLE_TO:
      let available_to = action.payload.split("T");
      return { ...state, startDate: available_to[0] };
    case UPDATE_END_DATE:
      let end_date = action.payload.split("T");
      return { ...state, startDate: end_date[0] };
    case CHANGE_MODAL:
      return { ...state, open: !state.open };
    case UPDATE_EXAM_TO_DELETE:
      return { ...state, examToDelete: action.payload };
    case UPDATE_EXAM_TO_UPDATE:
      return { ...state, examToUpdate: action.payload };
    case SET_IS_UPDATING:
      return { ...state, isUpdating: action.payload };
    case UPDATE_OPEN_ADD_EXAM:
      return { ...state, openAddExam: !state.openAddExam };
    case UPDATE_COST_DISABLED:
      return { ...state, costDisabled: action.payload };
    case LOAD_CLIENTS:
      let clients = action.payload.map((data) => {
        return {
          text: data.organization_name,
          value: data.id,
          online: data.online,
        };
      });
      return { ...state, clients: clients };
    case UPDATE_CLIENT:
      return { ...state, client: action.payload };
    case UPDATE_DAYS_AVAILABLE:
      return { ...state, daysAvailable: action.payload };
    case UPDATE_AVAILABLE_FROM_TIME:
      return { ...state, available_from_time: action.payload };
    case UPDATE_AVAILABLE_TO_TIME:
      return { ...state, available_to_time: action.payload };
    case UPDATE_CAPACITY:
      if (
        !isNaN(action.payload) &&
        action.payload > 0 &&
        action.payload <= 15
      ) {
        return { ...state, capacity: action.payload };
      } else {
        return { ...state };
      }
    case UPDATE_LIMIT:
      if (
        !isNaN(action.payload) &&
        action.payload >= 0 &&
        action.payload <= 15
      ) {
        return { ...state, limit: action.payload };
      } else {
        return { ...state };
      }
    case LOAD_EXAM:
      return { ...state, exam: action.payload };
    default:
      return state;
  }
};
