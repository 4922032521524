import { combineReducers} from 'redux';
import chatReducer from './reducers/chat';
import clientReducer from './reducers/clients';
import sessionReducer from './reducers/session';
import examReducer from './reducers/exam';
import keycloakReducer from './reducers/keycloak';
import vodReducer from './reducers/vods';
import accountDetails from './reducers/accountDetails';
import couponsReducer from './reducers/coupons';
import notificationReducer from './reducers/notification';
import bookingsReducer from './reducers/booking';
import learnersReducer from './reducers/learners';
import roomReducer from './reducers/room';


const reducer = combineReducers({
    chat: chatReducer,
    clients: clientReducer,
    coupons: couponsReducer,
    session: sessionReducer,
    exam: examReducer,
    bookings: bookingsReducer,
    vods: vodReducer,
    keycloak: keycloakReducer,
    accountDetails: accountDetails,
    notification:notificationReducer,
    learners: learnersReducer,
    rooms: roomReducer
});

export default reducer;

