import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import WebCamUser from "../components/WebCamUser";
import CurrentUserDetails from "../components/CurrentUserDetails";
import Chat from "../components/Chat";
import NoLearners from "../components/NoLearners";
import PushUrlModal from "../components/PushUrlModal";
import AllPushUrlModal from "../components/AllPushUrlModal";
import { connect } from "react-redux";
import {
  addComment,
  updateNewMessage,
  clearComments,
  clearCommentsForUser,
  addChatBox,
  updateChatBoxes,
  clearChatBoxes,
  updateTyping
} from "../reducers/chat";
import {
  addSession,
  updateExamName,
  updateExamCapacity,
  banUser,
  softBootUser,
  saveSession,
  updateSessions,
  setActiveChat,
  saveEvent,
  updateEvents,
  updateCurrentExam,
  updateSocketForUrlPush,
  updateAllPushModal,
  updatePushModal,
  updateZoomDesktop,
  updateZoomSRC,
  setMaximizedUser,
  clearSessions,
  updateMessage,
  updateShowMessage,
  updateFaceToFaceConnected,
} from "../reducers/session";

import { Card, Grid, Modal, Menu, Popup, Label } from "semantic-ui-react";
import { fetchExams } from "../reducers/exam";
import ExpandedDesktop from "../components/ExpandedDesktop";
import socketUtil from "./../util/socket.js";
import * as Sentry from "@sentry/react";
import Slider from "./../components/Slider";
import moment from "moment";
import { withTranslation } from "react-i18next";

const mediasoup = require("mediasoup-client");

const MEDIA_TYPE_VIDEO = "video";
const MEDIA_TYPE_AUDIO = "audio";
const MEDIA_TYPE_DESKTOP = "desktop";

class ProctoringSession extends Component {
  constructor(props) {
    super(props);
    this.room_id = this.props.match.params.room_id;
    this.onMaximize = this.onMaximize.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.exitZoom = this.exitZoom.bind(this);
    this.onMinimize = this.onMinimize.bind(this);
    this.onBan = this.onBan.bind(this);
    this.onSoftBoot = this.onSoftBoot.bind(this);
    this.onSwapViews = this.onSwapViews.bind(this);
    this.onFlag = this.onFlag.bind(this);
    this.updateExamCapacity = this.updateExamCapacity.bind(this);
    this.updateExamName = this.updateExamName.bind(this);
    this.urlModal = this.urlModal.bind(this);
    this.allUrlModal = this.allUrlModal.bind(this);
    this.pushUrl = this.pushUrl.bind(this);
    this.allPushUrl = this.allPushUrl.bind(this);
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.handleLeaveExam = this.handleLeaveExam.bind(this);
    this.addUserToSession = this.addUserToSession.bind(this);
    this.startVideoCall = this.startVideoCall.bind(this);
    this.handleAssistanceRequest = this.handleAssistanceRequest.bind(this);
    this.handleUserTyping = this.handleUserTyping.bind(this);
    this.stopVideoCall = this.stopVideoCall.bind(this);
    this.updateMaxUserCycleTime = this.updateMaxUserCycleTime.bind(this);
    this.handleWindowBeforeUnload = this.handleWindowBeforeUnload.bind(this);
    this.handleProctorLeft = this.handleProctorLeft.bind(this);

    this.state = {
      consumerTransports: [],
      producerTransports: [],
      sessions: [],
      maximizedIndex: 0,
      maxCycleInterval: null,
      maxUserCycleTime: 0,
      usertyping: false,
    };
  }

  async startVideoCall(learner_id) {
    if (this.state.producerTransports.length === 0) {
      await this._publish(MEDIA_TYPE_AUDIO, learner_id);
      await this._publish(MEDIA_TYPE_VIDEO, learner_id);

      this.props.socket.request("startFaceToFace", {
        user_id: this.props.kc.tokenParsed.sub,
        learner_id,
        room: this.room_id,
      });
    } else {
      this.props.socket.request("startFaceToFace", {
        user_id: this.props.kc.tokenParsed.sub,
        learner_id,
        room: this.room_id,
      });
    }
    this.props.updateFaceToFaceConnected(true);
    let sel = document.querySelector("#selected-user");
    sel.classList.add( "incoming" );
  }

  async stopVideoCall(user_id) {
    this.props.socket.request("stopFaceToFace", {
      room: this.room_id,
      user_id,
    });
    this.props.updateMessage(
      this.props.t("ProctoringSession.faceToFaceDisconnected")
    );
    this.props.updateShowMessage(true);
    this.props.updateFaceToFaceConnected(false);
    let sel = document.querySelector("#selected-user");
    sel.classList.remove( "incoming" );
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    this.props.fetchExams();
    this.props.clearSessions();
    await this.props.clearChatBoxes();

    this.props.socket.request = socketUtil.promise(this.props.socket);
    const data = await this.props.socket.request("getRouterRtpCapabilities", {
      room: this.room_id,
      user_id: this.props.kc.tokenParsed.sub,
      role: "proctor",
      username: this.props.kc.tokenParsed.preferred_username,
      first_name: this.props.kc.tokenParsed.given_name,
      last_name: this.props.kc.tokenParsed.family_name,
    });

    const learners = await this.props.socket.request("joinExam", {
      role: "proctor",
      username: this.props.kc.tokenParsed.preferred_username,
      user_id: this.props.kc.tokenParsed.sub,
      room_id: this.room_id,
    });

    await this._loadDevice(data);

    for (let learner of learners) {
      learner.webcamAudioStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_AUDIO
      );
      learner.webcamVideoStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_VIDEO
      );
      learner.desktopStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_DESKTOP
      );
      await this.addUserToSession(learner);
    }

    this.props.socket.on("userRequestedAssistance", (socket_id) => {
      this.handleAssistanceRequest(socket_id);
    });

    this.props.socket.on("usertyping", (data) => {
      this.handleUserTyping(data);
    });

    this.props.socket.on("proctorJoined", (examName) => {
      this.props.updateCurrentExam(examName);
    });

    this.props.socket.on("userRejoined", async (learner) => {
      learner.webcamAudioStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_AUDIO
      );
      learner.webcamVideoStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_VIDEO
      );
      learner.desktopStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_DESKTOP
      );
      const sessions = this.props.sessions.filter(
        (session) => session.user_id !== learner.id
      );
      this.props.updateSessions(sessions);
      await this.addUserToSession(learner);

      let session = this.props.sessions.find(
        (session) => session.id === learner.socket_id
      );
      let event = `${learner.username} Rejoined Session`;
      this.updateLog(event, session);
    });

    this.props.socket.on("userJoined", async (learner) => {
      this.props.clearCommentsForUser(learner.username);

      learner.webcamAudioStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_AUDIO
      );
      learner.webcamVideoStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_VIDEO
      );
      learner.desktopStream = await this._subscribe(
        learner.id,
        MEDIA_TYPE_DESKTOP
      );

      await this.addUserToSession(learner);

      let session = this.props.sessions.find(
        (session) => session.user_id === learner.id
      );
      let event =
        learner.username + this.props.t("ProctoringSession.joinedSession");
      this.updateLog(event, session);
    });

    this.props.socket.on("userLeft", (data) => {
      const session = this.props.sessions.find(
        (session) => session.user_id === data.user_id
      );
      if (session) {
        const event = `${session.username} ${data.status}`;
        this.updateLog(event, session);
        const sessions = this.props.sessions.map((session) => {
          if (session.user_id === data.user_id) {
            session.render = false;
          }
          return session;
        });
        this.props.updateSessions(sessions);
      }

      const chatBoxes = this.props.chatBoxes.filter(
        (chatBox) => chatBox.user_id !== data.user_id
      );
      this.props.updateChatBoxes(chatBoxes);

      //closing consumer transports for this user
      const consumerTransports = this.state.consumerTransports.find(
        t => t.user_id === data.user_id
      );
      for (let transport of consumerTransports.transports) {
        transport.close();
      }
    });

    this.props.socket.on("userDisconnected", (data) => {
      const session = this.props.sessions.find(
        (session) => session.user_id === data.user_id
      );
      if (session) {
        const event = `${session.username} ${this.props.t(
          "ProctoringSession.lostConnection"
        )}`;
        this.updateLog(event, session);
        const sessions = this.props.sessions.map((session) => {
          if (session.user_id === data.user_id) {
            session.disconnected = true;
          }
          return session;
        });
        this.props.updateSessions(sessions);
      }

      const chatBoxes = this.props.chatBoxes.filter(
        (chatBox) => chatBox.user_id !== data.user_id
      );
      this.props.updateChatBoxes(chatBoxes);

      //closing consumer transports for this user
      const consumerTransports = this.state.consumerTransports.find(
        t => t.user_id === data.user_id
      );
      for (let transport of consumerTransports.transports) {
        transport.close();
      }
    });

    this.props.socket.on("message", (msg) => {
      this.props.addComment({
        room: msg.room,
        text: msg.text,
        timestamp: moment.now(),
        author: `${msg.first_name} ${msg.last_name}`,
        user_id: msg.from,
      });
      this.onMessageReceived(msg.from);
      this.props.updateNewMessage(true);
    });

    this.props.socket.on("stopFaceToFace", () => {
      this.props.updateMessage(
        this.props.t("ProctoringSession.faceToFaceDisconnected")
      );
      this.props.updateShowMessage(true);
      this.props.updateFaceToFaceConnected(false);
      let sel = document.querySelector("#selected-user");
      sel.classList.remove( "incoming" );
    });

    this.props.socket.on("faceToFaceConnected", () => {
      this.props.updateMessage(
        this.props.t("ProctoringSession.faceToFaceConnected")
      );
      this.props.updateShowMessage(true);
      this.props.updateFaceToFaceConnected(true);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.maxUserCycleTime !== this.state.maxUserCycleTime) {
      clearInterval(this.state.maxCycleInterval);
      if (this.props.sessions.length > 1 && this.state.maxUserCycleTime != 0) {
        const maxCycleInterval = setInterval(() => {
          //if at the last index reset back to 0
          const maximizedIndex =
            this.state.maximizedIndex + 1 === this.props.sessions.length
              ? 0
              : this.state.maximizedIndex + 1;
          const learner = this.props.sessions[maximizedIndex];
          if (learner) {
            this.props.setMaximizedUser({
              username: learner.username,
              user_id: learner.user_id,
            });
            this.setState({ maximizedIndex });
          }
        }, this.state.maxUserCycleTime * 1000);

        this.setState({ maxCycleInterval });
      }
    }
  }

  handleAssistanceRequest(socket_id) {
    const chatBoxes = this.props.chatBoxes.filter((d) => {
      if (socket_id === d.socket_id) {
        d.assistance_requested = true;
      }
      return d;
    });
    this.props.updateChatBoxes(chatBoxes);
  }

  handleUserTyping(data) {
    if(data.is_typing){
      this.setState({ usertyping: true });
    }else{
      this.setState({ usertyping: false });
    }
    const chatBoxes = this.props.chatBoxes.filter((d) => {
      if (data.socket_id === d.socket_id) {
        d.typing = this.state.usertyping;
      }
      return d;
    });
    this.props.updateChatBoxes(chatBoxes);
  }

  addUserToSession(learner) {
    return new Promise((resolve, reject) => {
      if (this.props.sessions.length === 0) {
        this.props.setMaximizedUser({
          username: learner.username,
          user_id: learner.id,
        });
      }

      this.props.addSession({
        ip: learner.ip,
        id: learner.socket_id,
        socket_id: learner.socket_id,
        username: learner.username,
        user_id: learner.id,
        selected: this.props.sessions.length === 0,
        booking_id: learner.booking_id,
        webcamAudioStream: learner.webcamAudioStream,
        webcamVideoStream: learner.webcamVideoStream,
        desktopStream: learner.desktopStream,
        disconnected: false,
        joinedExamCount: learner.joinedExamCount,
      });

      const comments = learner.messages.map((message) => {
        return {
          room: message.room,
          text: message.text,
          timestamp: message.timestamp,
          author: `${message.first_name} ${message.last_name}`,
          user_id: learner.id,
        };
      });

      this.props.addChatBox({
        socket_id: learner.socket_id,
        username: learner.username,
        user_id: learner.id,
        assistance_requested: false,
        unreadMessages: learner.messages.length,
        comments,
      });

      resolve();
    });
  }
  onMaximize(maximizedIndex) {
    const learner = this.props.sessions[maximizedIndex];
    this.props.setMaximizedUser({
      username: learner.username,
      user_id: learner.user_id,
    });
    this.setState({ maximizedIndex });
  }

  onZoom(user_id) {
    let session = this.props.sessions.filter((session) => {
      return session.user_id === user_id;
    });
    this.props.updateZoomSRC(session[0].desktopStream);
    this.props.updateZoomDesktop();
  }

  exitZoom() {
    this.props.updateZoomSRC(null);
    this.props.updateZoomDesktop();
  }

  onMinimize(user_id) {
    this.props.setMaximizedUser({
      username: null,
      user_id: null,
    });
  }

  onSwapViews(user_id) {
    let sessions = this.props.sessions.map((session) => {
      if (session.user_id === user_id) {
        var desktopStream = session.desktopStream;
        var webcamVideoStream = session.webcamVideoStream;
        session.webcamVideoStream = desktopStream;
        session.desktopStream = webcamVideoStream;
      }
      return session;
    });
    this.props.updateSessions(sessions);
  }

  onBan(user_id, username) {
    let session = this.props.sessions.find(
      (session) => session.user_id === user_id
    );
    let event = username + ` ${this.props.t("general.banned")}`;
    this.updateLog(event, session);

    this.props.socket.emit("userBanned", {
      socket_id: session.id,
      user_id: session.user_id,
      room: this.room_id,
    });
    this.props.banUser(user_id);
  }
  onSoftBoot(user_id, username) {
    let session = this.props.sessions.find(
      (session) => session.user_id === user_id
    );
    let event = username + ` ${this.props.t("general.softBooted")}`;
    this.updateLog(event, session);

    this.props.socket.emit("userSoftBooted", {
      socket_id: session.socket_id,
      user_id: session.user_id,
      room: this.room_id,
    });
    this.props.softBootUser(user_id);
  }

  onFlag(user_id, username) {
    let session = this.props.sessions.find(
      (session) => session.user_id === user_id
    );
    let event = username + ` ${this.props.t("general.flagged")}`;
    this.updateLog(event, session);
  }
  updateLog(event, session) {
    let tmpEvent = {
      session_id: session.id,
      timestamp: moment.now(),
      event: JSON.stringify(event),
      proctor_id: 0,
    };
    this.props.saveEvent(tmpEvent);
  }

  async _loadDevice(routerRtpCapabilities) {
    try {
      this.device = new mediasoup.Device();
      await this.device.load({ routerRtpCapabilities });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  /**
   * Publish audio/video for proctor face to face call
   * @param {String} mediaType
   * @param {String} learner_id
   */
  async _publish(mediaType, learner_id) {
    try {
      const data = await this.props.socket.request("createProducerTransport", {
        forceTcp: false,
        rtpCapabilities: this.device.rtpCapabilities,
        role: "proctor",
        kind: mediaType,
        user_id: this.props.kc.tokenParsed.sub,
        room: this.room_id,
      });

      const transport = this.device.createSendTransport(data);
      this.setState({
        producerTransports: this.state.producerTransports.concat(transport),
      });
      transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        this.props.socket
          .request("connectProducerTransport", {
            dtlsParameters,
            kind: mediaType,
            room: this.room_id,
            user_id: this.props.kc.tokenParsed.sub,
          })
          .then(callback)
          .catch(errback);
      });

      transport.on(
        "produce",
        async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await this.props.socket.request("produce", {
              transportId: transport.id,
              kind,
              rtpParameters,
              room: this.room_id,
              role: "proctor",
              username: this.props.username,
              user_id: this.props.kc.tokenParsed.sub,
              type: mediaType,
              learner_id,
            });
            callback({ id });
          } catch (e) {
            Sentry.captureException(e);
            errback(e);
          }
        }
      );

      transport.on("connectionstatechange", (state) => {
        switch (state) {
          case "failed":
            transport.close();
            throw new Error(`FAILED TO PRODUCE ${mediaType}`);
          default:
            break;
        }
      });

      this.localStream = await this._getUserMedia(transport, mediaType);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  /**
   *
   * @param {*} transport
   * @param {*} kind
   */
  async _getUserMedia(transport, kind) {
    try {
      if (!this.device.canProduce(kind)) {
        throw new Error("error: cannot produce video");
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        video: kind === MEDIA_TYPE_VIDEO,
        audio: kind === MEDIA_TYPE_AUDIO,
      });
      const track =
        kind === MEDIA_TYPE_VIDEO
          ? stream.getVideoTracks()[0]
          : stream.getAudioTracks()[0];
      const params = { track };
      await transport.produce(params);
      return stream;
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  /**
   * Start the process of consuming a learners webcam/audio/desktop
   * @param {String} learner_id
   * @param {String} type
   */
  async _subscribe(user_id, type) {
    try {
      const data = await this.props.socket.request("createConsumerTransport", {
        forceTcp: false,
        kind: type,
        room: this.room_id,
        consuming_id: user_id,
        user_id: this.props.kc.tokenParsed.sub,
        role: "proctor",
      });

      const transport = this.device.createRecvTransport(data);

      //grab existing consumerTransports for this user
      const existingTransports = this.state.consumerTransports.find(
        (t) => t.user_id === user_id
      );
      const consumerTransports = existingTransports
        ? existingTransports.transports
        : [];

      //add new transport to state so it can be closed later
      this.setState({
        consumerTransports: [
          ...this.state.consumerTransports,
          { user_id, transports: [...consumerTransports, transport] },
        ],
      });

      transport.on("connect", ({ dtlsParameters }, callback, errback) => {
        this.props.socket
          .request("connectConsumerTransport", {
            transportId: transport.id,
            dtlsParameters,
            room: this.room_id,
            kind: type,
            user_id: this.props.kc.tokenParsed.sub,
            consuming_id: user_id,
          })
          .then(callback)
          .catch(errback);
      });

      transport.on("connectionstatechange", (state) => {
        switch (state) {
          case "failed":
            transport.close();
            throw new Error(`FAILED TO CONSUME ${type}`);
          default:
            break;
        }
      });

      return await this._consume(transport, user_id, type);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  /**
   * Consume either a learners audio/webcam/desktop
   * @param {Object} transport
   * @param {String} learner_id
   * @param {String} type
   */
  async _consume(transport, learner_id, type) {
    const { rtpCapabilities } = this.device;
    const data = await this.props.socket.request("consume", {
      rtpCapabilities,
      room: this.room_id,
      kind: type,
      consuming_id: learner_id,
      consumer_id: this.props.kc.tokenParsed.sub,
    });

    const { producerId, id, kind, rtpParameters } = data;

    const codecOptions = {};

    const consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });

    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return stream;
  }

  updateExamName(e) {
    this.props.updateExamName(e.target.value);
  }
  updateExamCapacity(e) {
    this.props.updateExamCapacity(e.target.value);
  }

  urlModal(socket_id) {
    this.props.updateSocketForUrlPush(socket_id);
    this.props.updatePushModal();
  }
  allUrlModal() {
    this.props.updateAllPushModal();
  }
  allPushUrl() {
    if (this.props.url !== "") {
      let socket_all = "all";
      this.props.socket.emit("testUrl", {
        socket_id: socket_all,
        url: this.props.url,
      });
      this.props.updateAllPushModal();
    }
  }
  pushUrl() {
    if (this.props.url !== "") {
      this.props.socket.emit("testUrl", {
        socket_id: this.props.socketForUrlPush,
        url: this.props.url,
      });
      this.props.updatePushModal();
    }
  }

  handleLeaveExam() {
    this.props.socket.emit("proctorLeftExam");
    this.props.updateSessions([]);
    this.props.updateEvents([]);
    this.props.updateCurrentExam(null);
    this.props.clearComments();
  }

  onMessageReceived(user_id) {
    const chatBoxes = this.props.chatBoxes.filter((session) => {
      if (user_id === session.user_id && this.props.activeChat !== user_id) {
        session.unreadMessages++;
      }
      return session;
    });
    this.props.updateChatBoxes(chatBoxes);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    this.handleProctorLeft();
  }
  updateMaxUserCycleTime(e) {
    this.setState({ maxUserCycleTime: e.target.value });
  }
  handleWindowBeforeUnload() {
    this.handleProctorLeft();
  }
  handleProctorLeft() {
    this.props.socket.off("userRequestedAssistance");
    this.props.socket.off("usertyping");
    this.props.socket.off("userSharedStreams");
    this.props.socket.off("message");
    this.props.socket.off("proctorJoined");
    this.props.socket.off("userJoined");
    this.props.socket.off("userLeft");
    this.props.socket.off("webcamIceCandidate");
    this.props.socket.off("webcamViewerResponse");
    this.props.socket.off("desktopIceCandidate");
    this.props.socket.off("desktopViewerResponse");
    this.props.socket.off("listOfCurrentLearners");
    this.props.socket.off("stopFaceToFace");
    this.props.socket.off("faceToFaceConnected");
    this.props.socket.off("userDisconnected");
    this.props.socket.off("userRejoined");

    //closing consumer and producer transports
    for (let consumerTransport of this.state.consumerTransports) {
      for (let transport of consumerTransport.transports) {
        transport.close();
      }
    }

    for (let transport of this.state.producerTransports) {
      transport.close();
    }

    this.handleLeaveExam();
  }
  render() {
    if (this.props.sessions.length > 0) {
      return (
        <div style={{ margin: 8 }}>
          <PushUrlModal pushUrl={this.pushUrl} />
          <AllPushUrlModal allPushUrl={this.allPushUrl} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Card.Group itemsPerRow={2}>
                  {this.props.sessions.map((data, i) => {
                    if (data.render) {
                      return (
                        <span key={`${data.user_id}_card`}>
                          <WebCamUser
                            id={data.id}
                            disconnected={data.disconnected}
                            socketId={data.socket_id}
                            {...data}
                            onMinimize={this.onMinimize}
                            onMaximize={this.onMaximize}
                            onZoom={this.onZoom}
                            onBan={this.onBan}
                            onFlag={this.onFlag}
                            urlModal={this.urlModal}
                            user={data.username}
                            webcamVideoStream={data.webcamVideoStream}
                            webcamAudioStream={data.webcamAudioStream}
                            desktopStream={data.desktopStream}
                            allUrlModal={this.allUrlModal}
                            onSoftBoot={this.onSoftBoot}
                            startVideoCall={this.startVideoCall}
                            stopVideoCall={this.stopVideoCall}
                            onSwapViews={this.onSwapViews}
                            user_id={data.user_id}
                            index={i}
                          />
                          <Modal
                            size="fullscreen"
                            open={this.props.zoomDesktop}
                            onClose={this.exitZoom}
                          >
                            <Modal.Header>
                              {this.props.t("ProctoringSession.expandView")}
                            </Modal.Header>
                            <Modal.Content>
                              <ExpandedDesktop
                                media={this.props.zoomedSRC}
                                style={{ maxWidth: "99%", minWidth: "45%" }}
                              />
                            </Modal.Content>
                          </Modal>
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Card.Group>
              </Grid.Column>
              <Grid.Column width={5}>
                <div style={{ position: "fixed", right: "1%" }}>
                  <Slider
                    label={this.props.t("ProctoringSession.cycleMaxUsers")}
                    min={0}
                    max={30}
                    updateMaxUserCycleTime={this.updateMaxUserCycleTime}
                    maxUserCycleTime={this.state.maxUserCycleTime}
                    disabled={this.props.sessions.length === 1}
                  />
                  <CurrentUserDetails
                    session={this.props.sessions.find(
                      (session) => session.selected === true
                    )}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="chatBar">
                  <Menu floated="right" inverted>
                    {this.props.chatBoxes.map((data, i) => {
                      return (
                        <Popup
                          onClose={() => this.props.setActiveChat(null)}
                          key={`${data.user_id}_chat_box`}
                          content={
                            <Chat
                              onMessageReceived={this.onMessageReceived}
                              socket={this.props.socket}
                              socket_id={data.socket_id}
                              room={this.room_id}
                              user_id={data.user_id}
                              comments={data.comments}
                            />
                          }
                          position="top left"
                          on="click"
                          trigger={
                            <Menu.Item
                              color={
                                data.assistance_requested ? "red" : data.typing ? "green" : "black"
                              }
                              active={data.assistance_requested ? data.assistance_requested : data.typing ? data.typing : false }
                              onClick={() => {
                                const chatBoxes = this.props.chatBoxes.filter(
                                  (d) => {
                                    if (data.user_id === d.user_id) {
                                      data.unreadMessages = 0;
                                      data.assistance_requested = false;
                                    }
                                    return data;
                                  }
                                );

                                this.props.updateChatBoxes(chatBoxes);
                                this.props.setActiveChat(data.user_id);
                              }}
                            >
                              {data.username + "  "}
                              {data.typing ? 
                                <div className="dots-container"><div className='dot-elastic'></div></div> 
                                : null
                              }
                              {data.unreadMessages > 0 ? (
                                <Label color="teal">
                                  {data.unreadMessages}
                                </Label>
                              ) : null}
                            </Menu.Item>
                          }
                        />
                      );
                    })}
                  </Menu>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    } else {
      return (
        <div style={{ margin: 8 }}>
          <NoLearners />
        </div>
      );
    }
  }
}

//export default ProctoringSession
export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      sessions: state.session.sessions,
      maximizedUser: state.session.maximizedUser,
      activeChat: state.session.activeChat,
      url: state.session.url,
      socketForUrlPush: state.session.socketForUrlPush,
      zoomDesktop: state.session.zoomDesktop,
      zoomedSRC: state.session.zoomedSRC,
      currentExam: state.session.currentExam,
      chatBoxes: state.chat.chatBoxes,
    }),
    {
      addSession,
      updateExamCapacity,
      saveEvent,
      updateEvents,
      fetchExams,
      updateExamName,
      updateCurrentExam,
      banUser,
      softBootUser,
      saveSession,
      updateSessions,
      setActiveChat,
      updateSocketForUrlPush,
      clearCommentsForUser,
      updatePushModal,
      updateZoomDesktop,
      updateZoomSRC,
      setMaximizedUser,
      addComment,
      updateNewMessage,
      clearComments,
      clearSessions,
      updateAllPushModal,
      updateMessage,
      updateShowMessage,
      updateFaceToFaceConnected,
      addChatBox,
      updateChatBoxes,
      clearChatBoxes,
      updateTyping,
    }
  )(ProctoringSession)
);
