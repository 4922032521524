import React from "react";
import { Grid, Icon, Header, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const NoLearners = (props) => (
  <Grid
    textAlign="center"
    style={{
      height: "100%",
      opacity: "50%",
    }}
    verticalAlign="middle"
  >
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h2" color="grey" textAlign="center">
        {props.t("ProctoringSession.noLearnersInExam")}
      </Header>
      <Icon size="massive" className="grey" name="hourglass start" />
      <Message>{props.t("ProctoringSession.noLearnersInExamMessage")}</Message>
    </Grid.Column>
  </Grid>
);

export default withTranslation()(NoLearners);
