import React from "react";
import { withTranslation } from "react-i18next";

const StatusText = (props) => {
  const status = props.status;
  const color = ["approved", "completed"].includes(status) ? "green" : "red";
  return (
    <span style={{ color: status === "pending" ? null : color }}>
      {`${props.t(`general.${status}`)} `}
    </span>
  );
};

export default withTranslation()(StatusText);
