import React, { Component } from "react";
import {
  Segment,
  Header,
  Container,
  Form,
  Dropdown,
  Grid,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  updateEmailAddress,
  updateFirstName,
  updateLastName,
  updateTimezone,
  updateLearningInstitution,
  updateHideSuccess,
  updateHideError,
  updateProvince,
  updatePhone,
  updateBirthday,
  updateStreetAddress,
  updatePostalCode,
  updateCity,
} from "./../reducers/accountDetails";
import { updateUser } from "./../lib/keycloakServices";
import { createOrUpdateUser } from "./../lib/userServices";
import { fetchClients } from "./../reducers/clients";
import { addNotification } from "./../reducers/notification";
import PictureUploader from "../components/PictureUploader";
import { withTranslation } from "react-i18next";

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.handleUpdateEmailAddress = this.handleUpdateEmailAddress.bind(this);
    this.handleUpdateLastName = this.handleUpdateLastName.bind(this);
    this.handleUpdateFirstName = this.handleUpdateFirstName.bind(this);
    this.handleUpdateTimezone = this.handleUpdateTimezone.bind(this);
    this.handleUpdatePostalCode = this.handleUpdatePostalCode.bind(this);
    this.handleUpdateCity = this.handleUpdateCity.bind(this);
    this.handleUpdateStreetAddress = this.handleUpdateStreetAddress.bind(this);
    this.handleUpdatePhone = this.handleUpdatePhone.bind(this);
    this.handleUpdateBirthday = this.handleUpdateBirthday.bind(this);
    this.handleUpdateLearningInstitution =
      this.handleUpdateLearningInstitution.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {
    this.props.fetchClients();
    this.props.updateEmailAddress(this.props.kc.tokenParsed.email);
    this.props.updateLastName(this.props.kc.tokenParsed.family_name);
    this.props.updateFirstName(this.props.kc.tokenParsed.given_name);
    this.props.updateTimezone(this.props.kc.tokenParsed.timezone);
    this.props.updateProvince(this.props.kc.tokenParsed.province);
    this.props.updateLearningInstitution(
      this.props.kc.tokenParsed.learning_institution
        ? String(this.props.kc.tokenParsed.learning_institution)
        : null
    );
    this.props.updatePhone(this.props.kc.tokenParsed.phone);
    this.props.updateBirthday(this.props.kc.tokenParsed.birthday);
    this.props.updateStreetAddress(this.props.kc.tokenParsed.street);
    this.props.updateCity(this.props.kc.tokenParsed.city);
    this.props.updatePostalCode(this.props.kc.tokenParsed.postal_code);
  }
  handleUpdateEmailAddress(value) {
    this.props.updateEmailAddress(value);
  }
  handleUpdateFirstName(value) {
    this.props.updateFirstName(value);
  }
  handleUpdateLastName(value) {
    this.props.updateLastName(value);
  }
  handleUpdateTimezone(value) {
    this.props.updateTimezone(value);
  }
  handleUpdateLearningInstitution(value) {
    this.props.updateLearningInstitution(value);
  }
  handleUpdateProvince(value) {
    this.props.updateProvince(value);
  }
  handleUpdatePhone(value) {
    this.props.updatePhone(value);
  }
  handleUpdateBirthday(value) {
    this.props.updateBirthday(value);
  }
  handleUpdatePostalCode(value) {
    this.props.updatePostalCode(value);
  }
  handleUpdateCity(value) {
    this.props.updateCity(value);
  }
  handleUpdateStreetAddress(value) {
    this.props.updateStreetAddress(value);
  }
  handleSubmit() {
    updateUser({
      id: this.props.kc.tokenParsed.sub,
      email: this.props.accountDetails.emailAddress,
      firstName: this.props.accountDetails.firstName,
      lastName: this.props.accountDetails.lastName,
      timezone: this.props.accountDetails.timezone,
      learningInstitution: this.props.accountDetails.learningInstitution,
      province: this.props.accountDetails.province,
      birthday: this.props.accountDetails.birthday,
      phone: this.props.accountDetails.phone,
      streetAddress: this.props.accountDetails.streetAddress,
      postalCode: this.props.accountDetails.postalCode,
      city: this.props.accountDetails.city,
    }).then((res) => {
      if (res.success) {
        let user = {
          user_id: this.props.kc.tokenParsed.sub,
          username: this.props.kc.tokenParsed.preferred_username,
          first_name: this.props.accountDetails.firstName,
          last_name: this.props.accountDetails.lastName,
          timezone: this.props.accountDetails.timezone,
          learning_institution: this.props.accountDetails.learningInstitution,
          province: this.props.accountDetails.province,
          dob: this.props.accountDetails.birthday,
          phone: this.props.accountDetails.phone,
        };
        user = Object.assign(user);
        createOrUpdateUser(user);
        this.props.addNotification({
          message: this.props.t("AccountDetails.updatingSuccess"),
          type: "success",
          title: this.props.t("AccountDetails.success"),
          timeout: true,
        });

        this.props.kc.tokenParsed.family_name =
          this.props.accountDetails.lastName;
        this.props.kc.tokenParsed.given_name =
          this.props.accountDetails.firstName;
        this.props.kc.tokenParsed.timezone = this.props.accountDetails.timezone;
        this.props.kc.tokenParsed.province = this.props.accountDetails.province;
        this.props.kc.tokenParsed.learning_institution =
          this.props.accountDetails.learningInstitution;
      } else {
        this.props.addNotification({
          message: this.props.t("AccountDetails.updatingError"),
          type: "error",
          title: this.props.t("AccountDetails.error"),
          timeout: true,
        });
      }
      window.scroll(0, 0);
    });
  }
  render() {
    let clients = [];
    this.props.clients.clients.forEach((client) => {
      if (client.online === true) {
        clients.push({ value: `${client.id}`, text: client.organization_name });
      }
    });

    let timeZones = [
      { text: this.props.t("AccountDetails.PST"), value: "PST" },
      { text: this.props.t("AccountDetails.MST"), value: "MST" },
      { text: this.props.t("AccountDetails.CST"), value: "CST" },
      { text: this.props.t("AccountDetails.EST"), value: "EST" },
      { text: this.props.t("AccountDetails.AST"), value: "AST" },
      { text: this.props.t("AccountDetails.NST"), value: "NST" },
    ];

    let provinces = [
      { text: this.props.t("AccountDetails.ON"), value: "ON" },
      { text: this.props.t("AccountDetails.QC"), value: "QC" },
      { text: this.props.t("AccountDetails.PE"), value: "PE" },
      { text: this.props.t("AccountDetails.NB"), value: "NB" },
      { text: this.props.t("AccountDetails.NS"), value: "NS" },
      { text: this.props.t("AccountDetails.NL"), value: "NL" },
      { text: this.props.t("AccountDetails.MB"), value: "MB" },
      { text: this.props.t("AccountDetails.SK"), value: "SK" },
      { text: this.props.t("AccountDetails.AB"), value: "AB" },
      { text: this.props.t("AccountDetails.BC"), value: "BC" },
      { text: this.props.t("AccountDetails.NT"), value: "NT" },
      { text: this.props.t("AccountDetails.YT"), value: "YT" },
      { text: this.props.t("AccountDetails.NU"), value: "NU" },
    ];
    return (
      <div style={{ margin: 8 }}>
        <Container>
          <Segment>
            <Header>{this.props.t("AccountDetails.header")}</Header>
            <Form>
              <Form.Field>
                <label>{this.props.t("general.emailAddress")}</label>
                <input
                  disabled
                  value={this.props.accountDetails.emailAddress || ""}
                  onChange={(e) =>
                    this.handleUpdateEmailAddress(e.target.value)
                  }
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label={this.props.t("general.lastName")}
                  value={this.props.accountDetails.lastName}
                  onChange={(e) => this.handleUpdateLastName(e.target.value)}
                />
                <Form.Input
                  fluid
                  label={this.props.t("general.firstName")}
                  value={this.props.accountDetails.firstName}
                  onChange={(e) => this.handleUpdateFirstName(e.target.value)}
                />
              </Form.Group>
              <Form.Field>
                <label>{this.props.t("AccountDetails.timezone")}</label>
                <Dropdown
                  placeholder={this.props.t("AccountDetails.selectTimezone")}
                  fluid
                  selection
                  options={timeZones}
                  onChange={(e, data) => this.handleUpdateTimezone(data.value)}
                  value={this.props.accountDetails.timezone}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  {this.props.t("AccountDetails.learningInstitution")}
                </label>
                <Dropdown
                  onChange={(e, data) =>
                    this.handleUpdateLearningInstitution(data.value)
                  }
                  placeholder={this.props.t(
                    "AccountDetails.selectLearningInstitution"
                  )}
                  fluid
                  selection
                  options={clients}
                  value={this.props.accountDetails.learningInstitution}
                />
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("AccountDetails.province")}</label>
                <Dropdown
                  onChange={(e, data) => this.handleUpdateProvince(data.value)}
                  placeholder={this.props.t("AccountDetails.selectProvince")}
                  fluid
                  selection
                  options={provinces}
                  value={this.props.accountDetails.province}
                />
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("general.phoneNumber")}</label>
                <input
                  value={this.props.accountDetails.phone || ""}
                  onChange={(e) => this.handleUpdatePhone(e.target.value)}
                />
              </Form.Field>

              <Form.Field>
                <label>{this.props.t("AccountDetails.dateOfBirth")}</label>
                <input
                  value={this.props.accountDetails.birthday || ""}
                  onChange={(e) => this.handleUpdateBirthday(e.target.value)}
                />
              </Form.Field>

              <Form.Field>
                <label>{this.props.t("AccountDetails.streetAddress")}</label>
                <input
                  value={this.props.accountDetails.streetAddress || ""}
                  onChange={(e) =>
                    this.handleUpdateStreetAddress(e.target.value)
                  }
                />
              </Form.Field>

              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label={this.props.t("AccountDetails.city")}
                  value={this.props.accountDetails.city || ""}
                  onChange={(e) => this.handleUpdateCity(e.target.value)}
                />
                <Form.Input
                  fluid
                  label={this.props.t("AccountDetails.postalCode")}
                  value={this.props.accountDetails.postalCode || ""}
                  onChange={(e) => this.handleUpdatePostalCode(e.target.value)}
                />
              </Form.Group>

              <Form.Field>
                <label>{this.props.t("AccountDetails.personalHeadshot")}</label>
                <Grid columns="equal">
                  <Grid.Column></Grid.Column>
                  <Grid.Column width={10}>
                    <Segment>
                      <PictureUploader />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid>
              </Form.Field>
              <Grid>
                <Grid.Row centered>
                  <Form.Button
                    size="big"
                    color="green"
                    onClick={this.handleSubmit}
                  >
                    {this.props.t("AccountDetails.saveAccountDetails")}
                  </Form.Button>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      accountDetails: state.accountDetails,
      clients: state.clients,
    }),
    {
      updateEmailAddress,
      updateFirstName,
      updateLastName,
      updateTimezone,
      updateLearningInstitution,
      updateHideSuccess,
      updateHideError,
      updateProvince,
      addNotification,
      updatePhone,
      updateBirthday,
      updateStreetAddress,
      updatePostalCode,
      updateCity,
      fetchClients,
    }
  )(AccountDetails)
);
