import React, { Component } from "react";
import axios from "axios";
import { Button, Progress } from "semantic-ui-react";

class DownloadButton extends Component {
  constructor(props) {
    super(props);
    this.handleDownload = this.handleDownload.bind(this);
    this.state = {
      downloading: false,
      progress: 0,
    };
  }
  handleDownload() {
    this.setState({
      downloading: true,
      progress: 0,
    });
    axios
      .get(this.props.url, {
        responseType: "blob",
        onDownloadProgress: (evt) => {
          let percentCompleted = Math.round((evt.loaded * 100) / evt.total);
          if (percentCompleted >= 100) {
            this.setState({
              downloading: false,
            });
          } else {
            this.setState({
              progress: percentCompleted,
            });
          }
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .trim();
        link.setAttribute(
          "download",
          fileName.substring(1, fileName.length - 1)
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.log(e));
  }
  render() {
    return (
      <React.Fragment>
        {this.state.downloading ? (
          <Progress percent={this.state.progress} indicating progress />
        ) : (
          <Button color="green" onClick={this.handleDownload}>
            {this.props.text}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default DownloadButton;
